
import { CounselorInterface } from "../interfaces";

const formatCreateCounselor = (counselor: CounselorInterface) => {
  return {
    name: counselor.name,
    gender: counselor.gender,
    email: counselor.email,
    tokenPerHour: counselor.tokenPerHour,
    sessionTypeId: counselor.sessionType?.id,
    expertiseIds: counselor.expertise.map((exp) => exp?.id),
  }
}

const formatUpdateCounselor = (counselor: CounselorInterface) => {
  return {
    id: counselor.id,
    name: counselor.name,
    gender: counselor.gender,
    email: counselor.email,
    tokenPerHour: counselor.tokenPerHour,
    sessionTypeId: counselor.sessionType?.id,
    expertiseIds: counselor.expertise.map((exp) => exp?.id),
  }
}

export const CounselorFormatter = {
  formatCreateCounselor,
  formatUpdateCounselor,
};

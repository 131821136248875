import { useAppSelector } from "../../app/hooks";
import { Roles } from "../auth/Auth.Slice";
import { DashboardAdmin } from "./DashboardAdmin";
import { DashboardHR } from "./DashboardHR";

export function Dashboard() {
  const role = useAppSelector(state => state.auth.role);
  if (role === Roles.ADMIN) return <DashboardAdmin />;
  if (role === Roles.HR_ADMIN) return <DashboardHR />;
  if (role === Roles.MASTER_ADMIN) return <DashboardAdmin />;
  else return <></>;
}

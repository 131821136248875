import React from "react";

const SearchBox = ({
  className,
  searchByValue,
  searchByOnChange,
  searchByOptions = [],
  searchTextOnChange,
  searchTextValue,
}: {
  className: string;
  searchByValue: any;
  searchByOnChange: any;
  searchByOptions: any;
  searchTextOnChange: any;
  searchTextValue: any;
}) => {
  return (
    <label
      className={`${className} flex w-full flex-col gap-2 text-base md:!w-auto md:flex-row md:gap-0 md:input-group`}
    >
      <div
        className="md:pr-[1rem]s box-border flex min-h-[3.375rem] w-full flex-row items-center text-black 
        md:w-auto md:items-start"
      >
        <span
          className="flex min-h-[3.375rem] min-w-[6rem] items-center justify-center rounded-l-lg 
          border-y border-l !bg-white pr-1"
        >
          Search By
        </span>
        <select
          className="min-h-[3.375rem] self-stretch w-full flex-initial cursor-pointer rounded-r-lg 
          border-y border-r bg-white md:w-auto md:rounded-none md:border-r-0"
          onChange={searchByOnChange}
          value={searchByValue}
        >
          {searchByOptions.map((item: any, index: number) => {
            return (
              <option key={index} value={item?.value}>
                {item?.name}
              </option>
            );
          })}
        </select>
      </div>
      <input
        type="text"
        placeholder="Pencarian Data"
        className="input min-h-[3.375rem] w-full flex-1 border-black border-opacity-20 bg-white text-black"
        value={searchTextValue}
        onChange={searchTextOnChange}
      />
    </label>
  );
};

SearchBox.defaultProps = {
  className: "",
  searchByValue: "all",
  searchByOptions: [{ value: "all", name: "All" }],
  searchTextValue: "",
};

export default SearchBox;

import { useState, useEffect } from 'react';
import { useFormik } from "formik";

import { Main } from '../../components/Main';
import SearchBox from '../../components/SearchBox';
import Table from '../../components/Table';

import { FaListUl, FaPencilAlt, FaTrashAlt, FaPlusSquare } from 'react-icons/fa';

import ModalAlert from '../../components/ModalAlert';
import ModalForm from '../../components/ModalForm';
import FormContent from "./FormContent";

import { ExpertiseService } from '../../services/ExpertiseService';

export function ViewExpertise() {
  const [refresh, setRefresh] = useState(false);
  const [sidebarIsOpen, setSidebarIsOpen] = useState(true);
  const [modalFormIsUpdate, setModalFormIsUpdate] = useState(false);
  const [modalAlertVisible, setModalAlertVisible] = useState(false);
  const [modalAlertText, setModalAlertText] = useState("Expertise Succesfully Created");
  const [modalAlertIsConfirmation, setModalAlertIsConfirmation] = useState(false);
  const [modalFormVisible, setModalFormVisible] = useState(false);
  const [modalFormTitle, setModalFormTitle] = useState("New Expertise");
  const [modalFormButtonText, setModalFormButtonText] = useState("Create New Expertise");
  const [masterResultsAmount, setMasterResultsAmount] = useState(5);
  const [masterResultsTotal, setMasterResultsTotal] = useState(5);
  const [masterPageNumber, setMasterPageNumber] = useState(1);
  const [masterPageNumberTotal, setMasterPageNumberTotal] = useState(2);
  const [masterSearchByValue, setMasterSearchByValue] = useState("all");
  const [masterSearchText, setMasterSearchText] = useState("");
  const [masterBody, setMasterBody] = useState([]);
  const [masterSortKey, setMasterSortKey] = useState("");
  const [masterSortIsAsc, setMasterSortIsAsc] = useState(true);
  const [selectedId, setSelectedId] = useState('');
  const [modalAlertIsDelete, setModalAlertIsDelete] = useState(false);
  const [modalAlertIsFailed, setModalAlertIsFailed] = useState(false);

  const masterSearchByOnChange = (e: any) => { setMasterSearchByValue(e.target.value); };
  const mastersearchTextOnChange = (e: any) => { setMasterSearchText(e.target.value); setRefresh(true)};
  const masterResultsAmountOnChange = (e: any) => { setMasterResultsAmount(e.target.value); };
  const masterChangePage = (e: any) => { setMasterPageNumber(e.selected+1); };

  useEffect(() => {
    const sort = (!masterSortKey) ? 'desc(createdAt)' : ( masterSortIsAsc ? 'asc(name)' : 'desc(name)' );
    
    const getSessionTypes = async () => {
      const response = await ExpertiseService.getExpertise(
        masterPageNumber,
        masterResultsAmount,
        masterSearchText,
        sort
      );
      setMasterPageNumberTotal(response?.data?.page?.totalPage);
      setMasterResultsTotal(response?.data?.page?.totalCount);
      setMasterBody(response?.data?.data);
      
    };
    getSessionTypes();
    if (refresh) {
      setRefresh(false);
      setMasterPageNumber(1);
    }
  }, [
    masterPageNumber,
    masterResultsAmount,
    masterSearchByValue, 
    masterSearchText,
    refresh,
    masterSortIsAsc,
    masterSortKey
  ]);
  
  const masterSearchByOptions = [
    { value:"all", name:"All" },
    { value:"expertiseName", name:"Expertise Name" }];

  const masterHeader = [
    { key:"name", name:"Expertise" },
    { key:"action", name:"Action", className:"max-w-[20%]", noSort:true }];

  const masterBodyFormatting = (key: string, data: any): JSX.Element => {
    let classNameAction = "flex w-[30] items-center justify-center rounded-lg text-white";

    switch(key) {
      case "action":
        return (<div className="flex gap-2">
          <button className={`${classNameAction} bg-orange-500`}
            onClick={() => {
              setModalFormTitle("Update Expertise");
              setModalFormButtonText("Update Expertise");
              setModalFormVisible(true);
              setModalFormIsUpdate(true);
              formik.setFieldValue('id',data.id);
              formik.setFieldValue('name',data.name);
            }}>
            <FaPencilAlt className="h-4 w-4 m-3"/>
          </button>
          <button className={`${classNameAction} bg-red-700`}
            onClick={() => {
              setModalAlertText(`Are you sure you want to delete this expertise ?`);
              setSelectedId(data.id);
              setModalAlertIsConfirmation(true);
              setModalAlertVisible(true);
              setModalAlertIsDelete(true);
            }} >
            <FaTrashAlt className="h-4 w-4 m-3"/>
          </button>
        </div>);
      default:
        return (<>{data[key]}</>)
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { id: "", name: "" },
    onSubmit: async (values) => {
      if(modalFormIsUpdate){
        const result = await ExpertiseService.updateExpertise(values);
        handleResponse(result, true, false);
      } else{
        const result = await ExpertiseService.createExpertise(values);
        handleResponse(result, false, false);
      }
    },
  });

  const deleteExpertise = async (id: string)=>{
    const result = await ExpertiseService.deleteExpertise(id);
    handleResponse(result, false, true);
  }

  const handleResponse = (result: any, isUpdate = false, isDelete = false) => {
    if (result?.data?.code === "200") {
      setModalAlertText(result?.data?.data?.message);
      setModalAlertVisible(true);
      if (isUpdate) {
        setModalFormIsUpdate(false);
      }
      setModalFormVisible(false);
      setRefresh(true);
    } else {
      if (result?.code === "ERR_NETWORK") {
        setModalAlertText("Can't connect to the server");
        setModalAlertVisible(true);
        setModalAlertIsFailed(true);
      } else if (!isDelete) {
        const errors = result?.response?.data?.errors;
        for (let key of Object.keys(errors)) {
          formik.setFieldError(key, errors[key]);
        }
      }
    }
    if (isDelete) {
      setModalAlertIsConfirmation(false);
    }
  };

  const ContentExpertise = (
      <div className="bg-white min-h-screen flex flex-col px-4 md:px-0 mt-12 md:mt-0">
        <div className='flex flex-col md:flex-row gap-2 mb-5'>
          <div className='flex w-full min-h-[3.375rem] md:w-[180px]'>
            <button className="text-white flex-auto inline-flex items-center font-semibold btn-active btn-accent rounded-lg"
              onClick={() => { 
                setModalFormTitle("New Expertise");
                setModalFormButtonText("Create New Expertise");
                setModalFormIsUpdate(false);
                setModalFormVisible(true);
              }}
            >
              <FaPlusSquare className="h-4 w-4 mt-1 mr-2 ml-5"/>New Expertise
            </button>
          </div>
          <div className='flex flex-auto'>
            <SearchBox
              className="flex-auto text-black"
              searchByValue={masterSearchByValue}
              searchByOnChange={masterSearchByOnChange}
              searchByOptions={masterSearchByOptions}
              searchTextOnChange={mastersearchTextOnChange}
              searchTextValue={masterSearchText}
            />
          </div>
        </div>
        <div className='flex flex-row'>
          <div className='w-0 flex-1'>
            <Table 
              tableTitle="Expertise List"
              headerData={masterHeader}
              bodyData={masterBody}
              resultsAmount={masterResultsAmount}
              resultsTotal={masterResultsTotal}
              resultsAmountOnChange={masterResultsAmountOnChange}
              resultsAmountOptions={[5, 10, 20, 30, 40, 50]}
              pageNumber={masterPageNumber}
              pageNumberTotal={masterPageNumberTotal}
              changePage={masterChangePage}
              bodyFormatting={masterBodyFormatting}
              sortKey={masterSortKey}
              setSortKey={setMasterSortKey}
              sortIsAsc={masterSortIsAsc}
              setSortIsAsc={setMasterSortIsAsc}
            />
          </div>
        </div>
      </div> 
  )

  return (
    <>
      <Main
        toggleSidebar={() => setSidebarIsOpen(!sidebarIsOpen)}
        sidebarIsOpen={sidebarIsOpen}
        title={"Expertise"}
        subtitle={`Expertise`}
        icon={<FaListUl className="w-4 h-4" />}
        user={"user"}
        children={ContentExpertise}
      />
      <ModalAlert 
        isVisible={modalAlertVisible} 
        alertText={modalAlertText}
        isConfirmationAlert={modalAlertIsConfirmation}
        isFailedAlert={modalAlertIsFailed}
        handleModalVisibility={() => {
          setModalAlertVisible(!modalAlertVisible);
          setModalAlertIsConfirmation(false);
          setModalAlertIsDelete(false);
          setModalAlertIsFailed(false);
        }}
        handleConfirm={
          ()=>{
            if(modalAlertIsDelete) deleteExpertise(selectedId);
          }
        }
      />
      <ModalForm isVisible={modalFormVisible} 
        handleModalVisibility={() => {setModalFormVisible(!modalFormVisible); formik.resetForm();}} 
        modalTitle={modalFormTitle}
        buttonText={modalFormButtonText}
        formContent={<FormContent formik={formik} />}
        handleConfirm={formik.handleSubmit}
      />
    </>
  );
}
import { createAxiosInstance } from "./AxiosInstance";

const UserAccessRoute = "/user-access";

export const UserAccessService = {
    getUserAccess,
    createUserAccess,
    updateUserAccess,
    deleteUserAccess
};

type UserAccess = {
  name: string;
  email: string;
  secondEmail: string;
  role: {label: string, value:string};
  company: {companyId: string, companyName: string};
  status: string;
  newEmail: string;
  employeeId: string;
};

async function getUserAccess(
    page: number, 
    size: number, 
    searchBy: string, 
    searchQuery: string, 
    sort: string,
    filterRole: string,
    filterStatus: string
) {
  const result = await createAxiosInstance()
    .get(UserAccessRoute, {
      params: {
        page, size, searchBy, searchQuery, sort, filterRole, filterStatus
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function createUserAccess(userAccess:UserAccess) {
  const result = await createAxiosInstance()
    .post(
      UserAccessRoute,
      {
        name: userAccess.name,
        role: userAccess.role.value,
        email: userAccess.email,
        companyId: userAccess.company.companyId,
        secondEmail: userAccess.secondEmail
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function updateUserAccess(userAccess:UserAccess) {
  const result = await createAxiosInstance()
    .put(
      UserAccessRoute,
      {
        name: userAccess.name,
        role: userAccess.role.value,
        email: userAccess.email,
        companyId: userAccess.company.companyId,
        secondEmail: userAccess.secondEmail,
        newEmail: userAccess.newEmail,
        employeeId: userAccess.employeeId,
        status:userAccess.status
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function deleteUserAccess(email: String) {
  const result = await createAxiosInstance()
    .delete(
      UserAccessRoute,
      {
        data:{
          email
        }
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}
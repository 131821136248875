import { createAxiosInstance } from "./AxiosInstance";

const ReminderRoute = "/reminder";

export const ReminderService = {
  GetReminder,
  GetReminderByID,
  CreateReminder,
  EditReminder,
  DeleteReminder,
};

async function GetReminder(
  page: number = 1,
  size: number = 5,
  searchBy: string = "",
  searchQuery: string = "",
  sortKey: string = "",
  isAsc: boolean = false
) {
  const sort = sortKey ? isAsc ? `asc(${sortKey})` : `desc(${sortKey})` : "";
  const result = createAxiosInstance()
    .get(ReminderRoute, {
      params: { page, size, searchBy, searchQuery, sort }
    })
    .then((res) => { return res; })
    .catch((err) => { console.log(err); return err; });

  return result;
}

async function GetReminderByID(id: string) {
  const result = createAxiosInstance()
    .get(`${ReminderRoute}/${id}`)
    .then((res) => { return res; })
    .catch((err) => { console.log(err); return err; });

  return result;
}

async function CreateReminder(
  name: string,
  tokenLeft: string,
  durationLeft: string,
  emailSubject: string,
  emailContent: string
) {
  const result = createAxiosInstance()
    .post(ReminderRoute, {
      name, tokenLeft, durationLeft, emailSubject, emailContent,
    })
    .then((res) => { return res; })
    .catch((err) => { console.log(err); return err; });

  return result;
}

async function EditReminder(
  id: string,
  name: string,
  tokenLeft: string,
  durationLeft: string,
  emailSubject: string,
  emailContent: string
) {
  const result = createAxiosInstance()
    .put(ReminderRoute, {
      id, name, tokenLeft, durationLeft, emailSubject, emailContent,
    })
    .then((res) => { return res; })
    .catch((err) => { console.log(err); return err; });

  return result;
}

async function DeleteReminder(id: string) {
  const result = createAxiosInstance()
    .delete(ReminderRoute, { data: { id } })
    .then((res) => { return res; })
    .catch((err) => { console.log(err); return err; });

  return result;
}

import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import {
  FaListUl,
  FaPlusSquare,
  FaPencilAlt,
  FaTrashAlt,
} from "react-icons/fa";

import { Main } from "../../components/Main";
import TabsPanel from "../../components/TabsPanel";
import ModalForm from "../../components/ModalForm";
import ModalAlert from "../../components/ModalAlert";
import SearchBox from "../../components/SearchBox";
import Table from "../../components/Table";
import { SelectionBox, SelectionBoxAsync } from "../../components/SelectionBox";
import { DatePickerRange } from "../../components/DatePicker";
import { ApprovalSession } from "./ApprovalSession";

import FormContent from "./FormContent";

import { SessionService } from "../../services/SessionService";
import { SessionTypeService } from "../../services/SessionTypeService";
import { SessionInterface } from "../../util/interfaces";

const calculateDeltaHour = (timeStart: any, timeEnd: any) => {
  let timeStartInDate: any;
  if (timeStart.match(/^\d\d:\d\d:\d\d$/)) {
    timeStartInDate = new Date("1970-01-01T" + timeStart);
  } else {
    timeStartInDate = new Date("1970-01-01T" + timeStart + ":00");
  }

  let timeEndInDate: any;
  if (timeStart.match(/^\d\d:\d\d:\d\d$/)) {
    timeEndInDate = new Date("1970-01-01T" + timeEnd);
  } else {
    timeEndInDate = new Date("1970-01-01T" + timeEnd + ":00");
  }

  return Math.ceil(
    Math.abs(timeEndInDate - timeStartInDate) / (60 * 60 * 1000)
  );
};

const calculateTokenUsed = (
  timeStart: any,
  timeEnd: any,
  tokenPerHour: any
) => {
  const deltaHour = calculateDeltaHour(timeStart, timeEnd);
  return parseFloat(Number(deltaHour * tokenPerHour).toFixed(2));
};

export function Session(props: any) {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(
    location?.state?.initialTabIndex ? location?.state?.initialTabIndex : 0
  );
  const [tabIndex, setTabIndex] = useState(
    location?.state?.initialTabIndex ? location?.state?.initialTabIndex : 0
  );
  const [refresh, setRefresh] = useState(false);

  const handleViewCompany = (companyId: string) => {
    navigate(`/company/${companyId}`, {
      state: {
        title: "Session",
        subtitle: "Session > Master Data > Detail",
        returnLink: "/session",
      },
    });
  };

  const masterHeader = [
    {
      key: "sessionDate",
      name: "Session Date",
    },
    {
      key: "company",
      name: "Company",
    },
    {
      key: "counselor",
      name: "Counselor",
    },
    {
      key: "sessionType",
      name: "Session Type",
    },
    {
      key: "counselingTime",
      name: "Counseling Time",
    },
    {
      key: "usedToken",
      name: "Used Token",
    },
    {
      key: "rating",
      name: "Rating",
    },
    {
      key: "notes",
      name: "Notes",
    },
    {
      key: "clientName",
      name: "Client Name",
    },
    {
      key: "clientEmail",
      name: "Client Email",
    },
    {
      key: "clientPhoneNumber",
      name: "Client Phone Number",
    },
    {
      key: "sessionStatus",
      name: "Session Status",
    },
    {
      key: "action",
      name: "Action",
      noSort: true,
    },
  ];
  const [masterBody, setMasterBody] = useState([]);
  const TOOLTIP_ID_UPDATE = "session-update-";
  const TOOLTIP_ID_DELETE = "session-delete-";
  const masterBodyFormatting = (key: string, data: any): JSX.Element => {
    let classNameAction =
      "btn rounded-lg border-none flex w-12 items-center justify-center rounded-lg text-white";
    switch (key) {
      case "action":
        return (
          <div className="flex gap-2">
            <span
              data-tooltip-html="Session is on approval<br/>cant be edited"
              data-tip-disable={false}
              id={TOOLTIP_ID_UPDATE + data?.id}
            >
              <button
                className={`${classNameAction} bg-orange-500 hover:bg-orange-500`}
                onClick={() => {
                  setModalFormTitle("Update Session");
                  setModalFormButtonText("Update Session");
                  setModalFormIsUpdate(true);
                  setModalFormVisible(true);
                  formik.setFieldValue("id", data?.id);
                  formik.setFieldValue(
                    "sessionDate",
                    new Date(data?.sessionDate)
                  );
                  formik.setFieldValue(
                    "counselingTime.start",
                    data?.counselingTime?.start
                  );
                  formik.setFieldValue(
                    "counselingTime.end",
                    data?.counselingTime?.end
                  );
                  formik.setFieldValue("rating", data?.rating);
                  formik.setFieldValue("notes", data?.notes);
                  formik.setFieldValue("company", data?.company);
                  formik.setFieldValue("counselor", data?.counselor);
                  formik.setFieldValue("sessionType", data?.sessionType);
                  formik.setFieldValue("clientName", data?.clientName);
                  formik.setFieldValue("clientEmail", data?.clientEmail);
                  formik.setFieldValue(
                    "clientPhoneNumber",
                    data?.clientPhoneNumber
                  );
                  formik.setFieldValue("sessionStatus", data?.sessionStatus);
                  setTokenUsed(data?.usedToken);
                }}
                disabled={data?.isWaitingApproval}
              >
                <FaPencilAlt className="h-4 w-4" fill="#FFFFFF" />
              </button>
              {data?.isWaitingApproval ? (
                <ReactTooltip anchorId={TOOLTIP_ID_UPDATE + data?.id} />
              ) : (
                <></>
              )}
            </span>
            <span
              data-tooltip-html="Session is on approval<br/>cant be deleted"
              data-tip-disable={false}
              id={TOOLTIP_ID_DELETE + data?.id}
            >
              <button
                className={`${classNameAction} bg-red-700 hover:bg-red-700`}
                onClick={() => {
                  setModalAlertText(
                    `Are you sure you want to delete this session?`
                  );
                  setModalAlertIsConfirmation(true);
                  setModalAlertVisible(true);
                  formik.setFieldValue("id", data?.id);
                  setModalAlertIsDelete(true);
                }}
                disabled={data?.isWaitingApproval}
              >
                <FaTrashAlt className="h-4 w-4" fill="#FFFFFF" />
              </button>
              {data?.isWaitingApproval ? (
                <ReactTooltip anchorId={TOOLTIP_ID_DELETE + data?.id} />
              ) : (
                <></>
              )}
            </span>
          </div>
        );
      case "counselingTime":
        return (
          <>
            {data[key]?.start}-{data[key]?.end}
          </>
        );
      case "sessionType":
        return <>{data[key]?.name}</>;
      case "counselor":
        return <>{data[key]?.name}</>;
      case "company":
        return (
          <a
            className="cursor-pointer text-[#289B95] underline"
            onClick={() => handleViewCompany(data[key]?.companyId)}
          >
            {data[key]?.companyName}
          </a>
        );
      case "notes":
        return (
          <div className="max-w-28 w-28 whitespace-normal break-words">
            {data[key]}
          </div>
        );
      case "sessionStatus":
        return <>{data[key]?.label}</>;
      default:
        return <>{data[key]}</>;
    }
  };

  const [tokenUsed, setTokenUsed] = useState("");
  const [modalFormIsUpdate, setModalFormIsUpdate] = useState(false);
  const [modalAlertIsConfirmation, setModalAlertIsConfirmation] =
    useState(false);
  const [modalAlertIsFailed, setModalAlertIsFailed] = useState(false);
  const [modalAlertIsDelete, setModalAlertIsDelete] = useState(false);
  const [modalAlertVisible, setModalAlertVisible] = useState(false);
  const [modalAlertText, setModalAlertText] = useState(
    "Session Succesfully Created"
  );
  const [modalFormVisible, setModalFormVisible] = useState(false);
  const [modalFormTitle, setModalFormTitle] = useState("New Session");
  const [modalFormButtonText, setModalFormButtonText] =
    useState("Add New Session");

  const ratingOptions = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "not yet rated", value: "unrated" },
  ];
  const sessionStatusOptions = [
    { label: "Scheduled", value: "SCHEDULED" },
    { label: "Done", value: "DONE" },
    { label: "Report Submitted", value: "REPORT_SUBMITTED" },
    { label: "Invoice Submitted", value: "INVOICE_SUBMITTED" },
    { label: "Paid", value: "PAID" },
  ];
  const [filterRating, setFilterRating] = useState(null);
  const [filterSessionType, setFilterSessionType] = useState(null);
  const [filterSessionStatus, setFilterSessionStatus] = useState(null);
  const [filterSessionDateStart, setFilterSessionDateStart] =
    useState<Date | null>(null);
  const [filterSessionDateEnd, setFilterSessionDateEnd] = useState<Date | null>(
    null
  );
  const [filterUsedTokenStart, setFilterUsedTokenStart] = useState("");
  const [filterUsedTokenEnd, setFilterUsedTokenEnd] = useState("");

  const masterSearchByOptions = [
    { value: "all", name: "All" },
    { value: "sessionDate", name: "Session Date" },
    { value: "companyName", name: "Company" },
    { value: "counselorName", name: "Counselor" },
    { value: "sessionTypeName", name: "Session Type" },
    { value: "counselingTime", name: "Counseling Time" },
    { value: "usedToken", name: "Used Token" },
    { value: "notes", name: "Notes" },
    { value: "clientName", name: "Client Name" },
    { value: "clientEmail", name: "Client Email" },
    { value: "clientPhoneNumber", name: "Client Phone Number" },
    { value: "sessionStatus", name: "Session Status" },
  ];
  const [masterSearchByValue, setMasterSearchByValue] = useState("all");
  const [masterSearchText, setMasterSearchText] = useState("");
  const [masterResultsAmount, setMasterResultsAmount] = useState(10);
  const [masterResultsTotal, setMasterResultsTotal] = useState(10);
  const [masterPageNumber, setMasterPageNumber] = useState(1);
  const [masterPageNumberTotal, setMasterPageNumberTotal] = useState(2);
  const [masterSortKey, setMasterSortKey] = useState("");
  const [masterSortIsAsc, setMasterSortIsAsc] = useState(true);

  const masterSearchByOnChange = (e: any) => {
    setMasterSearchByValue(e.target.value);
  };
  const mastersearchTextOnChange = (e: any) => {
    setMasterSearchText(e.target.value);
  };
  const masterResultsAmountOnChange = (e: any) => {
    setMasterResultsAmount(e.target.value);
  };
  const masterChangePage = (e: any) => {
    setMasterPageNumber(e.selected + 1);
  };

  const formik = useFormik<SessionInterface>({
    enableReinitialize: true,
    initialValues: {
      id: "",
      sessionDate: null,
      counselingTime: {
        start: "",
        end: "",
      },
      usedToken: 0,
      counselor: null,
      company: null,
      sessionType: null,
      rating: "",
      notes: "",
      clientName: "",
      clientEmail: "",
      clientPhoneNumber: "",
      sessionStatus: null,
    },
    onSubmit: async (values) => {
      if (modalFormIsUpdate) {
        const result = await SessionService.updateSession(values);
        handleResponse(result, true, false);
      } else {
        const result = await SessionService.createSession(values);
        handleResponse(result, false, false);
      }
    },
  });

  const handleResponse = (result: any, isUpdate = false, isDelete = false) => {
    if (result?.data?.code === "200") {
      setModalAlertText(result?.data?.data?.message);
      setModalAlertVisible(true);
      if (isUpdate) {
        setModalFormIsUpdate(false);
      }
      setModalFormVisible(false);
      setRefresh(true);
      formik.resetForm();
      setTokenUsed("");
    } else {
      if (result?.code === "ERR_NETWORK") {
        setModalAlertText("Can't connect to the server");
        setModalAlertVisible(true);
        setModalAlertIsFailed(true);
      } else if (!isDelete && result?.response?.data?.errors) {
        const errors = result?.response?.data?.errors;
        for (let key of Object.keys(errors)) {
          if (key === "timeEnd") {
            formik.setFieldError("counselingTime.end", errors[key]);
          } else if (key === "timeStart") {
            formik.setFieldError("counselingTime.start", errors[key]);
          } else if (key === "idCompany") {
            formik.setFieldError("company", errors[key]);
          } else if (key === "idCounselor") {
            formik.setFieldError("counselor", errors[key]);
          } else if (key === "message") {
            formik.setStatus(errors[key]);
          } else {
            formik.setFieldError(key, errors[key]);
          }
        }
      } else {
        setModalAlertText(result?.response?.data?.data?.message);
        setModalAlertVisible(true);
        setModalAlertIsFailed(true);
      }
    }
    if (isDelete) {
      setModalAlertIsConfirmation(false);
    }
  };

  const handleDelete = async (id: string) => {
    const result = await SessionService.deleteSession(id);
    handleResponse(result, false, true);
  };

  const getSessions = async (page: number) => {
    const response: any = await SessionService.getSessions(
      page,
      masterResultsAmount,
      masterSearchByValue,
      masterSearchText,
      masterSortKey,
      masterSortIsAsc,
      filterSessionType,
      filterRating,
      filterSessionStatus,
      filterSessionDateStart,
      filterSessionDateEnd,
      filterUsedTokenStart,
      filterUsedTokenEnd
    );
    setMasterPageNumberTotal(response?.data?.page?.totalPage);
    setMasterResultsTotal(response?.data?.page?.totalCount);
    setMasterBody(response?.data?.data);
  };

  useEffect(() => {
    getSessions(masterPageNumber);
  }, [masterPageNumber]);

  useEffect(() => {
    if (masterPageNumber === 1) {
      getSessions(1);
    }
    setMasterPageNumber(1);
    if (refresh) {
      setRefresh(false);
    }
  }, [
    masterResultsAmount,
    masterSearchByValue,
    masterSearchText,
    masterSortKey,
    masterSortIsAsc,
    filterSessionType,
    filterRating,
    filterSessionStatus,
    refresh,
  ]);

  useEffect(() => {
    if (!modalFormVisible) {
      formik.resetForm();
      setTokenUsed("");
    }
  }, [modalFormVisible]);

  useEffect(() => {
    if (!modalAlertVisible) {
      if (!modalFormVisible) {
        formik.resetForm();
        setTokenUsed("");
      }
      setModalAlertIsConfirmation(false);
      setModalAlertIsFailed(false);
    }
  }, [modalAlertVisible]);

  useEffect(() => {
    if (
      formik.values.counselingTime.start &&
      formik.values.counselingTime.start !== "" &&
      formik.values.counselingTime.end &&
      formik.values.counselingTime.end !== "" &&
      formik.values.counselor?.tokenPerHour
    ) {
      const usedToken = calculateTokenUsed(
        formik.values.counselingTime.start,
        formik.values.counselingTime.end,
        formik.values.counselor?.tokenPerHour
      );
      if (isNaN(usedToken)) {
        setTokenUsed("");
      } else {
        setTokenUsed(usedToken.toString());
      }
    }
  }, [
    formik.values.counselingTime.start,
    formik.values.counselingTime.end,
    formik.values.counselor,
  ]);

  useEffect(() => {
    tabsChangeHandler(selectedIndex);
  }, [selectedIndex]);

  useEffect(() => {
    if (location?.state?.initialTabIndex) {
      setSelectedIndex(1);
    }
  }, [location?.state?.initialTabIndex]);

  const filterComponent = (
    <div className="flex w-full flex-col gap-2 md:flex-row md:items-end">
      <div className="flex w-full flex-col md:w-[28rem]">
        <DatePickerRange
          label="Session Date Filter"
          required={false}
          from={filterSessionDateStart}
          to={filterSessionDateEnd}
          onChangeFrom={(date: Date) => {
            setFilterSessionDateStart(date);
            if (filterSessionDateEnd && date > filterSessionDateEnd) {
              setFilterSessionDateEnd(date);
            }
          }}
          onChangeTo={(date: Date) => setFilterSessionDateEnd(date)}
          error={null}
        />
      </div>
      <div className="flex w-full flex-col items-start gap-1 text-black md:w-[28rem]">
        <label className="text-base font-bold">Used Token Filter</label>
        <div className="flex w-full flex-row items-center justify-between gap-2">
          <input
            className="h-[3rem] w-full rounded-lg 
                  border-2 border-solid border-[#CCCCCC] bg-white p-4 focus:outline-none"
            name="name"
            type="text"
            placeholder="Start Range"
            autoComplete="off"
            value={filterUsedTokenStart}
            onChange={(e) => {
              const re = /^\d{1,}(\.\d{0,4})?$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                setFilterUsedTokenStart(e.target.value);
              }
            }}
          />
          <span className="font-bold">to</span>
          <input
            className="h-[3rem] w-full rounded-lg 
                  border-2 border-solid border-[#CCCCCC] bg-white p-4 focus:outline-none"
            name="name"
            type="text"
            placeholder="End Range"
            autoComplete="off"
            value={filterUsedTokenEnd}
            onChange={(e) => {
              const re = /^\d{1,}(\.\d{0,4})?$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                setFilterUsedTokenEnd(e.target.value);
              }
            }}
          />
        </div>
      </div>
      <div
        className="btn border-none bg-accent normal-case text-white hover:bg-accent"
        onClick={() => {
          if (
            (filterSessionDateStart && filterSessionDateEnd) ||
            (filterUsedTokenStart !== "" && filterUsedTokenEnd !== "")
          ) {
            setRefresh(true);
          }
        }}
      >
        Apply Filter
      </div>
      <div
        className="btn border-accent bg-white normal-case text-accent hover:border-accent hover:bg-white"
        onClick={() => {
          if (
            filterSessionDateStart ||
            filterSessionDateEnd ||
            filterUsedTokenStart !== "" ||
            filterUsedTokenEnd !== ""
          ) {
            setFilterSessionDateStart(null);
            setFilterSessionDateEnd(null);
            setFilterUsedTokenStart("");
            setFilterUsedTokenEnd("");
            if (
              (filterSessionDateStart && filterSessionDateEnd) ||
              (filterUsedTokenStart !== "" && filterUsedTokenEnd !== "")
            ) {
              setRefresh(true);
            }
          }
        }}
      >
        Clear Filter
      </div>
    </div>
  );

  const mainContent = (
    <div className="flex w-full flex-col gap-7 px-4 md:px-0">
      <div className="flex flex-col items-center gap-2 md:flex-row">
        <div
          className="btn flex w-full gap-[0.625rem] border-none bg-accent normal-case text-white
            hover:bg-accent hover:bg-opacity-90 md:min-h-[3.375rem] md:w-auto md:py-4 md:px-8"
          onClick={() => {
            setModalFormTitle("New Session");
            setModalFormButtonText("Create New Session");
            setModalFormIsUpdate(false);
            setModalFormVisible(true);
          }}
        >
          <FaPlusSquare size={16} />
          <span>New Session</span>
        </div>
        <SearchBox
          className="w-full flex-auto"
          searchByValue={masterSearchByValue}
          searchByOnChange={masterSearchByOnChange}
          searchByOptions={masterSearchByOptions}
          searchTextOnChange={mastersearchTextOnChange}
          searchTextValue={masterSearchText}
        />
        <SelectionBoxAsync
          className="w-full md:w-[18rem]"
          placeholder="Show Session Type All"
          value={filterSessionType}
          getOptionLabel={(e: any) => e?.name}
          getOptionValue={(e: any) => e?.id}
          handleChange={(value: any) => {
            setFilterSessionType(value);
          }}
          loadOptions={SessionTypeService.getSessionTypesOptions}
          styleTemplate="page"
        />
        <SelectionBox
          className="w-full md:w-[13.5rem]"
          placeholder="Show Rating All"
          value={filterRating}
          options={ratingOptions}
          handleChange={(obj: any) => setFilterRating(obj)}
          styleTemplate="page"
        />
        <SelectionBox
          className="w-full md:w-[13.5rem]"
          placeholder="Show Session Status All"
          value={filterSessionStatus}
          options={sessionStatusOptions}
          handleChange={(obj: any) => setFilterSessionStatus(obj)}
          styleTemplate="page"
        />
      </div>
      {filterComponent}
      <Table
        tableTitle="Session List"
        headerData={masterHeader}
        bodyData={masterBody}
        resultsAmount={masterResultsAmount}
        resultsTotal={masterResultsTotal}
        resultsAmountOnChange={masterResultsAmountOnChange}
        pageNumber={masterPageNumber}
        pageNumberTotal={masterPageNumberTotal}
        changePage={masterChangePage}
        bodyFormatting={masterBodyFormatting}
        sortKey={masterSortKey}
        setSortKey={setMasterSortKey}
        sortIsAsc={masterSortIsAsc}
        setSortIsAsc={setMasterSortIsAsc}
      />
    </div>
  );

  const panelsData = [
    { tabTitle: "Master Data", tabContent: mainContent },
    {
      tabTitle: "Approval",
      tabContent: <ApprovalSession />,
    },
  ];
  const [crumbs, setCrumbs] = useState("Master Data");
  const tabsChangeHandler = (index: number) => {
    setCrumbs(panelsData[index].tabTitle);
    setTabIndex(index);
    if (index === 0) setRefresh(true);
  };

  return (
    <Main
      toggleSidebar={props.toggleSidebar}
      sidebarIsOpen={props.sidebarIsOpen}
      title="Counseling Session"
      subtitle={`Counseling Session > ${crumbs}`}
      icon={<FaListUl size={25} />}
      user={props.user}
    >
      <div className="w-full bg-white py-8 md:py-0">
        <TabsPanel
          panelsData={panelsData}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
        <ModalAlert
          isVisible={modalAlertVisible}
          alertText={modalAlertText}
          isConfirmationAlert={modalAlertIsConfirmation}
          isFailedAlert={modalAlertIsFailed}
          handleModalVisibility={() => setModalAlertVisible(!modalAlertVisible)}
          handleConfirm={() =>
            modalAlertIsDelete ? handleDelete(formik.values.id) : ""
          }
        />
        <ModalForm
          isVisible={modalFormVisible}
          handleModalVisibility={() => setModalFormVisible(!modalFormVisible)}
          modalTitle={modalFormTitle}
          buttonText={modalFormButtonText}
          formContent={<FormContent formik={formik} tokenUsed={tokenUsed} />}
          handleConfirm={formik.handleSubmit}
          wideModal={true}
        />
      </div>
    </Main>
  );
}

import { createAxiosInstance } from "./AxiosInstance";

const UserRoute = "/users";

export const UserService = {
  getHrAdmins,
};

async function getHrAdmins(idCompany: string) {
  const result = await createAxiosInstance()
    .get(UserRoute + "/hrAdmin", {
      params: {
        idCompany: idCompany,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}
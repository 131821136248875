import { createAxiosInstance } from "./AxiosInstance";

const DashboardHrRoute = "/dashboard-hr";

export const DashboardHRService = {
    getTokenSummary,
    getSession,
    getSessionChart
};

async function getTokenSummary() {
    const result = await createAxiosInstance()
      .get(`${DashboardHrRoute}/token`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  
    return result;
}

async function getSession(
  page: number, 
  size: number, 
  searchBy: string, 
  searchQuery: string, 
  sort: string,
  filter: string
) {
const result = await createAxiosInstance()
  .get(`${DashboardHrRoute}/session`, {
    params: {
      page, size, searchBy, searchQuery, sort, filter
    },
  })
  .then((res) => {
    return res;
  })
  .catch((err) => {
    console.log(err);
    return err;
  });

return result;
}

async function getSessionChart(filter: string) {
  const result = await createAxiosInstance()
    .get(`${DashboardHrRoute}/session-chart`, {
      params: {
        filter
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}
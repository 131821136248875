import React from 'react';

const Filter = ({
  className,
  filterBy,
  filterValue,
  filterOnChange,
  filterOptions = [],
}: {
  className: string,
  filterBy: { value: string, name: string },
  filterValue: any,
  filterOnChange: any,
  filterOptions: any,
}) => {
  return (
    <label className={`${className} input-group text-base`}>
      <select className="bg-white border flex-1 text-black !rounded-lg !pl-4 h-12" onChange={filterOnChange} value={filterValue} >
        {filterOptions.map((item: any, index: number) => { return (<option key={index} value={item?.value}>Show {filterBy.name} {item?.name}</option>) })}
      </select>
    </label>
  );
};

Filter.defaultProps = {
  className: "",
  filterValue: "all",
  filterOptions: [{ value: "all", name: "All" }],
}

export default Filter;

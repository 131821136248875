import { createSlice } from "@reduxjs/toolkit";

export interface AppState {
  loading: boolean,
  sidebar: boolean,
  menubar: boolean,
  loadingTransparent: boolean,
};

const initialState: AppState = {
  loading: false,
  sidebar: false,
  menubar: false,
  loadingTransparent: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
      state.loadingTransparent = false;
    },
    stopLoading: (state) => {
      state.loading = false;
      state.loadingTransparent = true;
    },
    startTransparentLoading: (state) => {
      state.loading = true;
      state.loadingTransparent = true;
    },
    stopTransparentLoading: (state) => {
      state.loading = false;
      state.loadingTransparent = false;
    },
    openSidebar: (state) => {
      state.sidebar = true;
    },
    closeSidebar: (state) => {
      state.sidebar = false;
    },
    openMenubar: (state) => {
      state.menubar = true;
    },
    closeMenubar: (state) => {
      state.menubar = false;
    },
  },
});

export const {
  startLoading, stopLoading,
  openSidebar, closeSidebar,
  openMenubar, closeMenubar,
  startTransparentLoading, stopTransparentLoading
} = appSlice.actions;
export default appSlice.reducer;

const masterHeader = [
  { key: "sessionDate", name: "Session Date" },
  { key: "sessionType", name: "Session Type" },
  { key: "time", name: "Time" },
  { key: "usedToken", name: "Used Token" },
];

const masterSearchByOptions = [
  { value: "all", name: "All" },
  { value: "sessionDate", name: "Session Date" },
  { value: "sessionType", name: "Session Type" },
  { value: "time", name: "Time" },
  { value: "usedToken", name: "Used Token" },
];

export const SessionOptions = {
  masterHeader, masterSearchByOptions
};

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikProps } from "formik";
import { AiFillPlusSquare } from 'react-icons/ai';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { TokenInterface } from "../../util/interfaces";

const FormContent = ({
    formik,
    companyList,
    handleCreateCompany,
    modalFormIsUpdate,
  }: {
    formik: FormikProps<TokenInterface>,
    companyList: any[],
    handleCreateCompany: any,
    modalFormIsUpdate: boolean,
}) => {
    const navigate = useNavigate();
    const [startDateType, setStartDateType] = useState("text");
    const [endDateType, setEndDateType] = useState("text");
    const handleNewCompany = () => {
        navigate("/company",{ state:{ createToken: true }});
    }

    return (
        <div className='text-left text-black'>
            <label className='text-base text-black font-bold'>Contract Date<span className='text-[#FFA17A]'>*</span></label>
            <div className='flex flex-col md:flex-row gap-2 justify-between w-full my-2'>
                <div className="w-full md:w-[49%]">
                    <DatePicker
                        selected={formik.values.startDate}
                        onChange={(date: Date) => formik.setFieldValue("startDate", date)}
                        className="bg-white border rounded p-2 flex flex-col gap-2 focus:outline-none cursor-pointer w-full"
                        placeholderText="Start Date"
                        dateFormat="dd/MM/yyyy"
                    />
                    {formik.errors.startDate ? <span className="text-[#FF0000]">{`${formik.errors.startDate}`}</span> : ""}
                </div>
                <div className="w-full md:w-[49%]">
                    <DatePicker
                        selected={formik.values.expiredDate}
                        onChange={(date: Date) => formik.setFieldValue("expiredDate", date)}
                        className="bg-white border rounded p-2 flex flex-col gap-2 focus:outline-none cursor-pointer w-full"
                        placeholderText="Expired Date"
                        dateFormat="dd/MM/yyyy"
                    />
                    {formik.errors.expiredDate ? <span className="text-[#FF0000]">{`${formik.errors.expiredDate}`}</span> : ""}
                </div>
            </div>
            <label className='text-base text-black font-bold'>Total Token<span className='text-[#FFA17A]'>*</span></label>
            <div className='flex flex-col gap-2 justify-between w-full my-2'>
                <input className={`bg-white border rounded p-2 w-full ${
                    formik.errors.totalToken ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
                    }`}
                    type="number" placeholder="Total Token"
                    name="totalToken" value={formik.values.totalToken}
                    onChange={formik.handleChange}
                    />
                {formik.errors.totalToken ? <span className="text-[#FF0000]">{formik.errors.totalToken}</span> : ""}
            </div>
            <label className='text-base text-black font-bold'>Company<span className='text-[#FFA17A]'>*</span></label>
            <div className='flex justify-between w-full my-2'>
                {modalFormIsUpdate ?
                    <>
                        <select className={`${formik.values.companyId === "" ? "text-[#9ca3af]" : "text-black"} bg-white w-full border rounded text-black p-2 ${
                            formik.errors.companyId ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
                            }`} onChange={formik.handleChange} name="companyId" value={formik.values.companyId} >
                            <option key={0} value="" disabled>Choose Company</option>
                            {companyList.map((item: any, index: number) => { return (<option key={index + 1} value={item?.value}>{item?.name}</option>) })}
                        </select>
                        {formik.errors.companyId ? <span className="text-[#FF0000]">{formik.errors.companyId}</span> : ""}
                    </>
                    :
                    <div className="flex flex-col gap-2 w-full">
                        <div className="flex flex-col gap-2 md:flex-row">
                            <select className={`${formik.values.companyId === "" ? "text-[#9ca3af]" : "text-black"} bg-white w-full border rounded text-black p-2 ${
                                formik.errors.companyId ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
                                }`} onChange={formik.handleChange} name="companyId" value={formik.values.companyId} >
                                <option key={0} value="" disabled>Choose Company</option>
                                {companyList.map((item: any, index: number) => { return (<option key={index + 1} value={item?.value}>{item?.name}</option>) })}
                            </select>
                            <div className="w-full block md:hidden">{formik.errors.companyId ? <span className="text-[#FF0000]">{formik.errors.companyId}</span> : ""}</div>
                            <button className="min-h-[3.375rem] w-full md:w-[19rem] inline-flex items-center font-semibold btn-active btn-accent rounded-lg p-2 text-white"
                                onClick={handleNewCompany}
                            >
                                <AiFillPlusSquare className="h-4 w-4 mt-1 mr-2 ml-5" />New Company
                            </button>
                        </div>
                        <div className="w-full hidden md:block">{formik.errors.companyId ? <span className="text-[#FF0000]">{formik.errors.companyId}</span> : ""}</div>
                    </div>
                }
            </div>
        </div>
    )
};

FormContent.defaultProps = {
    modalFormIsUpdate: false,
    companyList: [],
}

export default FormContent;
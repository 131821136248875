import React, { Dispatch, SetStateAction } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const RichTextEditor = ({
  editorState,
  setEditorState,
  onChange,
  readOnly = false
}: {
  editorState: EditorState,
  setEditorState: Dispatch<SetStateAction<EditorState>>,
  onChange: (state: EditorState) => any,
  readOnly?: boolean
}) => {
  const richTextEditor = (
    <Editor
      toolbarHidden={readOnly}
      readOnly={readOnly}
      placeholder="Email Content"
      editorState={editorState}
      toolbarClassName="draftjs-toolbar"
      wrapperClassName="draftjs-wrapper"
      editorClassName="draftjs-editor"
      onEditorStateChange={(state) => {
        setEditorState(state);
        onChange(state);
      }}
      hashtag={{}}
      toolbar={{
        options: [
          "colorPicker", "link", "emoji", "embedded", "image", "remove", "history",
          "inline", "blockType", "fontSize", "list", "textAlign", "fontFamily",
        ],
        inline: {
          visible: false,
          bold: { className: "draftjs-option" },
          italic: { className: "draftjs-option" },
          underline: { className: "draftjs-option" },
          strikethrough: { className: "draftjs-option" },
          monospace: { className: "draftjs-option" },
          superscript: { className: "draftjs-option" },
          subscript: { className: "draftjs-option" },
        },
        blockType: {
          className: "draftjs-option-with-dropdown",
        },
        fontSize: {
          className: "draftjs-option-with-dropdown"
        },
        list: {
          unordered: { className: "draftjs-option" },
          ordered: { className: "draftjs-option" },
          indent: { className: "draftjs-option" },
          outdent: { className: "draftjs-option" },
        },
        textAlign: {
          left: { className: "draftjs-option" },
          center: { className: "draftjs-option" },
          right: { className: "draftjs-option" },
          justify: { className: "draftjs-option" },
        },
        fontFamily: {
          className: "draftjs-option-with-dropdown",
        },
        colorPicker: {
          className: "draftjs-option",
        },
        link: {
          popupClassName: "draftjs-popup",
          link: { className: "draftjs-option" },
          unlink: { className: "draftjs-option" },
        },
        emoji: {
          className: "draftjs-option",
        },
        embedded: {
          popupClassName: "draftjs-popup",
          className: "draftjs-option",
        },
        image: {
          popupClassName: "draftjs-popup",
          className: "draftjs-option",
          alt: { present: true, mandatory: false }
        },
        remove: {
          className: "draftjs-option"
        },
        history: {
          undo: { className: "draftjs-option" },
          redo: { className: "draftjs-option" },
        },
      }}
    />
  );

  return richTextEditor;
}

export default RichTextEditor;

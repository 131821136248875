import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const DatePickerWithLabel = ({
  label,
  required,
  selected,
  onChange,
  error,
}: {
  label: string;
  required: boolean;
  selected: any;
  onChange: any;
  error: any;
}) => {
  return (
    <div className="flex w-full flex-col gap-1">
      <div className="flex w-full flex-col gap-2">
        <label className="text-base font-bold text-black">
          {label}
          {required ? <span className="text-[#FFA17A]">*</span> : <></>}
        </label>
        <div
          className="input flex min-h-[3.375rem] w-full flex-row items-center gap-3
            rounded-lg border-[#CCCCCC] bg-white px-3 text-base focus:outline-none"
        >
          <DatePicker
            selected={selected}
            onChange={onChange}
            className="w-full cursor-pointer border-none focus:outline-none"
            placeholderText="dd/mm/yyyy"
            dateFormat="dd/MM/yyyy"
            showMonthDropdown
            showYearDropdown
          />
        </div>
      </div>
      <div className="text-red-500">{error ? error : null}</div>
    </div>
  );
};

export const DatePickerRange = ({
  label,
  required,
  from,
  to,
  onChangeFrom,
  onChangeTo,
  error,
}: {
  label: string;
  required: boolean;
  from: any;
  to: any;
  onChangeFrom: any;
  onChangeTo: any;
  error: any;
}) => {
  return (
    <div className="flex w-full flex-col gap-1">
      <div className="flex w-full flex-col items-start gap-2 text-black">
        <label className="text-base font-bold">
          {label}
          {required ? <span className="text-[#FFA17A]">*</span> : <></>}
        </label>
        <div className="flex w-full flex-row items-center justify-between gap-2">
          <div
            className="input flex min-h-[3rem] w-1/2 flex-row items-center gap-3
              rounded-lg border-2 border-solid border-[#CCCCCC] bg-white
              px-3 text-base focus:outline-none"
          >
            <DatePicker
              selected={from}
              onChange={onChangeFrom}
              className="w-full cursor-pointer border-none focus:outline-none"
              placeholderText="Start Date"
              dateFormat="dd/MM/yyyy"
              showMonthDropdown
              showYearDropdown
            />
          </div>
          <span className="font-bold">to</span>
          <div
            className="input flex min-h-[3rem] w-1/2 flex-row items-center gap-3
              rounded-lg border-2 border-solid border-[#CCCCCC] bg-white
              px-3 text-base focus:outline-none"
          >
            <DatePicker
              selected={to}
              minDate={from}
              onChange={onChangeTo}
              className="w-full cursor-pointer border-none focus:outline-none"
              placeholderText="End Date"
              dateFormat="dd/MM/yyyy"
              showMonthDropdown
              showYearDropdown
            />
          </div>
        </div>
      </div>
      {error ? <div className="text-red-500">{error}</div> : <></>}
    </div>
  );
};

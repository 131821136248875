import { FormikProps } from "formik";
import { PatternFormat } from "react-number-format";

import { SelectionBox, SelectionBoxAsync } from "../../components/SelectionBox";
import { DatePickerWithLabel } from "../../components/DatePicker";

import { SessionInterface } from "../../util/interfaces";
import { CompanyService } from "../../services/CompanyService";
import { CounselorService } from "../../services/CounselorService";

const FormContent = ({
  formik,
  tokenUsed,
}: {
  formik: FormikProps<SessionInterface>;
  tokenUsed: string;
}) => {
  const statusOptions = [
    { label: "Scheduled", value: "SCHEDULED" },
    { label: "Done", value: "DONE" },
    { label: "Report Submitted", value: "REPORT_SUBMITTED" },
    { label: "Invoice Submitted", value: "INVOICE_SUBMITTED" },
    { label: "Paid", value: "PAID" },
  ];

  return (
    <div
      className="-mr-4 flex max-h-[30rem] w-full flex-col overflow-y-auto pr-4 md:mr-0 
      md:max-h-max md:min-w-[42rem] md:flex-row md:gap-4 md:pr-0"
    >
      <div className="flex w-full flex-col gap-1">
        <div className="flex w-full flex-col text-left text-black">
          <div className="flex flex-col gap-1">
            <DatePickerWithLabel
              label="Session Date"
              required={true}
              selected={formik.values.sessionDate}
              onChange={(date: Date) =>
                formik.setFieldValue("sessionDate", date)
              }
              error={formik.errors.sessionDate}
            />
          </div>
        </div>
        <div className="flex w-full flex-col gap-0">
          <div className="flex w-full flex-col gap-2 text-left text-black">
            <label className="text-base font-bold text-black">
              Session Time<span className="text-[#FFA17A]">*</span>
            </label>
            <div className="flex w-full flex-col gap-1 md:flex-row md:gap-2">
              <div className="flex w-full flex-col gap-1">
                <div className=" flex w-full justify-between">
                  <PatternFormat
                    format="##:##"
                    placeholder="hh:mm"
                    value={formik.values.counselingTime.start}
                    className={`h-[3.375rem] w-full rounded-lg border 
                border-[#CCCCCC] bg-white p-4 text-black focus:outline-none ${
                  formik.errors.counselingTime?.start
                    ? "shadow-[0_0_4px_rgb(255,0,0)]"
                    : ""
                }`}
                    isAllowed={(values) => {
                      const { formattedValue } = values;
                      if (formattedValue === "") return true;
                      const [hour, minute] = formattedValue
                        .split(":")
                        .map((val) => Number(val));
                      return (
                        hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59
                      );
                    }}
                    onValueChange={(values) => {
                      formik.setFieldValue(
                        "counselingTime.start",
                        values?.formattedValue
                      );
                    }}
                  />
                </div>
                <span className="text-[#FF0000]">
                  {formik.errors.counselingTime?.start}
                </span>
              </div>
              <div className="flex w-full flex-col gap-1">
                <div className=" flex w-full justify-between">
                  <PatternFormat
                    format="##:##"
                    placeholder="hh:mm"
                    value={formik.values.counselingTime.end}
                    className={`h-[3.375rem] w-full rounded-lg border 
                border-[#CCCCCC] bg-white p-4 text-black focus:outline-none ${
                  formik.errors.counselingTime?.end
                    ? "shadow-[0_0_4px_rgb(255,0,0)]"
                    : ""
                }`}
                    isAllowed={(values) => {
                      const { formattedValue } = values;
                      if (formattedValue === "") return true;
                      const [hour, minute] = formattedValue
                        .split(":")
                        .map((val) => Number(val));
                      return (
                        hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59
                      );
                    }}
                    onValueChange={(values) => {
                      formik.setFieldValue(
                        "counselingTime.end",
                        values?.formattedValue
                      );
                    }}
                  />
                </div>
                <span className="text-[#FF0000]">
                  {formik.errors.counselingTime?.end}
                </span>
              </div>
              <div className="flex w-full flex-col gap-1">
                <div className=" flex w-full justify-between">
                  <input
                    className="h-[3.375rem] w-full rounded-lg border border-[#CCCCCC]
                   bg-gray-300 p-4 focus:outline-none"
                    name="tokenUsed"
                    type="text"
                    placeholder="Token Used"
                    autoComplete="off"
                    value={tokenUsed || ""}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col gap-2 text-left text-black">
          <label className="text-base font-bold text-black">
            Company<span className="text-[#FFA17A]">*</span>
          </label>
          <div className="flex flex-col gap-1">
            <div className="flex w-full justify-between">
              <SelectionBoxAsync
                className="h-[3.375rem] w-full"
                placeholder="Company"
                value={formik.values.company}
                getOptionLabel={(e: any) => e?.companyName}
                getOptionValue={(e: any) => e?.companyId}
                handleChange={(value: any) => {
                  formik.setFieldValue("company", value);
                }}
                loadOptions={CompanyService.getCompanyOptions}
              />
            </div>
            <span className="text-[#FF0000]">{formik.errors.company}</span>
          </div>
        </div>
        <div className="flex w-full flex-col gap-0 md:flex-row md:gap-4">
          <div className="flex w-full flex-col gap-2 text-left text-black">
            <label className="text-base font-bold text-black">
              Counselor<span className="text-[#FFA17A]">*</span>
            </label>
            <div className="flex flex-col gap-1">
              <div className=" flex w-full justify-between">
                <SelectionBoxAsync
                  className="h-[3.375rem] w-full"
                  placeholder="Counselor"
                  value={formik.values.counselor}
                  getOptionLabel={(e: any) => e?.name}
                  getOptionValue={(e: any) => e?.id}
                  handleChange={(value: any) => {
                    formik.setFieldValue("counselor", value);
                    if (value) {
                      formik.setFieldValue("sessionType", value?.sessionType);
                    } else {
                      formik.setFieldValue("sessionType", null);
                    }
                  }}
                  loadOptions={CounselorService.getCounselorOptions}
                  styleTemplate="modal"
                />
              </div>
              <span className="text-[#FF0000]">{formik.errors.counselor}</span>
            </div>
          </div>
          <div className="flex w-full flex-col gap-2 text-left text-black">
            <label className="text-base font-bold text-black">
              Session Type<span className="text-[#FFA17A]">*</span>
            </label>
            <div className="flex flex-col gap-2">
              <div className=" flex w-full justify-between">
                <input
                  className={`h-[3.375rem] w-full rounded-lg 
                  border border-[#CCCCCC] bg-gray-300 p-4 focus:outline-none ${
                    formik.errors.sessionType
                      ? "shadow-[0_0_4px_rgb(255,0,0)]"
                      : ""
                  }`}
                  name="sessionType"
                  type="text"
                  placeholder="Session Type"
                  autoComplete="off"
                  value={formik.values.sessionType?.name || ""}
                  disabled={true}
                />
              </div>
              <span className="text-[#FF0000]">
                {formik.errors.sessionType}
              </span>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col text-left text-black">
          <div className="flex flex-col gap-2">
            <label className="text-base font-bold text-black">
              Rating
            </label>
            <div className="flex flex-col gap-1">
              <div className=" flex w-full justify-between">
                <input
                  className={`h-[3.375rem] w-full rounded-lg 
                  border border-[#CCCCCC] bg-white p-4 focus:outline-none ${
                    formik.errors.rating ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
                  }`}
                  name="rating"
                  type="text"
                  placeholder="Rating"
                  autoComplete="off"
                  value={formik.values.rating}
                  onChange={(e) => {
                    const re = /^[1-5]{1}$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      formik.handleChange(e);
                    }
                  }}
                />
              </div>
              <span className="text-[#FF0000]">{formik.errors.rating}</span>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col text-left text-black">
          <div className="flex flex-col gap-1">
            <label className="text-base font-bold text-black">
              Notes
            </label>
            <div className="flex flex-col gap-1">
              <div className=" flex w-full justify-between">
                <textarea
                  className={`h-[8rem] w-full resize-none rounded-lg
                  border border-[#CCCCCC] bg-white p-4 focus:outline-none ${
                    formik.errors.notes ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
                  }`}
                  name="notes"
                  placeholder="Notes"
                  autoComplete="off"
                  value={formik.values.notes}
                  onChange={formik.handleChange}
                />
              </div>
              <span className="text-[#FF0000]">{formik.errors.notes}</span>
            </div>
          </div>
        </div>
        <span className="w-full text-start text-[#FF0000]">
          {formik.status}
        </span>
      </div>
      <div className="flex w-full flex-col gap-1">
        <div className="flex w-full flex-col text-left text-black">
          <div className="flex flex-col gap-2">
            <label className="text-base font-bold text-black">
              Client Name<span className="text-[#FFA17A]">*</span>
            </label>
            <div className="flex flex-col gap-1">
              <div className=" flex w-full justify-between">
                <input
                  className={`h-[3.375rem] w-full rounded-lg 
                  border border-[#CCCCCC] bg-white p-4 focus:outline-none ${
                    formik.errors.clientName
                      ? "shadow-[0_0_4px_rgb(255,0,0)]"
                      : ""
                  }`}
                  name="clientName"
                  type="text"
                  placeholder="Client Name"
                  autoComplete="off"
                  value={formik.values.clientName}
                  onChange={formik.handleChange}
                />
              </div>
              <span className="text-[#FF0000]">{formik.errors.clientName}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:gap-2">
          <div className="flex w-full flex-col text-left text-black">
            <div className="flex flex-col gap-2">
              <label className="text-base font-bold text-black">
                Client Email<span className="text-[#FFA17A]">*</span>
              </label>
              <div className="flex flex-col gap-1">
                <div className=" flex w-full justify-between">
                  <input
                    className={`h-[3.375rem] w-full rounded-lg 
                  border border-[#CCCCCC] bg-white p-4 focus:outline-none ${
                    formik.errors.clientEmail
                      ? "shadow-[0_0_4px_rgb(255,0,0)]"
                      : ""
                  }`}
                    name="clientEmail"
                    type="text"
                    placeholder="Client Email"
                    autoComplete="off"
                    value={formik.values.clientEmail}
                    onChange={formik.handleChange}
                  />
                </div>
                <span className="text-[#FF0000]">
                  {formik.errors.clientEmail}
                </span>
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col text-left text-black">
            <div className="flex flex-col gap-2">
              <label className="text-base font-bold text-black">
                Client Phone Number<span className="text-[#FFA17A]">*</span>
              </label>
              <div className="flex flex-col gap-1">
                <div className=" flex w-full justify-between">
                  <input
                    className={`h-[3.375rem] w-full rounded-lg 
                  border border-[#CCCCCC] bg-white p-4 focus:outline-none ${
                    formik.errors.clientPhoneNumber
                      ? "shadow-[0_0_4px_rgb(255,0,0)]"
                      : ""
                  }`}
                    name="clientPhoneNumber"
                    type="text"
                    placeholder="Client Phone Number"
                    autoComplete="off"
                    value={formik.values.clientPhoneNumber}
                    onChange={(e) => {
                      const re = /^\d+$/;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        formik.handleChange(e);
                      }
                    }}
                  />
                </div>
                <span className="text-[#FF0000]">
                  {formik.errors.clientPhoneNumber}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full text-left text-black md:py-2">
          <label className="text-base font-bold text-black">
            Session Status<span className="text-[#FFA17A]">*</span>
          </label>
          <div className="flex flex-col gap-1 w-full">
            <SelectionBox
              className="w-full"
              placeholder="Status"
              value={formik.values.sessionStatus}
              options={statusOptions}
              handleChange={(obj: any) =>
                formik.setFieldValue("sessionStatus", obj)
              }
              styleTemplate="modal"
            />
            <span className="text-[#FF0000]">{formik.errors.sessionStatus}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

FormContent.defaultProps = {
  modalFormIsUpdate: false,
};

export default FormContent;

import { FaBell, FaUserCircle, FaChevronDown, FaBars, FaSignInAlt, FaKey } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import parse from 'html-react-parser';
import DOMPurify from "dompurify";
import io from 'socket.io-client';
import moment from 'moment';

import { logoutUser, Roles } from "../features/auth/Auth.Slice";

import { NotificationInterface } from "../util/interfaces";

import { closeMenubar, openMenubar, openSidebar } from "../App.Slice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";

import { NotificationService } from "../services/NotificationService";
import { handleResponse } from "../services/HandleResponse";
import { AuthService } from "../services/AuthService";

import DexwellnessLogo from "../images/dexwellness.png";

const socketUrl = process.env.REACT_APP_SOCKET_URL ? process.env.REACT_APP_SOCKET_URL : '';
const socket = io(socketUrl);

type Props = {
  title: string;
  subtitle: string;
  icon: React.ReactNode;
  user: any;
};

export const Topbar = ({ title, subtitle, icon, user }: Props) => {
  const currentLocation = useLocation();
  const userRole = useAppSelector((state: RootState) => state.auth.role);
  const [notifPopupVisible, setNotifPopupVisible] = useState(false);
  const [notificationList, setNotificationsList] = useState<NotificationInterface[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const name = useAppSelector(state => state.auth.name);
  const showMenubar = useAppSelector(state => state.app.menubar);
  
  const getNotifications = async () => {
    const response = await NotificationService.getNotifications();
    setNotificationsList(response.data.data);
  };

  const handleLogout = async () => {
    const result = await AuthService.LogoutUser();
    handleResponse({
      result: result,
      handleSuccess: (result: any) => dispatch(logoutUser()),
      handleFailed: (result: any) => dispatch(logoutUser()),
    });
  };

  useEffect(() => {
    getNotifications();
    const notifTrigger = userRole === Roles.MASTER_ADMIN ? "NOTIF_RECEIVED:MASTER_ADMIN" :
                          userRole === Roles.ADMIN ? "NOTIF_RECEIVED:ADMIN" : "";

    socket.on(notifTrigger, () => {
      getNotifications();
    })

    return () => {
      socket.off(notifTrigger);
    }
  },[]);

  const handleNotifBell = (e: any) => {
    setNotifPopupVisible(!notifPopupVisible);
  }

  const handleRedirect = (item: any, index: number) => {
    setNotifPopupVisible(false);
    navigate(item.redirectString,{ state:{ initialTabIndex: index+1 }});
  }

  const notifPopup = <>
    <div className={`${ notifPopupVisible ? "" : "invisible" } absolute bg-white border top-10 right-0 flex py-4 px-3 w-[400px] max-h-[250px] rounded-lg shadow text-left z-[1000]`}>
      <div className="flex flex-col gap-y-2 overflow-auto px-1">
        { notificationList.map((item, index) => {return(
          <div key={index} className="border cursor-pointer p-2 rounded-lg w-full" onClick={() => handleRedirect(item, index)}>
            <div className="font-normal mb-2 text-[#989898] text-sm">{moment(item.createdAt).format("DD/MM/YYYY HH:mm")}</div>
            <div className="font-normal text-base">{parse(DOMPurify.sanitize(item.message, {ALLOWED_ATTR: ['className']}))}</div>
          </div>)}) }
      </div>
    </div>
    <div className={`${ notifPopupVisible ? "" : "invisible" } fixed top-0 left-0 w-full h-full z-[999]`} onClick={() => setNotifPopupVisible(false)}/>
  </>

  return (
    <>
      <div className="flex md:hidden flex-col items-start w-full h-full">
        <div
          className={`${showMenubar ? "translate-x-0" : "translate-x-full"} ease-in-out duration-500
            h-screen fixed z-[999] w-3/4 ml-[calc(25%)] bg-[#F3EADA] text-[#193341]`}
        >
          <div className="block md:hidden absolute top-0 right-0 p-2">
            <button
              className="btn btn-ghost p-0 m-0 min-h-0 h-8 w-8 border-none"
              onClick={() => dispatch(closeMenubar())}
            >
              <MdClose size={24} />
            </button>
          </div>
          <div className="flex flex-col max-h-full p-4 text-left text-base gap-2 items-start">
            <FaUserCircle size={20} fill="#289B95" />
            <span className="text-base mr-2">Hello, {name} !</span>
            <button
              className="btn btn-ghost p-0 px-2 gap-4 m-0 min-h-0 h-8 w-full justify-start border-none capitalize"
              onClick={() => { navigate("/change-password") }}
            >
              <FaKey size={18} fill="#289B95" /> Change Password
            </button>
            <button
              className="btn btn-ghost p-0 px-2 gap-4 m-0 min-h-0 h-8 w-full justify-start border-none capitalize"
              onClick={async () => {
                await handleLogout();
                dispatch(closeMenubar());
              }}
            >
              <FaSignInAlt size={20} fill="#289B95" /> Logout
            </button>
            <div className="divider divider-vertical m-0 after:bg-opacity-20 before:bg-opacity-20 after:bg-black before:bg-black" />
            <div className="flex gap-2">
              <FaBell size={20} /><span className="text-base font-bold">Notification</span>
            </div>
            <div className="flex flex-col gap-2 overflow-y-auto max-h-full" >
              { notificationList.map((item, index) => {return(
                <div className="border bg-white cursor-pointer p-2 rounded-lg w-full" onClick={() => handleRedirect(item, index)}>
                  <div className="font-normal mb-2 text-[#989898] text-xs">{moment(item.createdAt).format("DD/MM/YYYY HH:mm")}</div>
                  <div className="font-normal text-sm">{parse(DOMPurify.sanitize(item.message, {ALLOWED_ATTR: ['className']}))}</div>
                </div>)}) }
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 flex items-center justify-between md:hidden w-full h-12 fixed bg-[#E4DCCD] z-[20]">
        <button
          className="btn btn-ghost p-0 m-0 min-h-0 h-8 w-8 border-none"
          onClick={() => dispatch(openSidebar())}
        >
          <FaBars color="#193341" size={20} />
        </button>
        <img
          src={DexwellnessLogo}
          className="h-8"
          alt="Dexwellness Logo"
        />
        <button
          className="btn btn-ghost p-0 m-0 min-h-0 h-8 w-8 border-none"
          onClick={() => dispatch(openMenubar())}
        >
          <FaUserCircle color="#289B95" size={20} />
        </button>
      </div>
      <div className="hidden md:flex flex-row items-center justify-between py-1 lg:py-4 text-black">
        <div className="flex flex-col items-start gap-1 lg:gap-2">
          <div className="flex flex-row gap-2 items-center text-black">
            <div className="pt-0.5">{icon}</div>
            <span className="text-sm lg:text-xl font-bold">{title}</span>
          </div>
          <span className="text-sm text-[#193341]">{subtitle}</span>
        </div>
        <div className="flex">
          <div className="flex items-center gap-4 text-[#193341]">
            <div className="relative">
              <FaBell size={20} className="cursor-pointer" onClick={handleNotifBell} onBlur={() => setNotifPopupVisible(false)}/>
              { notificationList.length > 0 ? <div className="absolute bg-red-600 top-0.5 right-0.5 w-2 h-2 rounded-[50%]"/> : ""}
              {notifPopup}
            </div>
            <div className="divider divider-horizontal m-0"></div>
            <div className="dropdown dropdown-end">
              <label className="flex items-center gap-2 cursor-pointer" tabIndex={0}>
                <span className="text-xl mr-2">Hello, {name} !</span>
                <FaUserCircle size={20} fill="#289B95" />
                <FaChevronDown size={20} />
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content menu bg-[#F3EADA] rounded-lg min-w-[13rem] font-bold"
              >
                <li
                  className="rounded-lg hover:bg-[#e4dccd]"
                  onClick={() => { navigate("/change-password") }}
                >
                  <span>Change Password</span>
                </li>
                <li
                  className="rounded-lg hover:bg-[#e4dccd]"
                  onClick={handleLogout}
                >
                  <span>Logout</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

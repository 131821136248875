import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";

import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { AiFillPlusSquare } from 'react-icons/ai';

import SearchBox from '../../components/SearchBox';
import Table from '../../components/Table';
import ModalAlert from '../../components/ModalAlert';
import ModalForm from '../../components/ModalForm';

import FormContent from './FormContent';

import { CompanyService } from "../../services/CompanyService";
import { UserService } from "../../services/UserService";

import { CompanyInterface } from "../../util/interfaces";

export function ViewCompany() {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedData, setSelectedData] = useState<CompanyInterface | null>(null);

  const [masterRefresh, setMasterRefresh] = useState(0);

  const [modalAlertIsConfirmation, setModalAlertIsConfirmation] = useState(false);
  const [modalAlertIsFailed, setModalAlertIsFailed] = useState(false);
  const [modalAlertIsDelete, setModalAlertIsDelete] = useState(false);
  const [modalAlertVisible, setModalAlertVisible] = useState(false);
  const [modalAlertText, setModalAlertText] = useState("Token Succesfully Created");

  const [modalFormIsUpdate, setModalFormIsUpdate] = useState(false);
  const [modalFormVisible, setModalFormVisible] = useState(false);
  const [modalFormTitle, setModalFormTitle] = useState("New Contract");
  const [modalFormButtonText, setModalFormButtonText] = useState("Create New Contract");

  const [masterBody, setMasterBody] = useState<any[]>([]);
  const [hrAdminList, setHrAdminList] = useState<any[]>([]);

  const [masterSearchByValue, setMasterSearchByValue] = useState("all");
  const [masterSearchText, setMasterSearchText] = useState("");
  const [masterSortKey, setMasterSortKey] = useState("");
  const [masterSortIsAsc, setMasterSortIsAsc] = useState(true);

  const [masterResultsAmount, setMasterResultsAmount] = useState(10);
  const [masterResultsTotal, setMasterResultsTotal] = useState(10);
  const [masterPageNumber, setMasterPageNumber] = useState(1);
  const [masterPageNumberTotal, setMasterPageNumberTotal] = useState(1);

  const masterSearchByOnChange = (e: any) => { setMasterSearchByValue(e.target.value); };
  const mastersearchTextOnChange = (e: any) => { setMasterSearchText(e.target.value); };
  const masterResultsAmountOnChange = (e: any) => { setMasterResultsAmount(e.target.value); };
  const masterChangePage = (e: any) => { setMasterPageNumber(e.selected+1); };
  
  const handleNewToken = (data: any) => {
    navigate("/token",{ state:{ companyId: data.companyId }});
  }
  
  const handleViewToken = (data: any) => {
    navigate(`/company/${data.companyId}`,{ state:{ 
      title: "Company", 
      subtitle: "Company > Master Data > Detail",
      returnLink: "/company",
    }});
  }

  const handleResponse = (result: any, isUpdate = false, isDelete = false) => {
    if (result?.data?.code === "200") {
      setModalAlertText(result?.data?.data?.message);
      setModalAlertVisible(true);
      if (isUpdate) {
        setModalFormIsUpdate(false);
      }
      setModalFormVisible(false);
      setMasterRefresh(masterRefresh + 1);
      formik.resetForm();
    } else {
      if (result?.code === "ERR_NETWORK") {
        setModalAlertText("Can't connect to the server");
        setModalAlertVisible(true);
        setModalAlertIsFailed(true);
      } else if (!isDelete) {
        const errors = result?.response?.data?.errors;
        for (let key of Object.keys(errors)) {
          formik.setFieldError(key, errors[key]);
        }
      }
    }
    if (isDelete) {
      setModalAlertIsConfirmation(false);
    }
  };

  const handleDelete = async (id: string) => {
    if (id) {
      const result = await CompanyService.deleteCompany(id);
      handleResponse(result, false, true);
    }
  };

  const formik = useFormik<CompanyInterface>({
    enableReinitialize: true,
    initialValues: {
      companyId: selectedData?.companyId || "",
      companyName: selectedData?.companyName || "",
      cpName: selectedData?.cpName || "",
      cpEmail: selectedData?.cpEmail || "",
      tokenId: selectedData?.tokenId || "",
      hrAdminId: selectedData?.hrAdminId || "",
      hrAdminName: selectedData?.hrAdminName || "",
      hrAdminEmail1: selectedData?.hrAdminEmail1 || "",
      hrAdminEmail2: selectedData?.hrAdminEmail2 || "",
      tokenStartDate: selectedData?.tokenStartDate || null,
      tokenEndDate: selectedData?.tokenEndDate || null,
      tokenAmount: selectedData?.tokenAmount || 0,
    },
    onSubmit: async (values) => {
      if (modalFormIsUpdate) {
        const result = await CompanyService.updateCompany(values);
        handleResponse(result, true, false);
      } else {
        const result = await CompanyService.createCompany(values);
        handleResponse(result, false, false);
      }
    },
  });

  useEffect(() => {
    setMasterRefresh(masterRefresh + 1);
  }, [masterSearchByValue, masterSearchText, masterResultsAmount, masterSortKey, masterSortIsAsc]);

  useEffect(() => {
    const getCompany = async () => {
      const response = await CompanyService.getCompany(
        masterPageNumber,
        masterResultsAmount,
        masterSearchByValue,
        masterSearchText,
        masterSortKey,
        masterSortIsAsc,
      );
      setMasterPageNumberTotal(response?.data?.page?.totalPage);
      setMasterResultsTotal(response?.data?.page?.totalCount);
      setMasterBody(response?.data?.data?.rows || []);
    };
    if (!masterRefresh) {
      getCompany();
    }
    if (masterRefresh) {
      setMasterRefresh(0);
      setMasterPageNumber(1);
    }
  }, [masterPageNumber, masterResultsAmount, masterRefresh]);

  useEffect(() => {
    if (location.state?.createToken) {
      formik.resetForm();
      setSelectedData(null);
      setModalFormTitle("New Company");
      setModalFormButtonText("Add New Company");
      setModalFormIsUpdate(false);
      setModalFormVisible(true);
    }
  },[location]);
  
  const getHrAdminList = async (idCompany: string) => {
    const response = await UserService.getHrAdmins(idCompany);
    setHrAdminList(response?.data?.data.map((item: any) => { return  {value: item.hrAdminId, name: item.hradminname }}));
  };

  const masterSearchByOptions = [
    { value:"all", name:"All" },
    { value:"companyName", name:"Company Name" },
    { value:"cpName", name:"CP Name" },
    { value:"cpEmail", name:"CP Email" },
    { value:"hrAdminName", name:"Admin HR" }];

  const masterHeader = [
    { key:"companyName", name:"Company Name" },
    { key:"cpName", name:"CP Name" },
    { key:"cpEmail", name:"CP Email" },
    { key:"hrAdminName", name:"Admin HR", className:"w-1/5" },
    { key:"tokenId", name:"Token", noSort:true },
    { key:"action", name:"Action", noSort:true }];

  const masterBodyFormatting = (key: string, data: any): JSX.Element => {
    let classNameAction = "flex w-12 items-center justify-center rounded-lg text-white";

    switch(key) {
      case "hrAdminName":
        return <div className="text-black text-left my-[-0.5rem]">
          <div>{data["hrAdminName"]}</div>
          <ul className='list-disc pl-4'>
            {data["hrAdminEmail1"] ? <li>{data["hrAdminEmail1"]}</li> : ""}
            {data["hrAdminEmail2"] ? <li>{data["hrAdminEmail2"]}</li> : ""}
          </ul>
        </div>;
      case "tokenId":
        if (data[key]) {
          return <div className="cursor-pointer w-40 rounded-lg text-white text-center py-3 px-7 my-[-0.5rem] bg-[#289B95]" onClick={() => handleViewToken(data)}>View Token</div>
        } else {
          return <div className="my-[-0.5rem] w-40">
            <div className="text-black underline text-left w-42">Token Not Exist</div>
            <div className="cursor-pointer rounded-lg text-white text-center py-3 px-7 w-42 bg-[#193341]" onClick={() => handleNewToken(data)}>Create Token</div>
          </div>
        }
      case "action":
        return (<div className="flex gap-2">
          <button className={`${classNameAction} bg-orange-500`}
            onClick={async() => { 
              await getHrAdminList(data.companyId);
              setSelectedData(data);
              setModalFormTitle("Update Company");
              setModalFormButtonText("Update Company");
              setModalFormIsUpdate(true);
              setModalFormVisible(true);
            }}>
            <FaPencilAlt className="h-4 w-4 m-3"/>
          </button>
          <button className={`${classNameAction} bg-red-700`}
            onClick={() => {
              setSelectedData(data);
              setModalAlertText(`Are you sure you want to delete this company ?`);
              setModalAlertIsConfirmation(true);
              setModalAlertIsDelete(true);
              setModalAlertVisible(true);
            }} >
            <FaTrashAlt className="h-4 w-4 m-3"/>
          </button>
        </div>);
      default:
        return (<>{data[key]}</>)
    }
  };

  const mainContent = <>
    <div className='flex flex-col md:flex-row gap-2 mb-5 px-4 md:px-0'>
      <button className="btn flex w-full gap-[0.625rem] border-none bg-accent normal-case text-white
            hover:bg-accent hover:bg-opacity-90 md:min-h-[3.375rem] md:w-auto md:py-4 md:px-8"
        onClick={() => { 
          formik.resetForm();
          setSelectedData(null);
          setModalFormTitle("New Company");
          setModalFormButtonText("Add New Company");
          setModalFormIsUpdate(false);
          setModalFormVisible(true);
        }}
      >
        <AiFillPlusSquare className="h-4 w-4 mt-1 mr-2 ml-5"/>New Company
      </button>
      <SearchBox className="flex-auto"
        searchByValue={masterSearchByValue}
        searchByOnChange={masterSearchByOnChange}
        searchByOptions={masterSearchByOptions}
        searchTextOnChange={mastersearchTextOnChange}
        searchTextValue={masterSearchText}
      />
    </div>
    <Table 
      tableTitle="Company List"
      headerData={masterHeader}
      bodyData={masterBody}
      resultsAmount={masterResultsAmount}
      resultsTotal={masterResultsTotal}
      resultsAmountOnChange={masterResultsAmountOnChange}
      pageNumber={masterPageNumber}
      pageNumberTotal={masterPageNumberTotal}
      changePage={masterChangePage}
      bodyFormatting={masterBodyFormatting}
      sortKey={masterSortKey}
      setSortKey={setMasterSortKey}
      sortIsAsc={masterSortIsAsc}
      setSortIsAsc={setMasterSortIsAsc}
    />
  </>

  return (
    <div className="w-full bg-white pt-9 md:pt-0">
      {mainContent}
      <ModalAlert isVisible={modalAlertVisible} 
        alertText={modalAlertText}
        isConfirmationAlert={modalAlertIsConfirmation}
        isFailedAlert={modalAlertIsFailed}
        handleModalVisibility={() => {
          setModalAlertVisible(false);
          setModalAlertIsConfirmation(false);
          setModalAlertIsDelete(false);
          setModalAlertIsFailed(false);
        }}
        handleConfirm={() =>
          modalAlertIsDelete ? handleDelete(selectedData?.companyId || "") : ""
        }
      />
      <ModalForm isVisible={modalFormVisible} 
        handleModalVisibility={() => setModalFormVisible(!modalFormVisible)} 
        modalTitle={modalFormTitle}
        buttonText={modalFormButtonText}
        formContent={<FormContent
          formik={formik}
          modalFormIsUpdate={modalFormIsUpdate}
          hrAdminList={hrAdminList}
        />}
        handleConfirm={formik.handleSubmit}
      />
    </div>
  );
}
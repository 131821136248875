import { createAxiosInstance } from "./AxiosInstance";

const LoginRoute = "/auth/login";
const RefreshTokenRoute = "/auth/refresh-token";
const LogoutRoute = "/auth/logout";
const RequestResetPasswordRoute = "/reset-password/request";
const CheckResetPasswordRoute = "/reset-password/check";
const ChangeResetPasswordRoute = "/reset-password/change";

export const AuthService = {
  LoginUser,
  RefreshUserToken,
  LogoutUser,
  RequestResetPassword,
  CheckResetPassword,
  ChangeResetPassword,
};

async function LoginUser(email: String, password: String) {
  const result = createAxiosInstance()
    .post(LoginRoute, { email, password })
    .then((res) => { return res; })
    .catch((err) => { console.log(err); return err; });

  return result;
}

async function RefreshUserToken() {
  const result = createAxiosInstance()
    .post(RefreshTokenRoute)
    .then((res) => { return res; })
    .catch((err) => { console.log(err); return err; });

  return result;
}

async function LogoutUser() {
  const result = createAxiosInstance()
    .post(LogoutRoute)
    .then((res) => { return res; })
    .catch((err) => { console.log(err); return err; });

  return result;
}

async function RequestResetPassword(email: string) {
  const result = createAxiosInstance()
    .post(RequestResetPasswordRoute, { email })
    .then((res) => { return res; })
    .catch((err) => { console.log(err); return err; });

  return result;
}

async function CheckResetPassword(token: string) {
  const result = createAxiosInstance()
    .post(CheckResetPasswordRoute, { token })
    .then((res) => { return res; })
    .catch((err) => { console.log(err); return err; });

  return result;
}

async function ChangeResetPassword(token: string, newPassword: string, confirmationPassword: string) {
  const result = createAxiosInstance()
    .post(ChangeResetPasswordRoute, { token, newPassword, confirmationPassword })
    .then((res) => { return res; })
    .catch((err) => { console.log(err); return err; });

  return result;
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

export enum Roles {
  MASTER_ADMIN = 'MASTER_ADMIN',
  ADMIN = 'ADMIN',
  COUNSELOR = 'COUNSELOR',
  HR_ADMIN = 'HR_ADMIN',
  EMPLOYEE = 'EMPLOYEE',
};

export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export interface AuthState {
  accessToken: string,
  role: Roles,
  name: string,
};

const initialState: AuthState = {
  accessToken: "",
  role: Roles.EMPLOYEE,
  name: "",
};

function decodeJWT(jwt: string) {
  const decodedJWT: any = jwt_decode(jwt);
  switch (decodedJWT.role) {
    case "MASTER_ADMIN": return { role: Roles.MASTER_ADMIN, name: decodedJWT.name };
    case "ADMIN": return { role: Roles.ADMIN, name: decodedJWT.name };
    case "COUNSELOR": return { role: Roles.COUNSELOR, name: decodedJWT.name };
    case "HR_ADMIN": return { role: Roles.HR_ADMIN, name: decodedJWT.name };
    default: return { role: Roles.EMPLOYEE, name: decodedJWT.name };
  }
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
      const { role, name } = decodeJWT(action.payload);
      state.role = role;
      state.name = name;
    },
    logoutUser: (state) => {
      state.accessToken = "";
    },
  },
});

export const { loginUser, logoutUser } = authSlice.actions;
export default authSlice.reducer;

import { useState, useEffect } from 'react';
import { useFormik } from "formik";

import SearchBox from '../../components/SearchBox';
import Table from '../../components/Table';

import { FaPencilAlt, FaTrashAlt, FaPlusSquare } from 'react-icons/fa';

import ModalAlert from '../../components/ModalAlert';
import ModalForm from '../../components/ModalForm';
import { SelectionBox, SelectionBoxAsync } from "../../components/SelectionBox";
import FormContent from './FormContent';

import { Roles, Status } from '../auth/Auth.Slice';

import { UserAccessService } from '../../services/UserAccessService';

export function UserAccess() {
  const [refresh, setRefresh] = useState(false);
  const [modalFormIsUpdate, setModalFormIsUpdate] = useState(false);
  const [modalAlertVisible, setModalAlertVisible] = useState(false);
  const [modalAlertText, setModalAlertText] = useState("User Succesfully Created");
  const [modalAlertIsConfirmation, setModalAlertIsConfirmation] = useState(false);
  const [modalFormVisible, setModalFormVisible] = useState(false);
  const [modalFormTitle, setModalFormTitle] = useState("New User");
  const [modalFormButtonText, setModalFormButtonText] = useState("Create New User");
  const [masterResultsAmount, setMasterResultsAmount] = useState(5);
  const [masterResultsTotal, setMasterResultsTotal] = useState(5);
  const [masterPageNumber, setMasterPageNumber] = useState(1);
  const [masterPageNumberTotal, setMasterPageNumberTotal] = useState(2);
  const [masterSearchByValue, setMasterSearchByValue] = useState("all");
  const [masterSearchText, setMasterSearchText] = useState("");
  const [masterBody, setMasterBody] = useState([]);
  const [masterSortKey, setMasterSortKey] = useState("");
  const [masterSortIsAsc, setMasterSortIsAsc] = useState(true);
  const [selectedUser, setSelectedUser] = useState('');
  const [modalAlertIsDelete, setModalAlertIsDelete] = useState(false);
  const [modalAlertIsFailed, setModalAlertIsFailed] = useState(false);

  const [roleFilter, setRoleFilter] = useState('');
  const [roleFilterValue, setRoleFilterValue] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [statusFilterValue, setStatusFilterValue] = useState('');

  const masterSearchByOnChange = (e: any) => { setMasterSearchByValue(e.target.value); };
  const mastersearchTextOnChange = (e: any) => { setMasterSearchText(e.target.value); setRefresh(true)};
  const masterResultsAmountOnChange = (e: any) => { setMasterResultsAmount(e.target.value); };
  const masterChangePage = (e: any) => { setMasterPageNumber(e.selected+1); };

  useEffect(() => {
    const sort = (!masterSortKey) ? 'desc(createdAt)' : ( masterSortIsAsc ? `asc(${masterSortKey})` : `desc(${masterSortKey})` );

    const getUserAccess = async()=>{
      const response = await UserAccessService.getUserAccess(
        masterPageNumber,
        masterResultsAmount,
        masterSearchByValue,
        masterSearchText,
        sort,
        roleFilterValue,
        statusFilterValue
      )
        
      setMasterPageNumberTotal(response?.data?.page?.totalPage);
      setMasterResultsTotal(response?.data?.page?.totalCount);
      setMasterBody(response?.data?.data);
    }

    getUserAccess();
    if (refresh) {
      setRefresh(false);
      setMasterPageNumber(1);
    }
  }, [
    masterPageNumber,
    masterResultsAmount,
    masterSearchByValue, 
    masterSearchText,
    refresh,
    masterSortIsAsc,
    masterSortKey,
    roleFilterValue,
    statusFilterValue
  ]);

  const roleOptions = [
    {label:'Admin', value:Roles.ADMIN},
    {label:'Master Admin', value:Roles.MASTER_ADMIN},
    {label:'HR Admin', value:Roles.HR_ADMIN}
  ];

  const statusOptions = [
    {label:'Active', value:Status.ACTIVE},
    {label:'Inactive', value:Status.INACTIVE}
  ];
  
  const masterSearchByOptions = [
    { value:"all", name:"All" },
    { value:"name", name:"Name" },
    { value:"email", name:"Email" },
    { value:"role", name:"Role" },
    { value:"company", name:"Company" },
    { value:"status", name:"Status" }];

  const masterHeader = [
    { key:"name", name:"Name" },
    { key:"email", name:"Email" },
    { key:"role", name:"Role" },
    { key:"companyName", name:"Company" },
    { key:"status", name:"Status" },
    { key:"action", name:"Action", noSort:true }];

  const masterBodyFormatting = (key: string, data: any): JSX.Element => {
    let classNameAction = "flex w-[30] items-center justify-center rounded-lg text-white";
    
    const company = data.companyId ? {companyId: data.companyId, companyName: data.companyName} : '';
    switch(key) {
        case "companyName":
            return(<div>{(data.companyName) ? data.companyName : '-'}</div>)
        case "role":
            const role = roleOptions.find((obj)=>{return obj.value === data.role})?.label;
            return(<div>{role}</div>)
        case "status":
            const status = statusOptions.find((obj)=>{return obj.value === data.status})?.label;
            return(<div>{status}</div>)
        case "email":
            if(data.secondEmail){
              return(
                <ul className='list-disc pl-4'>
                  <li>{data.email}</li>
                  <li>{data.secondEmail}</li>
                </ul>
              )
            }
            else return(<div>{data.email}</div>)
        case "action":
            return (<div className="flex gap-2">
            <button className={`${classNameAction} bg-orange-500`}
                onClick={() => {
                setModalFormTitle("Update User");
                setModalFormButtonText("Update User");
                setModalFormVisible(true);
                setModalFormIsUpdate(true);
                formik.setFieldValue('name', data.name);
                formik.setFieldValue('email', data.email);
                formik.setFieldValue('newEmail', data.email);
                formik.setFieldValue('role', roleOptions.find((obj)=>{return obj.value === data.role}));
                formik.setFieldValue('company', company);
                formik.setFieldValue('status', data.status);
                formik.setFieldValue('secondEmail', data.secondEmail);
                formik.setFieldValue('employeeId', data.employeeId);
                }}>
                <FaPencilAlt className="h-4 w-4 m-3"/>
            </button>
            <button className={`${classNameAction} bg-red-700`}
                onClick={() => {
                setModalAlertText(`Are you sure you want to delete this user?`);
                setSelectedUser(data.email);
                setModalAlertIsConfirmation(true);
                setModalAlertVisible(true);
                setModalAlertIsDelete(true);
                }} >
                <FaTrashAlt className="h-4 w-4 m-3"/>
            </button>
            </div>);
        default:
            return (<>{data[key]}</>)
        }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "", 
      email: "", 
      secondEmail: "", 
      role: {label:'',value:''}, 
      company: {companyId:'', companyName:''}, 
      status: "active",
      employeeId:"",
      newEmail:""
    },
    onSubmit: async (values) => {
      if(modalFormIsUpdate){
        const result = await UserAccessService.updateUserAccess(values);
        handleResponse(result, true, false);
      } else{
        const result = await UserAccessService.createUserAccess(values);
        handleResponse(result, false, false);
      }
    },
  });

  const deleteUser = async (email: string)=>{
    const result = await UserAccessService.deleteUserAccess(email);
    handleResponse(result, false, true);
  }

  const handleResponse = (result: any, isUpdate = false, isDelete = false) => {
    if (result?.data?.code === "200") {
      setModalAlertText(result?.data?.data?.message);
      setModalAlertVisible(true);
      if (isUpdate) {
        setModalFormIsUpdate(false);
      }
      setModalFormVisible(false);
      setRefresh(true);
      formik.resetForm();
    } else {
      if (result?.code === "ERR_NETWORK") {
        setModalAlertText("Can't connect to the server");
        setModalAlertVisible(true);
        setModalAlertIsFailed(true);
      } else if (!isDelete) {
        const errors = result?.response?.data?.errors;
        for (let key of Object.keys(errors)) {
          formik.setFieldError(key, errors[key]);
        }
      }
    }
    if (isDelete) {
      setModalAlertIsConfirmation(false);
    }
  };

  const SearchBar = (
    <div className='flex w-full'>
      <div className="flex flex-1 w-0 flex-col md:flex-row gap-2 mb-5">
        <div
          className="btn flex gap-[0.625rem] py-4 px-8 min-h-[3.375rem]
            bg-accent hover:bg-accent hover:bg-opacity-90 text-white normal-case border-none"
          onClick={() => {
            setModalFormTitle("New User");
            setModalFormButtonText("Create New User");
            setModalFormIsUpdate(false);
            setModalFormVisible(true);
          }}
        >
          <FaPlusSquare size={16} />
          <span>New User</span>
        </div>
        <SearchBox
          className="flex-auto"
          searchByValue={masterSearchByValue}
          searchByOnChange={masterSearchByOnChange}
          searchByOptions={masterSearchByOptions}
          searchTextOnChange={mastersearchTextOnChange}
          searchTextValue={masterSearchText}
        />
        <SelectionBox
          className="w-full md:w-[25rem]"
          placeholder="Show Role All"
          value={roleFilter}
          options={roleOptions}
          handleChange={(obj: any) => {
            setRoleFilter(obj); 
            (obj) ? setRoleFilterValue(obj.value) : setRoleFilterValue('');
          }}
          styleTemplate="page"
        />
        <SelectionBox
          className="w-full md:w-[25rem]"
          placeholder="Show Status All"
          value={statusFilter}
          options={statusOptions}
          handleChange={(obj: any) => {
            setStatusFilter(obj); 
            (obj) ? setStatusFilterValue(obj.value) : setStatusFilterValue('');
          }}
          styleTemplate="page"
        />
      </div>
    </div>
  );

  const UserContent = (
      <div className="w-full mb-4">
        <div className="flex flex-row w-full">
            <div className="h-full w-0 flex-1">
                <Table 
                    tableTitle="User List"
                    headerData={masterHeader}
                    bodyData={masterBody}
                    resultsAmount={masterResultsAmount}
                    resultsTotal={masterResultsTotal}
                    resultsAmountOnChange={masterResultsAmountOnChange}
                    resultsAmountOptions={[5, 10, 20, 30, 40, 50]}
                    pageNumber={masterPageNumber}
                    pageNumberTotal={masterPageNumberTotal}
                    changePage={masterChangePage}
                    bodyFormatting={masterBodyFormatting}
                    sortKey={masterSortKey}
                    setSortKey={setMasterSortKey}
                    sortIsAsc={masterSortIsAsc}
                    setSortIsAsc={setMasterSortIsAsc}
                />
            </div>
        </div>
      </div> 
  );

  return (
    <> 
        <div className="flex flex-col min-h-screen px-4 md:px-0 mt-12 md:mt-0">
            {SearchBar}
            {UserContent}
        </div>
        <ModalAlert 
          isVisible={modalAlertVisible} 
          alertText={modalAlertText}
          isConfirmationAlert={modalAlertIsConfirmation}
          isFailedAlert={modalAlertIsFailed}
          handleModalVisibility={() => {
            setModalAlertVisible(!modalAlertVisible);
            setModalAlertIsConfirmation(false);
            setModalAlertIsDelete(false);
            setModalAlertIsFailed(false);
          }}
          handleConfirm={
            ()=>{
              if(modalAlertIsDelete) deleteUser(selectedUser);
            }
          }
        />
        <ModalForm
            isVisible={modalFormVisible}
            handleModalVisibility={() => {
              setModalFormVisible(!modalFormVisible);
              formik.resetForm();
            }}
            modalTitle={modalFormTitle}
            buttonText={modalFormButtonText}
            formContent={<FormContent 
              formik={formik} 
              roleOptions={roleOptions}
              isUpdate={modalFormIsUpdate}
              statusOptions={statusOptions}
            />}
            handleConfirm={formik.handleSubmit}
        />
    </>
  );
}
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const TabsPanel = ({
    panelsData,
    selectedIndex,
    setSelectedIndex,
} : {
    panelsData: any[],
    selectedIndex: number,
    setSelectedIndex: any,
}) => {

    return (
        <Tabs className="w-full" selectedIndex={selectedIndex} onSelect={setSelectedIndex}>
            <TabList className="bg-[#F2F2F2] border-[#F2F2F2] cursor-pointer inline-flex w-full">
                {panelsData.map((item: any, index: number) => ( 
                <Tab selectedClassName="bg-white border-[#289B95] border-t-4" className="border-t-4 flex-1 rounded-t py-4 text-black" key={index}>{item.tabTitle}</Tab>
                ))}
            </TabList>
            {panelsData.map((item: any, index: number) => ( 
                <TabPanel className="pt-4" key={index}>{item.tabContent}</TabPanel>
            ))}
        </Tabs>
    );
};

TabsPanel.defaultProps = {
}

export default TabsPanel;
import { createAxiosInstance } from "./AxiosInstance";

const ExpertiseRoute = "/expertise";

type Expertise = {
  id: string;
  name: string;
};

export const ExpertiseService = {
  getExpertise,
  getExpertiseOptions,
  createExpertise,
  updateExpertise,
  deleteExpertise
};

async function getExpertise(page: number, pageSize: number, searchText: string, sort: string) {
  const result = await createAxiosInstance()
    .get(ExpertiseRoute, {
      params: {
        sort,
        page: page,
        size: pageSize,
        name: searchText,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function getExpertiseOptions(searchText: string) {
  const result = await createAxiosInstance()
    .get(ExpertiseRoute, {
      params: {
        page: 1,
        size: 20,
        name: searchText,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result?.data?.data;
}

async function createExpertise(expertise: Expertise) {
  const result = await createAxiosInstance()
    .post(
      ExpertiseRoute,
      {name: expertise.name}
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function updateExpertise(expertise: Expertise) {
  const result = await createAxiosInstance()
    .put(
      ExpertiseRoute,
      expertise
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function deleteExpertise(id: String) {
  const result = await createAxiosInstance()
    .delete(
      ExpertiseRoute,
      {
        data:{
          id
        }
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}
import React, { Dispatch, SetStateAction, useCallback } from "react";
import { HTMLInputTypeAttribute, useState, useEffect } from "react";
import { FaCheck, FaChevronLeft, FaListUl, FaPencilAlt, FaTimes } from "react-icons/fa";
import { NavLink, useParams } from "react-router-dom";
import { FormikProps, useFormik } from "formik";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Main } from "../../components/Main";
import { ReminderInterface } from "../../util/interfaces";
import RichTextEditor from "./RichTextEditor";
import { useAppDispatch } from "../../app/hooks";
import { startTransparentLoading, stopTransparentLoading } from "../../App.Slice";
import { handleResponse } from "../../services/HandleResponse";
import { ReminderService } from "../../services/ReminderService";
import ModalAlert from "../../components/ModalAlert";

export function ViewReminderDetail() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [originalEditorState, setOriginalEditorState] = useState(EditorState.createEmpty());
  const [originalValues, setOriginalValues] = useState<ReminderInterface>();
  const [nameEdit, setNameEdit] = useState(false);
  const [tokenLeftEdit, setTokenLeftEdit] = useState(false);
  const [durationLeftEdit, setDurationLeftEdit] = useState(false);
  const [emailSubjectEdit, setEmailSubjectEdit] = useState(false);
  const [emailContentEdit, setEmailContentEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [modalAlertVisible, setModalAlertVisible] = useState(false);
  const [modalAlertText, setModalAlertText] = useState("Email Reminder Succesfully Created");
  const [modalAlertIsFailed, setModalAlertIsFailed] = useState(false);

  const formik: FormikProps<ReminderInterface> = useFormik({
    enableReinitialize: true,
    initialValues: { id: "", name: "", tokenLeft: "", durationLeft: "", emailSubject: "", emailContent: "" },
    onSubmit: async (values) => {
      await handleEditReminder(
        values.id, values.name,
        values.tokenLeft, values.durationLeft,
        values.emailSubject, values.emailContent
      );
    },
  });

  const handleEditCancel = () => {
    formik.setValues(
      originalValues || {
        id: "", name: "",
        tokenLeft: "", durationLeft: "",
        emailSubject: "", emailContent: ""
      },
      false
    );
    setEditorState(originalEditorState);
  }

  const handleEditReminder = async (
    id: string,
    name: string,
    tokenLeft: string,
    durationLeft: string,
    emailSubject: string,
    emailContent: string
  ) => {
    dispatch(startTransparentLoading());
    const result = await ReminderService.EditReminder(
      id, name, tokenLeft, durationLeft, emailSubject, emailContent
    );
    handleResponse({
      result: result,
      handleSuccess: (result: any) => {
        setModalAlertText(result?.data?.data?.message);
        setModalAlertVisible(true);
        setModalAlertIsFailed(false);
        setRefresh(true);
      },
      handleFailed: (result: any) => {
        formik.setErrors(result?.response?.data?.errors);
        handleEditCancel();
      },
    })
    dispatch(stopTransparentLoading());
  }

  const getReminderByID = useCallback(
    async (id: string) => {
      dispatch(startTransparentLoading());
      const result = await ReminderService.GetReminderByID(id);
      handleResponse({
        result: result,
        handleSuccess: (result: any) => {
          const { id, name, tokenLeft, durationLeft, emailSubject, emailContent } = result?.data?.data;
          const values = {
            id: id, name: name,
            tokenLeft: tokenLeft.toString(),
            durationLeft: durationLeft.toString(),
            emailSubject: emailSubject,
            emailContent: emailContent
          }
          formik.setValues(values, false);
          setOriginalValues(values);

          const initialEditorState = EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(result?.data?.data?.emailContent).contentBlocks
            )
          );
          setEditorState(initialEditorState);
          setOriginalEditorState(initialEditorState);
        },
        handleFailed: (result: any) => {
          console.log(result?.response?.data?.errors?.id);
        },
      })
      dispatch(stopTransparentLoading());
    }, [dispatch],
  );

  useEffect(() => {
    if (id) getReminderByID(id);
    setRefresh(false);
  }, [getReminderByID, refresh, id]);

  const backButton = (
    <div className="flex justify-start items-center gap-2">
      <NavLink
        to="/reminder"
        className="btn btn-ghost p-0 m-0 min-h-0 h-8 w-8 border-none"
      >
        <FaChevronLeft color="#193341" size={20} />
      </NavLink>
      <span className="text-[#289B95] text-xl font-bold">
        Email Reminder Detail
      </span>
    </div>
  );

  const formField = (
    type: HTMLInputTypeAttribute,
    label: string,
    name: string,
    placeholder: string,
    value: string | number,
    error: string | undefined,
    onChange: (e: React.ChangeEvent<any>) => void,
    onlyInteger: boolean,
    edit: boolean,
    setEdit: Dispatch<SetStateAction<boolean>>,
    handleCancel: () => any
  ) => (
    <div className="flex flex-col w-full">
      <label className="label">
        <span className="label-text text-lg text-black font-bold">
          {label}
        </span>
      </label>
      <div className="input-group">
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            const re = /^\d+$/;
            if (onlyInteger && e.target.value && !re.test(e.target.value.toString())) return;
            onChange(e);
          }}
          className={`input border-black !input-bordered text-lg text-black font-normal bg-white p-4
            ${error ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""} ${edit ? "" : "hidden"} w-full`}
        />
        <button
          className={`${edit ? "" : "hidden"}
            btn btn-square btn-success bg-[#28A745] border-black border-opacity-20`}
          type="button"
          onClick={(e) => {
            formik.handleSubmit();
            setEdit(false);
          }}
        >
          <FaCheck color="#FFFFFF" />
        </button>
        <button
          className={`${edit ? "" : "hidden"} rounded-r-lg
            btn btn-square btn-error bg-[#DC3545] border-black border-opacity-20`}
          type="button"
          onClick={() => {
            setEdit(false);
            handleCancel();
          }}
        >
          <FaTimes color="#FFFFFF" />
        </button>
        <label className={`${edit ? "hidden" : ""} label flex-1 p-0`}>
          <span className="label-text text-lg text-black font-normal bg-transparent">
            {value}
          </span>
        </label>
        <button
          className={`${edit ? "hidden" : ""}
            btn btn-square btn-ghost`}
          type="button"
          onClick={() => setEdit(true)}
        >
          <FaPencilAlt color="#7F7F7F" />
        </button>
      </div>
      <label className={`label ${error ? "" : "hidden"}`}>
        <span className="label-text text-lg text-[#FF0000] font-bold">{error}</span>
      </label>
    </div>
  );

  const nameField = formField(
    "text", "Reminder Name", "name", "Reminder Name",
    formik.values.name, formik.errors.name, formik.handleChange, false,
    nameEdit, setNameEdit, handleEditCancel,
  );

  const tokenLeftField = formField(
    "text", "Token Left", "tokenLeft", "Token Left",
    formik.values.tokenLeft, formik.errors.tokenLeft, formik.handleChange, true,
    tokenLeftEdit, setTokenLeftEdit, handleEditCancel,
  );

  const periodLeftField = formField(
    "text", "Validity Period Name (Days)", "durationLeft", "Validity Period Name (Days)",
    formik.values.durationLeft, formik.errors.durationLeft, formik.handleChange, true,
    durationLeftEdit, setDurationLeftEdit, handleEditCancel,
  );

  const emailSubjectField = formField(
    "text", "Email Subject", "emailSubject", "Email Subject",
    formik.values.emailSubject, formik.errors.emailSubject, formik.handleChange, false,
    emailSubjectEdit, setEmailSubjectEdit, handleEditCancel,
  );

  const richTextEditorOnchange = (state: EditorState) => {
    if (!state.getCurrentContent().getPlainText())
      return formik.setFieldValue("emailContent", "");
    const emailContent = draftToHtml(convertToRaw(state.getCurrentContent()));
    formik.setFieldValue("emailContent", emailContent);
  }

  const emailContentField = (
    <div className="">
      <label className="label">
        <span className="label-text text-lg text-black font-bold">
          Email Content
        </span>
      </label>
      <div className={`${formik.errors.emailContent ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""}`}>
        <RichTextEditor
          readOnly={!emailContentEdit}
          editorState={editorState}
          setEditorState={setEditorState}
          onChange={richTextEditorOnchange}
        />
      </div>
      <label className={`label ${formik.errors.emailContent ? "" : "hidden"}`}>
        <span className="label-text text-lg text-[#FF0000] font-bold">{formik.errors.emailContent}</span>
      </label>
      <button type="button"
        className={`${emailContentEdit ? "hidden" : ""}
          btn bg-[#193341] text-white w-full mt-4`}
        onClick={() => setEmailContentEdit(true)}
      >
        Edit Konten Email
      </button>
      <div className={`${emailContentEdit ? "" : "hidden"} w-full flex gap-4 mt-4`}>
        <button type="button"
          className={`btn btn-success bg-[#28A745] text-white flex-1`}
          onClick={(e) => {
            formik.handleSubmit();
            setEmailContentEdit(false);
          }}
        >
          Save
        </button>
        <button type="button"
          className={`btn btn-error bg-[#DC3545] text-white flex-1`}
          onClick={() => {
            setEmailContentEdit(false);
            handleEditCancel();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );

  const editForm = (
    <form className="flex flex-col gap-0 md:flex-row md:gap-8">
      <div className="w-full md:w-1/5">
        {nameField}
        {tokenLeftField}
        {periodLeftField}
      </div>
      <div className="flex-1">
        {emailSubjectField}
        {emailContentField}
      </div>
    </form>
  );

  const content = (
    <div className="flex w-full flex-col gap-7 px-4 py-12 md:px-0 md:py-0 text-black">
      {backButton}
      {editForm}
    </div>
  );

  return (
    <>
      <Main
        title={"Email Reminder"}
        subtitle={`Email Reminder`}
        icon={<FaListUl className="w-4 h-4" />}
        user={"user"}
      >
        {content}
      </Main>
      <ModalAlert
        isVisible={modalAlertVisible}
        alertText={modalAlertText}
        isConfirmationAlert={false}
        isFailedAlert={modalAlertIsFailed}
        handleModalVisibility={() => {
          setModalAlertVisible(!modalAlertVisible);
        }}
      />
    </>
  );
}

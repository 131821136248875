import { createAxiosInstance } from "./AxiosInstance";
import { fixTimezoneOffset } from "../util/formatter/formatter";
import { CounselorFormatter } from "../util/formatter/CounselorFormatter";
import { CounselorInterface } from "../util/interfaces";

const CounselorRoute = "/counselor";
const CounselorRouteApproval = CounselorRoute + "/approval";

export const CounselorService = {
  getCounselors,
  getCounselorOptions,
  getCounselorApprovals,
  createCounselor,
  updateCounselor,
  deleteCounselor,
};

async function getCounselors(
  page: number,
  pageSize: number,
  searchBy: string = "",
  searchText: string = "",
  sortKey: string = "",
  isAsc: boolean = false,
  filterGender: any,
  filterSessionType: any,
  filterExpertise: any
) {
  const result = await createAxiosInstance()
    .get(CounselorRoute, {
      params: {
        page: page,
        size: pageSize,
        searchBy: searchBy,
        searchQuery: searchText,
        sort:
          sortKey !== ""
            ? isAsc
              ? `asc(${sortKey})`
              : `desc(${sortKey})`
            : undefined,
        gender: filterGender?.value || "",
        idExpertise: filterExpertise?.id || "",
        idSessionType: filterSessionType?.id || "",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function getCounselorOptions(searchText: string) {
  const result: any = await createAxiosInstance()
    .get(CounselorRoute, {
      params: {
        page: 1,
        size: 20,
        searchQuery: searchText,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result?.data?.data;
}

async function getCounselorApprovals(
  page: number,
  pageSize: number,
  searchBy: string = "",
  searchText: string = "",
  sortKey: string = "",
  isAsc: boolean = false,
  filterStatus: any,
  filterGender: any,
  filterSessionType: any,
  filterExpertise: any,
  filterDateStart: Date | null,
  filterDateEnd: Date | null,
) {
  const result = await createAxiosInstance()
    .get(CounselorRouteApproval, {
      params: {
        page: page,
        size: pageSize,
        searchBy: searchBy,
        searchQuery: searchText,
        sort:
          sortKey !== ""
            ? isAsc
              ? `asc(${sortKey})`
              : `desc(${sortKey})`
            : undefined,
        status: filterStatus?.value || "",
        gender: filterGender?.value || "",
        idExpertise: filterExpertise?.id || "",
        idSessionType: filterSessionType?.id || "",
        dateStart: fixTimezoneOffset(filterDateStart),
        dateEnd: fixTimezoneOffset(filterDateEnd),
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function createCounselor(counselor: CounselorInterface) {
  const result = await createAxiosInstance()
    .post(CounselorRoute, CounselorFormatter.formatCreateCounselor(counselor))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function updateCounselor(counselor: CounselorInterface) {
  const result = await createAxiosInstance()
    .put(CounselorRoute, CounselorFormatter.formatUpdateCounselor(counselor))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function deleteCounselor(id: string) {
  const result = await createAxiosInstance()
    .delete(CounselorRoute, {
      data: {
        id: id,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

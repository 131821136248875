import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { MdClose } from "react-icons/md";

import { NavLink, useLocation, useNavigate } from "react-router-dom";

import {
  FaHome,
  FaBuilding,
  FaClipboardList,
  FaListOl,
  FaPenNib,
  FaUserMd,
  FaEnvelope,
  FaSignInAlt,
} from "react-icons/fa";

import { MdPsychology } from 'react-icons/md';

import DexwellnessLogo from "../images/dexwellness.png";
import { closeSidebar } from "../App.Slice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Roles } from "../features/auth/Auth.Slice";

export const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const role = useAppSelector(state => state.auth.role);

  const items = [
    {
      icon: <FaHome className="w-4 h-4" />,
      title: "Dashboard",
      target: "/",
      roles: [Roles.HR_ADMIN, Roles.ADMIN, Roles.MASTER_ADMIN],
    },
    {
      icon: <FaBuilding className="w-4 h-4" />,
      title: "Company",
      target: "/company",
      roles: [Roles.ADMIN, Roles.MASTER_ADMIN],
    },
    {
      icon: <FaClipboardList className="w-4 h-4" />,
      title: "Token",
      target: "/token",
      roles: [Roles.ADMIN, Roles.MASTER_ADMIN],
    },
    {
      icon: <FaPenNib className="w-4 h-4" />,
      title: "Session",
      target: "/session",
      roles: [Roles.ADMIN, Roles.MASTER_ADMIN],
    },
    {
      icon: <FaListOl className="w-4 h-4" />,
      title: "Session Type",
      target: "/session-type",
      roles: [Roles.ADMIN, Roles.MASTER_ADMIN],
    },
    {
      icon: <FaUserMd className="w-4 h-4" />,
      title: "Counselor",
      target: "/counselor",
      roles: [Roles.ADMIN, Roles.MASTER_ADMIN],
    },
    {
      icon: <MdPsychology className="w-4 h-4" />,
      title: "Expertise",
      target: "/expertise",
      roles: [Roles.ADMIN, Roles.MASTER_ADMIN],
    },
    {
      icon: <FaEnvelope className="w-4 h-4" />,
      title: "Email Reminder",
      target: "/reminder",
      roles: [Roles.ADMIN, Roles.MASTER_ADMIN],
    },
    {
      icon: <FaSignInAlt className="w-4 h-4" />,
      title: "User Access",
      target: "/user-access",
      roles: [Roles.MASTER_ADMIN],
    },
  ];

  return (
    <div
      className="w-full h-full md:h-[calc(94vh-3rem)] md:max-w-[18rem] md:rounded-2xl bg-[#F3EADA] text-[#193341]"
    >
      <div className="block md:hidden absolute top-0 right-0 p-2">
        <button
          className="btn btn-ghost p-0 m-0 min-h-0 h-8 w-8 border-none"
          onClick={() => dispatch(closeSidebar())}
        >
          <MdClose size={24} />
        </button>
      </div>
      <div className="h-full py-1 lg:py-8">
        <div className="flex flex-col items-center gap-8 2xl:gap-12 h-full">
          <img
            src={DexwellnessLogo}
            className="h-[7.75rem]"
            alt="Dexwellness Logo"
          />
          <SimpleBar className="w-full h-0 flex-1">
            <div className="flex flex-col items-center gap-2 px-6 lg:gap-4 md:px-4 2xl:px-6 w-full">
              {items.map((item, index) => {
                if (!item.roles.includes(role)) return (
                  <div
                    key={index}
                    className="hidden"
                  />
                );
                else return (
                  <NavLink
                    key={index}
                    to={item.target}
                    className="w-full"
                  >
                    <div
                      className={`p-2 px-3 2xl:p-3 cursor-pointer rounded-lg w-full
                      ${location.pathname === item.target
                          ? "bg-[#193341] text-white"
                          : ""
                        }`}
                    >
                      <div className="flex flex-row items-center gap-4 text-base">
                        <span
                          className={`${location.pathname === item.target
                            ? ""
                            : "text-[#289B95]"
                            }`}
                        >
                          {item.icon}
                        </span>{" "}
                        {item.title}
                      </div>
                    </div>
                  </NavLink>
                )
              })}
            </div>
          </SimpleBar>
        </div>
      </div>
    </div>
  );
};

import { useState, useEffect } from "react";
import { useFormik } from "formik";

import { FaPlusSquare, FaPencilAlt, FaTrashAlt } from "react-icons/fa";

import ModalForm from "../../components/ModalForm";
import ModalAlert from "../../components/ModalAlert";
import SearchBox from "../../components/SearchBox";
import Table from "../../components/Table";

import FormContent from "./FormContent";

import { SessionTypeService } from "../../services/SessionTypeService";
import { SessionTypeInterface } from "../../util/interfaces";

export function SessionType() {
  const [refresh, setRefresh] = useState(0);

  const masterSearchByOptions = [
    { value: "all", name: "All" },
    { value: "sessionTypeName", name: "Session Type Name" },
  ];

  const [masterBody, setMasterBody] = useState([]);
  const [selectedData, setSelectedData] = useState<SessionTypeInterface | null>(null);
  const [modalFormIsUpdate, setModalFormIsUpdate] = useState(false);
  const [modalAlertIsConfirmation, setModalAlertIsConfirmation] =
    useState(false);
  const [modalAlertIsFailed, setModalAlertIsFailed] = useState(false);
  const [modalAlertIsDelete, setModalAlertIsDelete] = useState(false);

  const handleResponse = (result: any, isUpdate = false, isDelete = false) => {
    if (result?.data?.code === "200") {
      setModalAlertText(result?.data?.data?.message);
      setModalAlertVisible(true);
      if (isUpdate) {
        setModalFormIsUpdate(false);
      }
      setModalFormVisible(false);
      setRefresh(refresh + 1);
      formik.resetForm();
    } else {
      if (result?.code === "ERR_NETWORK") {
        setModalAlertText("Can't connect to the server");
        setModalAlertVisible(true);
        setModalAlertIsFailed(true);
      } else if (!isDelete) {
        const errors = result?.response?.data?.errors;
        for (let key of Object.keys(errors)) {
          formik.setFieldError(key, errors[key]);
        }
      }
    }
    if (isDelete) {
      setModalAlertIsConfirmation(false);
    }
  };

  const handleDelete = async (id: string) => {
    const result = await SessionTypeService.deleteSessionType(id);
    handleResponse(result, false, true);
  };

  const formik = useFormik<SessionTypeInterface>({
    enableReinitialize: true,
    initialValues: {
      id: selectedData?.id || "",
      name: selectedData?.name || "",
    },
    onSubmit: async (values) => {
      if (modalFormIsUpdate) {
        const result = await SessionTypeService.updateSessionType(values);
        handleResponse(result, true, false);
      } else {
        const result = await SessionTypeService.createSessionType(values);
        handleResponse(result, false, false);
      }
    },
  });

  const masterBodyFormatting = (key: string, data: any): JSX.Element => {
    let classNameAction =
      "flex w-12 items-center justify-center rounded-lg text-white";
    switch (key) {
      case "action":
        return (
          <div className="flex gap-2">
            <button
              className={`${classNameAction} bg-orange-500`}
              onClick={() => {
                setModalFormTitle("Update Session Type");
                setModalFormButtonText("Update Session Type");
                setModalFormIsUpdate(true);
                setModalFormVisible(true);
                setSelectedData(data);
              }}
            >
              <FaPencilAlt className="h-4 w-4 m-3" />
            </button>
            <button
              className={`${classNameAction} bg-red-700`}
              onClick={() => {
                setModalAlertText(
                  `Are you sure you want to delete this session type ?`
                );
                setModalAlertIsConfirmation(true);
                setModalAlertVisible(true);
                setSelectedData(data);
                setModalAlertIsDelete(true);
              }}
            >
              <FaTrashAlt className="h-4 w-4 m-3" />
            </button>
          </div>
        );
      default:
        return <>{data[key]}</>;
    }
  };

  const [modalAlertVisible, setModalAlertVisible] = useState(false);
  const [modalAlertText, setModalAlertText] = useState(
    "Session Type Succesfully Created"
  );
  const [modalFormVisible, setModalFormVisible] = useState(false);
  const [modalFormTitle, setModalFormTitle] = useState("New Session Type");
  const [modalFormButtonText, setModalFormButtonText] = useState(
    "Add New Session Type"
  );

  const [masterSearchByValue, setMasterSearchByValue] = useState("all");
  const [masterSearchText, setMasterSearchText] = useState("");
  const [masterResultsAmount, setMasterResultsAmount] = useState(10);
  const [masterResultsTotal, setMasterResultsTotal] = useState(10);
  const [masterPageNumber, setMasterPageNumber] = useState(1);
  const [masterPageNumberTotal, setMasterPageNumberTotal] = useState(2);
  const [masterSortKey, setMasterSortKey] = useState("");
  const [masterSortIsAsc, setMasterSortIsAsc] = useState(true);

  const masterSearchByOnChange = (e: any) => {
    setMasterSearchByValue(e.target.value);
  };
  const mastersearchTextOnChange = (e: any) => {
    setMasterSearchText(e.target.value);
  };
  const masterResultsAmountOnChange = (e: any) => {
    setMasterResultsAmount(e.target.value);
  };
  const masterChangePage = (e: any) => {
    setMasterPageNumber(e.selected + 1);
  };

  useEffect(() => {
    setRefresh(refresh + 1);
  }, [masterSearchByValue, masterSearchText, masterResultsAmount, masterSortKey, masterSortIsAsc]);

  useEffect(() => {
    if (!modalFormVisible) {
      setSelectedData(null);
    }
  }, [modalFormVisible])

  useEffect(() => {
    if (!modalAlertVisible) {
      setSelectedData(null);
      setModalAlertIsConfirmation(false);
      setModalAlertIsFailed(false);
    }
  }, [modalAlertVisible])

  useEffect(() => {
    const getSessionTypes = async () => {
      const response = await SessionTypeService.getSessionTypes(
        masterPageNumber,
        masterResultsAmount,
        masterSearchText,
        masterSortKey,
        masterSortIsAsc,
      );
      setMasterPageNumberTotal(response?.data?.page?.totalPage);
      setMasterResultsTotal(response?.data?.page?.totalCount);
      setMasterBody(response?.data?.data);
    };
    if (!refresh) {
      getSessionTypes();
    }
    if (refresh) {
      setRefresh(0);
      setMasterPageNumber(1);
    }
  }, [masterPageNumber, masterResultsAmount, refresh]);

  const masterHeader = [
    {
      key: "name",
      name: "Session Type",
    },
    {
      key: "action",
      name: "Action",
      noSort: true,
    },
  ];

  const mainContent = (
    <div className="flex w-full flex-col gap-7 px-4 md:px-0">
      <div className="flex flex-col items-center gap-2 md:flex-row">
        <div
          className="btn flex w-full gap-[0.625rem] border-none bg-accent normal-case text-white
            hover:bg-accent hover:bg-opacity-90 md:min-h-[3.375rem] md:w-auto md:py-4 md:px-8"
          onClick={() => {
            formik.resetForm();
            setModalFormTitle("New Session Type");
            setModalFormButtonText("Create New Session Type");
            setModalFormIsUpdate(false);
            setModalFormVisible(true);
          }}
        >
          <FaPlusSquare size={16} />
          <span>New Session Type</span>
        </div>
        <SearchBox
          className="flex-auto"
          searchByValue={masterSearchByValue}
          searchByOnChange={masterSearchByOnChange}
          searchByOptions={masterSearchByOptions}
          searchTextOnChange={mastersearchTextOnChange}
          searchTextValue={masterSearchText}
        />
      </div>
      <Table
        tableTitle="Session Type List"
        headerData={masterHeader}
        bodyData={masterBody}
        resultsAmount={masterResultsAmount}
        resultsTotal={masterResultsTotal}
        resultsAmountOnChange={masterResultsAmountOnChange}
        pageNumber={masterPageNumber}
        pageNumberTotal={masterPageNumberTotal}
        changePage={masterChangePage}
        bodyFormatting={masterBodyFormatting}
        sortKey={masterSortKey}
        setSortKey={setMasterSortKey}
        sortIsAsc={masterSortIsAsc}
        setSortIsAsc={setMasterSortIsAsc}
      />
    </div>
  );

  return (
    <div className="w-full bg-white py-12 md:py-0 md:min-h-screen">
      {mainContent}
      <ModalAlert
        isVisible={modalAlertVisible}
        alertText={modalAlertText}
        isConfirmationAlert={modalAlertIsConfirmation}
        isFailedAlert={modalAlertIsFailed}
        handleModalVisibility={() => {
          setModalAlertVisible(!modalAlertVisible);
          setModalAlertIsConfirmation(false);
          setModalAlertIsDelete(false);
          setModalAlertIsFailed(false);        
        }}
        handleConfirm={() =>
          modalAlertIsDelete ? handleDelete(selectedData?.id || "") : ""
        }
      />
      <ModalForm
        isVisible={modalFormVisible}
        handleModalVisibility={() => setModalFormVisible(!modalFormVisible)}
        modalTitle={modalFormTitle}
        buttonText={modalFormButtonText}
        formContent={<FormContent formik={formik} />}
        handleConfirm={formik.handleSubmit}
      />
    </div>
  );
}

import { FormikProps } from "formik";

import {
  SelectionBoxAsync,
  MultiSelectionBoxAsync,
} from "../../components/SelectionBox";

import { CounselorInterface } from "../../util/interfaces";

import { SessionTypeService } from "../../services/SessionTypeService";
import { ExpertiseService } from "../../services/ExpertiseService";

const FormContent = ({
  formik,
  isUpdate,
}: {
  formik: FormikProps<CounselorInterface>;
  isUpdate: boolean;
}) => {
  const genderOptions = [
    {
      label: "Male",
      value: "MALE",
    },
    {
      label: "Female",
      value: "FEMALE",
    },
  ];

  return (
    <div className="flex flex-col gap-0 overflow-y-auto max-h-[30rem] 
      md:max-h-max pr-4 md:pr-0 -mr-4 md:mr-0">
      <div className="flex flex-col md:flex-row w-full gap-1 md:gap-4">
        <div className="text-left text-black md:py-2 w-full">
          <label className="text-base text-black font-bold">
            Counselor Name<span className="text-[#FFA17A]">*</span>
          </label>
          <div className="flex flex-col gap-1">
            <div className="flex justify-between w-full my-2">
              <input
                className={`bg-white border border-color-[#CCCCCC] rounded-lg 
                  p-4 w-full h-[3.375rem] focus:outline-none ${
                  formik.errors.name ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
                }`}
                name="name"
                type="text"
                placeholder="Counselor"
                autoComplete="off"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </div>
            <span className="text-[#FF0000]">{formik.errors.name}</span>
          </div>
        </div>
        <div className="text-left text-black md:py-2 w-full">
          <label className="text-base text-black font-bold">
            Session Type<span className="text-[#FFA17A]">*</span>
          </label>
          <div className="flex flex-col gap-1">
            <div className="flex justify-between w-full my-2">
              <SelectionBoxAsync
                className="w-full h-[3.375rem]"
                placeholder="Session Type"
                value={formik.values.sessionType}
                getOptionLabel={(e: any) => e?.name}
                getOptionValue={(e: any) => e?.id}
                handleChange={(value: any) => {
                  const selected = value
                    ? {
                        id: value?.id,
                        name: value?.name,
                      }
                    : null;
                  formik.setFieldValue("sessionType", selected);
                }}
                loadOptions={SessionTypeService.getSessionTypesOptions}
                styleTemplate="modal"
              />
            </div>
            <span className="text-[#FF0000]">{formik.errors.sessionType}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full gap-1 md:gap-4">
        <div className="text-left text-black md:py-2 w-full">
          <label className="text-base text-black font-bold">
            Token per Hour<span className="text-[#FFA17A]">*</span>
          </label>
          <div className="flex flex-col gap-1">
            <div className="flex justify-between w-full my-2">
              <input
                className={`bg-white border border-color-[#CCCCCC] rounded-lg p-4 w-full 
                  h-[3.375rem] focus:outline-none ${
                  formik.errors.tokenPerHour
                    ? "shadow-[0_0_4px_rgb(255,0,0)]"
                    : ""
                }`}
                name="tokenPerHour"
                type="number"
                min="0"
                step="any"
                placeholder="Token per Hour"
                value={formik.values.tokenPerHour}
                onChange={formik.handleChange}
              />
            </div>
            <span className="text-[#FF0000]">{formik.errors.tokenPerHour}</span>
          </div>
        </div>
        <div className="text-left text-black md:py-2 w-full">
          <label className="text-base text-black font-bold">
            Expertise<span className="text-[#FFA17A]">*</span>
          </label>
          <div className="flex flex-col gap-1">
            <div className="flex justify-between w-full my-2">
              <MultiSelectionBoxAsync
                className="w-full h-[3.375rem]"
                placeholder="Expertise"
                value={formik.values.expertise}
                getOptionLabel={(e: any) => e?.name}
                getOptionValue={(e: any) => e?.id}
                handleChange={(option: any) => {
                  formik.setFieldValue(
                    "expertise",
                    option?.map((item: any) => ({
                      id: item?.id,
                      name: item?.name,
                    }))
                  );
                }}
                loadOptions={ExpertiseService.getExpertiseOptions}
                maxOptions={3}
              />
            </div>
            <span className="text-[#FF0000]">
              {formik.errors.expertise?.toString()}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full gap-1 md:gap-4">
        <div className="text-left text-black md:py-2 w-full">
          <label className="text-base text-black font-bold">
            Email<span className="text-[#FFA17A]">*</span>
          </label>
          <div className="flex flex-col gap-1">
            <div className="flex justify-between w-full my-2">
              <input
                className={`${
                  isUpdate ? "input-disabled border-[#CCCCCC] bg-gray-200" : ""
                } bg-white border border-[#CCCCCC] rounded-lg p-4 w-full 
                h-[3.375rem] focus:outline-none ${
                  formik.errors.email ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
                }`}
                name="email"
                type="text"
                placeholder="Email"
                autoComplete="off"
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled={isUpdate}
              />
            </div>
            <span className="text-[#FF0000]">{formik.errors.email}</span>
          </div>
        </div>
        <div className="text-left text-black md:py-2 w-full">
          <label className="text-base text-black font-bold">
            Gender<span className="text-[#FFA17A]">*</span>
          </label>
          <div className="flex flex-col gap-1">
            <div className="flex gap-2 justify-start w-full my-2">
              {genderOptions?.map((gender, index) => (
                <div key={index} className="form-control">
                  <label className="label cursor-pointer gap-2 items-center">
                    <input
                      type="radio"
                      name="gender"
                      value={gender?.value}
                      className="w-4 h-4"
                      onChange={() =>
                        formik.setFieldValue("gender", gender?.value)
                      }
                      checked={formik.values.gender === gender?.value}
                    />
                    <span className="py-1">{gender?.label}</span>
                  </label>
                </div>
              ))}
            </div>
            <span className="text-[#FF0000]">{formik.errors.gender}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

FormContent.defaultProps = {
  modalFormIsUpdate: false,
};

export default FormContent;

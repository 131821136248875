import { AuthService } from './AuthService';
import { store } from './../app/store';
import axios from "axios";
import { loginUser, logoutUser } from '../features/auth/Auth.Slice';
import { startTransparentLoading, stopTransparentLoading } from '../App.Slice';

export function createAxiosInstance() {
  const accessToken = store.getState().auth.accessToken;
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: {
      Authorization: "Bearer " + accessToken
    }
  });

  axiosInstance.interceptors.request.use(
    function (config) {
      store.dispatch(startTransparentLoading());
      return Promise.resolve(config);
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      store.dispatch(stopTransparentLoading());
      return Promise.resolve(response);
    },
    async (error) => {
      store.dispatch(stopTransparentLoading());
      if (error?.response?.data?.errors?.message === "Access token tidak valid") {
        const originalRequest = error.config;
        originalRequest._retry = false;

        const result = await AuthService.RefreshUserToken();
        if (result?.data?.data?.accessToken) {
          store.dispatch(loginUser(result?.data?.data?.accessToken));
          originalRequest.headers["Authorization"] = "Bearer " + result?.data?.data?.accessToken;
          return Promise.resolve(axiosInstance(originalRequest));
        }
        else {
          store.dispatch(logoutUser());
        }
      }
      return Promise.reject(error);
    },
  );

  return axiosInstance;
}

const masterHeader = [
  { key: "sessionDate", name: "Session Date" },
  { key: "sessionType", name: "Session Type" },
  { key: "sessionTime", name: "Time" },
  { key: "sessionTokenUsed", name: "Used Token" },
  { key: "companyName", name: "Company" },
  { key: "counselorName", name: "Counselor" },
];

const masterSearchByOptions = [
  { value: "all", name: "All" },
  { value: "sessionDate", name: "Session Date" },
  { value: "sessionType", name: "Session Type" },
  { value: "time", name: "Time" },
  { value: "usedToken", name: "Used Token" },
];

export const SessionOptions = {
  masterHeader, masterSearchByOptions
};

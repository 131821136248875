import { FormikProps } from "formik";
import { SelectionBox, SelectionBoxAsync } from "../../components/SelectionBox";

import { UserAccessInterface } from "../../util/interfaces";
import { CompanyService } from "../../services/CompanyService";

import { Roles } from "../auth/Auth.Slice";

const FormContent = ({
  formik,
  roleOptions,
  isUpdate,
  statusOptions
}: {
  formik: FormikProps<UserAccessInterface>
  roleOptions: any
  isUpdate: boolean
  statusOptions: any
}) => {

  return (
    <div className={`overflow-y-auto my-2 md:my-0 flex max-h-[30rem] md:max-h-full gap-4 ${formik.values.role ? ((formik.values.role.value === Roles.HR_ADMIN && isUpdate) ? 'flex-col md:flex-row' : 'flex-col') : 'flex-col'}`}>
      <div className="w-full">
        <div className="text-left text-black py-2">
          <label className="text-base text-black font-bold">
            Name<span className="text-[#FFA17A]">*</span>
          </label>
          <div className="flex flex-col gap-1">
            <div className="flex justify-between w-full my-2">
              <input
                className={`bg-white border rounded p-4 w-full ${
                  formik.errors.name ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
                }`}
                name="name"
                type="text"
                placeholder="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>
        <div className="text-left text-black py-2 w-full">
            <label className="text-base text-black font-bold">
              Role<span className="text-[#FFA17A]">*</span>
            </label>
            <div className="flex flex-col gap-1">
              <div className="flex justify-between w-full my-2">
              <SelectionBox
                className={`w-full ${
                  formik.errors.name ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
                }`}
                placeholder="Role"
                value={formik.values.role ? (formik.values.role.value === '' ? '' : formik.values.role) : ''}
                options={roleOptions}
                handleChange={(obj: any) => formik.setFieldValue('role', obj)}
                styleTemplate="modal"
              />
              </div>
            </div>
        </div>
        <div className={`text-left text-black py-2 ${formik.values.role ? (formik.values.role.value === Roles.HR_ADMIN ? '' : 'hidden') : 'hidden'}`}>
          <label className="text-base text-black font-bold">
            Company<span className="text-[#FFA17A]">*</span>
          </label>
          <div className="flex flex-col gap-1">
            <div className="flex justify-between w-full my-2">
              <SelectionBoxAsync
                className={`w-full h-[3.375rem] ${
                  formik.errors.name ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
                }`}
                placeholder="Company"
                value={
                  formik.values.company ? (formik.values.company.companyName !== '' ? formik.values.company : '') : ''
                }
                getOptionLabel={(e: any) => e?.companyName}
                getOptionValue={(e: any) => e?.companyId}
                handleChange={(value: any) => {
                  if(value){
                    formik.setFieldValue("company", {
                      companyId:value.companyId, companyName:value.companyName
                    });
                  } else {
                    formik.setFieldValue("company", {
                      companyId:'', companyName:''
                    });
                  }
                }}
                loadOptions={CompanyService.getCompanyOptions}
                styleTemplate="modal"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="text-left text-black py-2">
          <label className="text-base text-black font-bold">
            Email<span className="text-[#FFA17A]">*</span>
          </label>
          <div className={`flex justify-between gap-4 py-2 ${isUpdate ? 'flex-col' : 'flex-col md:flex-row'}`}>
            <div className="w-full">
              <input
                className={`bg-white border rounded p-4 w-full ${
                  (formik.errors.name || formik.errors.email) ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
                }`}
                name={isUpdate ? "newEmail" : "email"}
                type="text"
                placeholder="Email"
                value={isUpdate ? formik.values.newEmail : formik.values.email}
                onChange={formik.handleChange}
              />
            </div>
            <div className={`w-full ${formik.values.role ? (formik.values.role.value === Roles.HR_ADMIN ? '' : 'hidden') : 'hidden'}`}>
              <input
                className={`bg-white border rounded p-4 w-full ${
                  formik.errors.secondEmail ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
                }`}
                name="secondEmail"
                type="text"
                placeholder="Second Email"
                value={formik.values.secondEmail}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>
        <div className={`text-left text-black py-2 w-full ${isUpdate ? '' : 'hidden'}`}>
          <label className="text-base text-black font-bold">
            Status<span className="text-[#FFA17A]">*</span>
          </label>
          <div className="flex flex-col gap-1">
            <div className="flex gap-2 justify-start w-full my-2">
              {statusOptions?.map((status:any, index:any) => (
                <div key={index} className="form-control">
                  <label className="label cursor-pointer gap-2 items-center">
                    <input
                      type="radio"
                      name="status"
                      value={status?.value}
                      className={`w-4 h-4`}
                      onChange={() =>
                        formik.setFieldValue("status", status?.value)
                      }
                      checked={formik.values.status === status?.value}
                    />
                    <span className="py-1">{status?.label}</span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="text-left">
          <span className="text-[#FF0000]">{formik.errors.name}</span>
          <span className="text-[#FF0000]">{formik.errors.email}</span>
          <span className="text-[#FF0000]">{formik.errors.secondEmail}</span>
        </div>
        <div className={`${isUpdate ? 'hidden' : ''} italic text-black my-2 text-left`}>
          Password for user will be created automatically and sent via email
        </div>
      </div>
    </div>
  );
};

FormContent.defaultProps = {
  modalFormIsUpdate: false,
};

export default FormContent;
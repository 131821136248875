import { createAxiosInstance } from "./AxiosInstance";
import moment from 'moment';
import { CompanyInterface } from "../util/interfaces";

const CompanyRoute = "/company";

export const CompanyService = {
  getCompany,
  getCompanyById,
  getCompanyOptions,
  getTokenlessCompany,
  getTokenlessCompanyById,
  createCompany,
  updateCompany,
  deleteCompany
};

const formatCreateCompany = (company: CompanyInterface) => {
  return {
    companyName: company.companyName,
    cpName: company.cpName,
    cpEmail: company.cpEmail,
    hrAdminName: company.hrAdminName,
    hrAdminEmail1: company.hrAdminEmail1,
    hrAdminEmail2: company.hrAdminEmail2,
    tokenStartDate: company.tokenStartDate ? moment(new Date(company.tokenStartDate)).format("YYYY-MM-DD") : "",
    tokenEndDate: company.tokenEndDate ? moment(new Date(company.tokenEndDate)).format("YYYY-MM-DD") : "",
    tokenAmount: company?.tokenAmount,
  };
};

const formatUpdateCompany = (company: CompanyInterface) => {
  return {
    companyId: company.companyId,
    companyName: company.companyName,
    cpName: company.cpName,
    cpEmail: company.cpEmail,
    hrAdminId: company.hrAdminId,
  };
};

async function getCompany(
  page: number,
  pageSize: number,
  searchBy: string,
  searchText: string,
  sortKey: string,
  isAsc: boolean
) {
  const result = await createAxiosInstance()
    .get(CompanyRoute, {
      params: {
        page: page,
        size: pageSize,
        searchBy: searchBy,
        searchQuery: searchText,
        sort: isAsc ? `asc(${sortKey})` : `desc(${sortKey})`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function getCompanyById(id: string) {
  const result = createAxiosInstance()
  .get(CompanyRoute + `/Id/${id}`)
  .then((res) => {
    return res;
  })
  .catch((err) => {
    console.log(err);
    return err;
  });

  return result;
}

async function getCompanyOptions(searchText: string) {
  const result: any = await createAxiosInstance()
    .get(CompanyRoute, {
      params: {
        page: 1,
        size: 20,
        searchQuery: searchText,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result?.data?.data?.rows;
}

async function getTokenlessCompany() {
  const result = await createAxiosInstance()
    .get(CompanyRoute + `/tokenless/`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function getTokenlessCompanyById(id: any) {
  const result = await createAxiosInstance()
    .get(CompanyRoute + `/tokenless/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function createCompany(company: CompanyInterface) {
  const result = await createAxiosInstance()
    .post(CompanyRoute, formatCreateCompany(company))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function updateCompany(company: CompanyInterface) {
  const result = await createAxiosInstance()
    .put(CompanyRoute, formatUpdateCompany(company))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function deleteCompany(id: string) {
  const result = await createAxiosInstance()
    .delete(CompanyRoute, {
      data: {
        id: id,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

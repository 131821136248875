import { useState } from "react";
import { BrowserRouter as Router, Navigate, useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

import { Main } from "./components/Main";
import { Login } from "./features/auth/Login";
import { Session } from "./features/session/Session";
import { SessionType } from "./features/session-type/SessionType";
import { Counselor } from "./features/counselor/Counselor";

import { FaListUl } from "react-icons/fa";
import "./App.css";
import { ViewToken } from "./features/token/ViewToken";
import { ViewExpertise } from "./features/expertise/ViewExpertise";
import { ViewCompany } from "./features/company/ViewCompany";

import { loginUser, Roles } from "./features/auth/Auth.Slice";
import { ChangePassword } from "./features/change-password/ChangePassword";
import { UserAccess } from "./features/user-access/UserAccess";

import { AppDispatch, RootState } from "./app/store";
import { AuthService } from "./services/AuthService";
import { startLoading, stopLoading } from "./App.Slice";
import { Loading } from "./components/Loading";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { Dashboard } from "./features/dashboard-hr/Dashboard";
import { ViewCompanyDetail } from "./features/company/ViewCompanyDetail";
import { ForgotPassword } from "./features/auth/ForgotPassword";
import { ResetPassword } from "./features/auth/ResetPassword";
import { ViewReminderList } from "./features/reminder/ViewReminderList";
import { ViewReminderDetail } from "./features/reminder/ViewReminderDetail";

async function getAccessToken(dispatch: AppDispatch): Promise<void> {
  dispatch(startLoading());
  const result = await AuthService.RefreshUserToken();
  if (result?.data?.data?.accessToken) dispatch(loginUser(result?.data?.data?.accessToken));
  dispatch(stopLoading());
}

function RequireAuth({ children, roles = [] }: { children: JSX.Element, roles?: Roles[] }): JSX.Element {
  const location = useLocation();
  const authed = useAppSelector((state: RootState) => state.auth.accessToken);
  const role = useAppSelector((state: RootState) => state.auth.role);
  if (authed) {
    if (roles.includes(role) || roles.length === 0) return children;
    else return <Navigate to={"/"} replace />;
  }
  return authed ? children : <Navigate to={"/login"} state={{ from: location }} replace />;
}

function RequireNotAuth({ children }: { children: JSX.Element }): JSX.Element {
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const authed = useAppSelector((state: RootState) => state.auth.accessToken);
  return authed ? <Navigate to={from} /> : children;
}

function App() {
  const dispatch = useAppDispatch();
  getAccessToken(dispatch);

  const [sidebarIsOpen, setsidebar] = useState<Boolean>(true);
  const toggleSidebar = () => setsidebar(!sidebarIsOpen);
  const [user, setUser] = useState();
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");

  return (
    <Router>
      <div className="App wrapper bg-white min-h-screen">
        <Loading />
        <Routes>
          <Route
            element={
              <RequireAuth>
                <Main
                  toggleSidebar={toggleSidebar}
                  sidebarIsOpen={sidebarIsOpen}
                  title="Dashboard"
                  subtitle="Dashboard"
                  icon={<FaListUl size={25} />}
                  user={user}
                >
                  <Dashboard />
                </Main>
              </RequireAuth>
            }
            path="/"
          />
          <Route
            element={
              <RequireAuth roles={[Roles.ADMIN, Roles.MASTER_ADMIN]}>
                <Main
                  toggleSidebar={toggleSidebar}
                  sidebarIsOpen={sidebarIsOpen}
                  title="Company"
                  subtitle="Company > Master Data"
                  icon={<FaListUl size={25} />}
                  user={user}
                >
                  <ViewCompany />
                </Main>
              </RequireAuth>
            }
            path="/company"
          />
          <Route
            element={
              <RequireAuth roles={[Roles.ADMIN, Roles.MASTER_ADMIN]}>
                <Main
                  toggleSidebar={toggleSidebar}
                  sidebarIsOpen={sidebarIsOpen}
                  title={title}
                  subtitle={subtitle}
                  icon={<FaListUl size={25} />}
                  user={user}
                >
                  <ViewCompanyDetail setTitle={setTitle} setSubtitle={setSubtitle} />
                </Main>
              </RequireAuth>
            }
            path="/company/:companyId"
          />
          <Route
            element={
              <RequireAuth roles={[Roles.ADMIN, Roles.MASTER_ADMIN]}>
                <ViewToken {...{ toggleSidebar, sidebarIsOpen, user }} />
              </RequireAuth>
            }
            path="/token"
          />
          <Route
            element={
              <RequireAuth roles={[Roles.ADMIN, Roles.MASTER_ADMIN]}>
                <Session {...{ toggleSidebar, sidebarIsOpen, user }} />
              </RequireAuth>
            }
            path="/session"
          />
          <Route
            element={
              <RequireAuth roles={[Roles.ADMIN, Roles.MASTER_ADMIN]}>
                <Main
                  toggleSidebar={toggleSidebar}
                  sidebarIsOpen={sidebarIsOpen}
                  title="Session Type"
                  subtitle="Session Type Master Data"
                  icon={<FaListUl size={25} />}
                  user={user}
                >
                  <SessionType />
                </Main>
              </RequireAuth>
            }
            path="/session-type"
          />
          <Route
            element={
              <RequireAuth roles={[Roles.ADMIN, Roles.MASTER_ADMIN]}>
                <Counselor {...{ toggleSidebar, sidebarIsOpen, user }} />
              </RequireAuth>
            }
            path="/counselor"
          />
          <Route
            element={
              <RequireAuth roles={[Roles.ADMIN, Roles.MASTER_ADMIN]}>
                <ViewExpertise />
              </RequireAuth>
            }
            path="/expertise"
          />
          <Route
            element={
              <RequireAuth roles={[Roles.ADMIN, Roles.MASTER_ADMIN]}>
                <Main
                  toggleSidebar={toggleSidebar}
                  sidebarIsOpen={sidebarIsOpen}
                  title="Email Reminder"
                  subtitle="Email Reminder"
                  icon={<FaListUl size={25} />}
                  user={user}
                >
                  <div className="font-bold bg-gray-300 h-[80rem]">
                    content here
                  </div>
                </Main>
              </RequireAuth>
            }
            path="/email-reminder"
          />
          <Route
            element={
              <RequireAuth roles={[Roles.MASTER_ADMIN]}>
                <Main
                  toggleSidebar={toggleSidebar}
                  sidebarIsOpen={sidebarIsOpen}
                  title="User Access"
                  subtitle="User Access"
                  icon={<FaListUl size={25} />}
                  user={user}
                >
                  <UserAccess />
                </Main>
              </RequireAuth>
            }
            path="/user-access"
          />
          <Route
            element={
              <RequireAuth>
                <Main
                  toggleSidebar={toggleSidebar}
                  sidebarIsOpen={sidebarIsOpen}
                  title="Change Password"
                  subtitle="Change Password"
                  icon={<FaListUl size={25} />}
                  user={user}
                >
                  <ChangePassword />
                </Main>
              </RequireAuth>
            }
            path="/change-password"
          />
          <Route
            element={
              <RequireAuth roles={[Roles.ADMIN, Roles.MASTER_ADMIN]}>
                <ViewReminderList />
              </RequireAuth>
            }
            path="/reminder"
          />
          <Route
            element={
              <RequireAuth roles={[Roles.ADMIN, Roles.MASTER_ADMIN]}>
                <ViewReminderDetail />
              </RequireAuth>
            }
            path="/reminder/:id"
          />
          <Route
            element={
              <RequireNotAuth>
                <Login />
              </RequireNotAuth>
            }
            path="/login"
          />
          <Route
            element={
              <RequireNotAuth>
                <ForgotPassword />
              </RequireNotAuth>
            }
            path="/forgot-password"
          />
          <Route
            element={
              <RequireNotAuth>
                <ResetPassword />
              </RequireNotAuth>
            }
            path="/reset-password"
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

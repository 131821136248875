import { useFormik } from "formik";
import { ChangePasswordService } from "../../services/ChangePassword";
import ModalAlert from "../../components/ModalAlert";
import { useState } from "react";

export function ChangePassword() {
  const [modalAlertVisible, setModalAlertVisible] = useState(false);
  const [modalAlertText, setModalAlertText] = useState('');
  const [modalAlertIsFailed, setModalAlertIsFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { currentPassword: "", newPassword: "", confirmationNewPassword: "" },
    onSubmit: async (values) => {
      setErrorMessage('');
      const result = await ChangePasswordService.changePassword(values);
      if(result?.data?.code === "200"){
        setModalAlertText(result?.data?.data?.message);
        setModalAlertVisible(true);
      } else {
        if (result?.code === "ERR_NETWORK") {
          setModalAlertText("Can't connect to the server");
          setModalAlertVisible(true);
          setModalAlertIsFailed(true);
        } else {
          setErrorMessage(result?.response?.data?.errors.name);
        }
      }
      formik.resetForm();
    }
  });

  return (
    <div className="flex bg-white min-h-screen px-4 md:px-0 mt-12 md:mt-0">
      <form onSubmit={formik.handleSubmit} className="flex flex-col flex-1 gap-4">
        <div className="text-accent text-left font-bold text-xl">Change Account Password</div>
        <div className="flex flex-col gap-1 text-left">
          <div>
            <label className="text-base text-black font-bold">
              Current Password<span className="text-[#FFA17A]">*</span>
            </label>
          </div>
          <div className="md:w-[30%]">
            <input
              className={`text-black bg-white border rounded-lg p-4 w-full`}
              name="currentPassword"
              type="password"
              placeholder="Current Password"
              value={formik.values.currentPassword}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="flex flex-col gap-1 text-left">
          <div>
            <label className="text-base text-black font-bold">
              New Password<span className="text-[#FFA17A]">*</span>
            </label>
          </div>
          <div className="md:w-[30%]">
            <input
              className={`text-black bg-white border rounded-lg p-4 w-full`}
              name="newPassword"
              type="password"
              placeholder="New Password"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="flex flex-col gap-1 text-left">
          <div>
            <label className="text-base text-black font-bold">
              Confirmation New Password<span className="text-[#FFA17A]">*</span>
            </label>
          </div>
          <div className="md:w-[30%]">
            <input
              className={`text-black bg-white border rounded-lg p-4 w-full`}
              name="confirmationNewPassword"
              type="password"
              placeholder="Confirmation New Password"
              value={formik.values.confirmationNewPassword}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="text-left">
          <span className="text-[#FF0000]">{errorMessage}</span>
        </div>
        <div className="text-left">
          <button className="border-0 btn normal-case w-full md:w-[30%] py-4 text-white rounded-lg bg-accent hover:bg-accent hover:bg-opacity-90" type="submit">Change Password</button>
        </div>
      </form>
      <ModalAlert 
        isVisible={modalAlertVisible}
        alertText={modalAlertText}
        isFailedAlert={modalAlertIsFailed}
        handleModalVisibility={()=>{setModalAlertVisible(!modalAlertVisible); setModalAlertIsFailed(false)}}
      />
    </div>
  );
}

import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { FaListUl, FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { AiFillPlusSquare, AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import moment from 'moment';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import { Roles } from "../auth/Auth.Slice";
import { RootState } from "../../app/store";
import { useAppSelector } from "../../app/hooks";

import { Main } from '../../components/Main';
import TabsPanel from '../../components/TabsPanel';
import SearchBox from '../../components/SearchBox';
import Table from '../../components/Table';
import ModalAlert from '../../components/ModalAlert';
import ModalForm from '../../components/ModalForm';

import FormContent from './FormContent';

import { CompanyService } from "../../services/CompanyService";
import { TokenService } from "../../services/TokenService";
import { ApprovalService } from "../../services/ApprovalService";

import { TokenInterface } from "../../util/interfaces";

export function ViewToken(props: any) {
  const location = useLocation();
  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState(location?.state?.initialTabIndex ? 1 : 0);
  const [selectedIndex, setSelectedIndex] = useState(location?.state?.initialTabIndex ? 1 : 0);
  const userRole = useAppSelector((state: RootState) => state.auth.role);
  const [selectedData, setSelectedData] = useState<TokenInterface | null>(null);
  const [selectedApprovalId, setSelectedApprovalId] = useState<string | null>(null);
  const [crumbs, setCrumbs] = useState("Master Data");

  const [masterRefresh, setMasterRefresh] = useState(0);
  const [approvalRefresh, setApprovalRefresh] = useState(0);

  const [modalAlertIsConfirmation, setModalAlertIsConfirmation] = useState(false);
  const [modalAlertIsFailed, setModalAlertIsFailed] = useState(false);
  const [modalAlertIsDelete, setModalAlertIsDelete] = useState(false);
  const [modalAlertIsApprove, setModalAlertIsApprove] = useState(false);
  const [modalAlertVisible, setModalAlertVisible] = useState(false);
  const [modalAlertText, setModalAlertText] = useState("Token Succesfully Created");

  const [modalFormIsUpdate, setModalFormIsUpdate] = useState(false);
  const [modalFormVisible, setModalFormVisible] = useState(false);
  const [modalFormTitle, setModalFormTitle] = useState("New Contract");
  const [modalFormButtonText, setModalFormButtonText] = useState("Create New Contract");

  const [masterBody, setMasterBody] = useState<TokenInterface[]>([]);
  const [approvalBody, setApprovalBody] = useState<any[]>([]);
  const [companyList, setCompanyList] = useState<any[]>([]);

  const [masterSearchByValue, setMasterSearchByValue] = useState("");
  const [masterSearchText, setMasterSearchText] = useState("");
  const [masterSortKey, setMasterSortKey] = useState("");
  const [masterSortIsAsc, setMasterSortIsAsc] = useState(true);
  const [approvalSearchByValue, setApprovalSearchByValue] = useState("");
  const [approvalSearchText, setApprovalSearchText] = useState("");
  const [approvalSortKey, setApprovalSortKey] = useState("");
  const [approvalSortIsAsc, setApprovalSortIsAsc] = useState(true);

  const [masterResultsAmount, setMasterResultsAmount] = useState(10);
  const [masterResultsTotal, setMasterResultsTotal] = useState(10);
  const [masterPageNumber, setMasterPageNumber] = useState(1);
  const [masterPageNumberTotal, setMasterPageNumberTotal] = useState(1);
  const [approvalResultsAmount, setApprovalResultsAmount] = useState(10);
  const [approvalResultsTotal, setApprovalResultsTotal] = useState(10);
  const [approvalPageNumber, setApprovalPageNumber] = useState(1);
  const [approvalPageNumberTotal, setApprovalPageNumberTotal] = useState(2);
  const [approvalShowStatusValue, setApprovalShowStatusValue] = useState("");

  const masterSearchByOnChange = (e: any) => { setMasterSearchByValue(e.target.value); };
  const mastersearchTextOnChange = (e: any) => { setMasterSearchText(e.target.value); };
  const masterResultsAmountOnChange = (e: any) => { setMasterResultsAmount(e.target.value); };
  const masterChangePage = (e: any) => { setMasterPageNumber(e.selected+1); };
  const approvalSearchByOnChange = (e: any) => { setApprovalSearchByValue(e.target.value); };
  const approvalsearchTextOnChange = (e: any) => { setApprovalSearchText(e.target.value); };
  const approvalResultsAmountOnChange = (e: any) => { setApprovalResultsAmount(e.target.value); };
  const approvalChangePage = (e: any) => { setApprovalPageNumber(e.selected+1); };
  const approvalShowStatusOnChange = (e: any) => { setApprovalShowStatusValue(e.target.value); };

  const handleResponse = (result: any, isUpdate = false, isDelete = false) => {
    setModalAlertIsConfirmation(false);
    setModalAlertIsDelete(false);

    if (result?.data?.code === "200") {
      setModalAlertText(result?.data?.data?.message);
      setModalAlertIsFailed(false);
      setModalAlertVisible(true);
      if (isUpdate) {
        setModalFormIsUpdate(false);
      }
      setModalFormVisible(false);
      if (tabIndex === 0) {
        setMasterRefresh(masterRefresh + 1);
      } else {
        setApprovalRefresh(approvalRefresh + 1);
      }
      formik.resetForm();
    } else {
      if (result?.code === "ERR_NETWORK") {
        setModalAlertText("Can't connect to the server");
        setModalAlertIsFailed(true);
        setModalAlertVisible(true);
      } else if (!isDelete) {
        const errors = result?.response?.data?.errors;
        for (let key of Object.keys(errors)) {
          formik.setFieldError(key, errors[key]);
        }
      }
    }
    setSelectedApprovalId(null);
  };

  const handleDelete = async (id: string) => {
    if (id) {
      const result = await TokenService.deleteToken(id);
      handleResponse(result, false, true);
      if (userRole !== Roles.MASTER_ADMIN) {
        setApprovalRefresh(approvalRefresh + 1);
      }
    }
  };

  const handleApprove = async (id: string | null) => {
    if (id) {
      const result = await ApprovalService.approve(id);
      handleResponse(result, false, true);
      setMasterRefresh(masterRefresh + 1);
    }
  };

  const handleReject = async (id: string | null) => {
    if (id) {
      const result = await ApprovalService.reject(id);
      handleResponse(result, false, true);
      setMasterRefresh(masterRefresh + 1);
    }
  };

  const handleViewCompany = (data: any) => {
    navigate(`/company/${data.companyId}`,{ state:{ 
      title: "Token", 
      subtitle: "Token > Master Data > Detail",
      returnLink: "/token",
    }});
  };

  const handleApprovalCompany = (data: any) => {
    navigate(`/company/${data?.companyId}`,{ state:{ 
      title: "Token", 
      subtitle: "Token > Approval > Detail",
      returnLink: "/token",
    }});
  };

  const convertData = (rows: any[]) => {
    const res: TokenInterface[] = [];
    rows.map(({startDate, expiredDate,...item}) => {
      res.push({
        startDate: startDate ? new Date(startDate) : null,
        expiredDate: expiredDate? new Date(expiredDate) : null,
        ...item
      });
    })
    return res;
  };

  const formik = useFormik<TokenInterface>({
    enableReinitialize: true,
    initialValues: {
      tokenId: selectedData?.tokenId || "",
      startDate: selectedData?.startDate || null,
      expiredDate: selectedData?.expiredDate || null,
      companyId: selectedData?.companyId || "",
      companyName: selectedData?.companyName || "",
      status: selectedData?.status || "",
      totalToken: selectedData?.totalToken || 0,
      usedToken: selectedData?.usedToken || 0,
      availToken: selectedData?.availToken || 0,
      daysLastUsed: selectedData?.daysLastUsed || 0,
      validityPeriod: selectedData?.validityPeriod || 0,
      isWaitingApproval: selectedData?.isWaitingApproval || false,
    },
    onSubmit: async (values) => {
      if (modalFormIsUpdate) {
        const result = await TokenService.updateToken(values);
        handleResponse(result, true, false);
      } else {
        const result = await TokenService.createToken(values);
        handleResponse(result, false, false);
      }
    },
  });

  useEffect(() => {
    setMasterRefresh(masterRefresh + 1);
  }, [masterSearchByValue, masterSearchText, masterResultsAmount, masterSortKey, masterSortIsAsc]);

  useEffect(() => {
    setApprovalRefresh(approvalRefresh + 1);
  }, [approvalSearchByValue, approvalSearchText, approvalResultsAmount, approvalSortKey, approvalSortIsAsc, approvalShowStatusValue]);

  useEffect(() => {
    const getToken = async () => {
      const response = await TokenService.getToken(
        masterPageNumber,
        masterResultsAmount,
        masterSearchByValue,
        masterSearchText,
        masterSortKey,
        masterSortIsAsc,
      );
      setMasterPageNumberTotal(response?.data?.page?.totalPage);
      setMasterResultsTotal(response?.data?.page?.totalCount);
      setMasterBody( response?.data?.data?.rows ? convertData(response?.data?.data?.rows) : []);
    };
    if (!masterRefresh) {
      getToken();
    }
    if (masterRefresh) {
      setMasterRefresh(0);
      setMasterPageNumber(1);
    }
  }, [masterPageNumber, masterResultsAmount, masterRefresh]);

  useEffect(() => {
    const getTokenApproval = async () => {
      const response = await TokenService.getTokenApproval(
        approvalPageNumber,
        approvalResultsAmount,
        approvalSearchByValue,
        approvalSearchText,
        approvalSortKey,
        approvalSortIsAsc,
        approvalShowStatusValue,
      );
      setApprovalPageNumberTotal(response?.data?.page?.totalPage);
      setApprovalResultsTotal(response?.data?.page?.totalCount);
      setApprovalBody(response?.data?.data?.rows || []);
    };
    if (!approvalRefresh) {
      getTokenApproval();
    }
    if (approvalRefresh) {
      setApprovalRefresh(0);
      setApprovalPageNumber(1);
    }
  }, [approvalPageNumber, approvalResultsAmount, approvalRefresh]);

  useEffect(() => {
    const getCompanyList = async () => {
      const response = ( selectedData?.tokenId ? await CompanyService.getTokenlessCompanyById(selectedData?.tokenId) : await CompanyService.getTokenlessCompany());
      setCompanyList(response?.data?.data.map((item: any) => { return  {value: item.id, name: item.name }}));
    };
    if (modalFormVisible) {
      getCompanyList();
    } else if (!modalFormVisible) {
      setCompanyList([]);
    }
  }, [masterRefresh, modalFormVisible]);

  useEffect(() => {
    if (location.state?.companyId) {
      formik.resetForm();
      setSelectedData({
        tokenId: "",
        startDate: null,
        expiredDate: null,
        companyId: location.state?.companyId,
        companyName: "",
        status: "",
        totalToken: 0,
        usedToken: 0,
        availToken: 0,
        daysLastUsed: 0,
        validityPeriod: 0,
        isWaitingApproval: false,
      });
      setModalFormTitle("New Contract");
      setModalFormButtonText("Create New Contract");
      setModalFormIsUpdate(false);
      setModalFormVisible(true);
    }
  },[location]);

  useEffect(() => {
    setCrumbs(panelsData[selectedIndex].tabTitle);
    setTabIndex(selectedIndex);
  },[selectedIndex]);

  useEffect(() => {
    if (location?.state?.initialTabIndex) {
      setSelectedIndex(1);
    }
  },[location?.state?.initialTabIndex]);

  const masterSearchByOptions = [
    { value:"", name:"All" },
    { value:"startDate", name:"Start Date" },
    { value:"expiredDate", name:"End Date" },
    { value:"companyName", name:"Company Name" },
    { value:"status", name:"Status" },
    { value:"totalToken", name:"Total Token" },
    { value:"usedToken", name:"Used Token" },
    { value:"availToken", name:"Total Token Left" },
    { value:"daysLastUsed", name:"Days Since Last Token Used" },
    { value:"validityPeriod", name:"Validity Period" }];

  const approvalSearchByOptions = [
    { value:"", name:"All" },
    { value:"tokenFor", name:"Token For" },
    { value:"requestDate", name:"Request Date" },
    { value:"requestBy", name:"Request By" },
    { value:"process", name:"Request Process" },
    { value:"status", name:"Status" }];

  const approvalShowStatusOptions = [
    { value:"", name:"All" },
    { value:"approved", name:"Approved" },
    { value:"rejected", name:"Rejected" },
    { value:"waiting", name:"Waiting" }];

  const masterHeader = [
    { key:"startDate", name:"Start Date" },
    { key:"expiredDate", name:"End Date" },
    { key:"companyName", name:"Company Name" },
    { key:"status", name:"Status" },
    { key:"totalToken", name:"Total Token" },
    { key:"usedToken", name:"Used Token" },
    { key:"availToken", name:"Total Token Left" },
    { key:"daysLastUsed", name:<span>Days Since Last<br/>Token Used</span>, className:"my-[-1rem]" },
    { key:"validityPeriod", name:"Validity Period" },
    { key:"action", name:"Action", noSort:true }];

  const approvalHeader = [
    { key:"tokenFor", name:"Token For", noSort:false },
    { key:"requestDate", name:"Request Date" }];

  if (userRole === Roles.MASTER_ADMIN) {
    approvalHeader.push(
      { key:"requestBy", name:"Request By" },
      { key:"requestFor", name:"Request Process" }, 
      { key:"status", name:"Status" },
      { key:"action", name:"Action", noSort:true });
  } else {
    approvalHeader.push(
      { key:"requestFor", name:"Request Process" }, 
      { key:"status", name:"Status" });
  }

  const TOOLTIP_ID_UPDATE = "token-update-";
  const TOOLTIP_ID_DELETE = "token-delete-";

  const masterBodyFormatting = (key: string, data: any): JSX.Element => {
    let classNameStatus = "rounded-lg text-white text-center py-1 px-2 my-[-1rem] w-24";
    let classNameAction = "flex flex-auto items-center justify-center rounded-lg text-white";

    switch(key) {
      case "startDate":
        return <>{moment(data[key]).format("DD-MM-YYYY")}</>
      case "expiredDate":
        return <>{moment(data[key]).format("DD-MM-YYYY")}</>
      case "companyName":
        return (<a className="cursor-pointer text-[#289B95] underline" onClick={() => handleViewCompany(data)}>{data[key]}</a>);
      case "status":
        switch(data[key].toLowerCase()) {
          case "active": return (<div className={`${classNameStatus} bg-[#28A745]`}>Active</div>);
          case "expiring": return (<div className={`${classNameStatus} bg-[#FFC107]`}>Expiring</div>);
          case "ended": return (<div className={`${classNameStatus} bg-[#DC3545]`}>Ended</div>);
          default: return (<div className={`${classNameStatus} bg-slate-500`}>Unknown</div>);
        }
      case "action":
        return (<div className="flex gap-2">
          <span
            data-tooltip-html="Token is on approval<br/>cant be edited"
            data-tip-disable={false}
            id={TOOLTIP_ID_UPDATE + data?.id}
          >
            <button className={`${classNameAction} ${data?.isWaitingApproval ? "bg-[#CBCBCB]" : "bg-orange-500"}`}
              disabled={data?.isWaitingApproval}
              onClick={() => {
                setSelectedData(data);
                setModalFormTitle("Update Contract");
                setModalFormButtonText("Update Contract");
                setModalFormIsUpdate(true);
                setModalFormVisible(true);
              }}>
              <FaPencilAlt className="h-4 w-4 m-3"/>
            </button>
              {data?.isWaitingApproval ? (
                <ReactTooltip anchorId={TOOLTIP_ID_UPDATE + data?.id} />
              ) : (
                <></>
              )}
          </span>
          <span
              data-tooltip-html="Token is on approval<br/>cant be deleted"
              data-tip-disable={false}
              id={TOOLTIP_ID_DELETE + data?.id}
            >
            <button className={`${classNameAction} ${data?.isWaitingApproval ? "bg-[#CBCBCB]" : "bg-red-700"}`}
              disabled={data?.isWaitingApproval}
              onClick={() => {
                setSelectedData(data);
                setModalAlertText(`Are you sure you want to delete this token ?`);
                setModalAlertIsConfirmation(true);
                setModalAlertIsDelete(true);
                setModalAlertVisible(true);
              }} >
              <FaTrashAlt className="h-4 w-4 m-3"/>
            </button>
            {data?.isWaitingApproval ? (
              <ReactTooltip anchorId={TOOLTIP_ID_DELETE + data?.id} />
            ) : (
              <></>
            )}
          </span>
        </div>);
      case "validityPeriod":
        if (data[key]) {
          return (<>{`${data[key]} Days`}</>)
        } else {
          return (<>{`0 Days`}</>)
        }
      default:
        if (data[key]) {
          return (<>{data[key]}</>)
        } else {
          return (<>{0}</>)
        }
    }
  };
  
  const approvalBodyFormatting = (key: string, data: any): JSX.Element => {
    let classNameStatus = "rounded-lg text-white text-center py-1 px-2 my-[-1rem] w-24";
    let classNameAction = "flex w-12 items-center justify-center rounded-lg text-white";

    switch(key) {
      case "tokenFor":
        return (<a className="text-[#289B95] underline" onClick={() => handleApprovalCompany(data)}>{data?.companyName}</a>);
      case "requestDate":
        return <>{moment(data[key]).format("DD-MMM-YYYY")}</>
      case "status":
        switch(data[key].toLowerCase()) {
          case "approved": return (<div className={`${classNameStatus} bg-[#28A745]`}>Approved</div>);
          case "waiting": return (<div className={`${classNameStatus} bg-[#FFC107]`}>Waiting</div>);
          case "rejected": return (<div className={`${classNameStatus} bg-[#DC3545]`}>Rejected</div>);
          default: return (<div className={`${classNameStatus} bg-slate-500`}>Unknown</div>);
        }
      case "action":
        let disabled = data?.status !== "WAITING";
        return (<div className="flex gap-2">
          <button className={`${classNameAction} ${ disabled ? "bg-[#CBCBCB]" : "bg-[#28A745]" }`}
            disabled={disabled}
            onClick={() => { 
              setModalAlertText(`Are you sure want to approve this ?`);
              setSelectedApprovalId(data?.id);
              setModalAlertIsApprove(true);
              setModalAlertIsConfirmation(true);
              setModalAlertIsFailed(false);
              setModalAlertVisible(true);
            }}>
            <AiOutlineCheck className="h-4 w-4 m-3"/>
          </button>
          <button className={`${classNameAction} ${ disabled ? "bg-[#CBCBCB]" : "bg-[#DC3545]" }`}
            disabled={disabled}
            onClick={() => {
              setModalAlertText(`Are you sure you want to reject this ?`);
              setSelectedApprovalId(data?.id);
              setModalAlertIsApprove(false);
              setModalAlertIsConfirmation(true);
              setModalAlertIsFailed(false);
              setModalAlertVisible(true);
            }} >
            <AiOutlineClose className="h-4 w-4 m-3"/>
          </button>
        </div>);
      default:
        return (<>{data[key]}</>)
    }
  };

  const panelsData = [
    { tabTitle: "Master Data", tabContent: 
      <>
        <div className='flex flex-col md:flex-row gap-2 mb-5 px-4 md:px-0'>
          <button className="btn flex w-full gap-[0.625rem] border-none bg-accent normal-case text-white
            hover:bg-accent hover:bg-opacity-90 md:min-h-[3.375rem] md:w-auto md:py-4 md:px-8"
            onClick={() => { 
              formik.resetForm();
              setSelectedData(null);
              setModalFormTitle("New Contract");
              setModalFormButtonText("Create New Contract");
              setModalFormIsUpdate(false);
              setModalFormVisible(true);
            }}
          >
            <AiFillPlusSquare className="h-4 w-4 mr-2 ml-5"/>New Contract
          </button>
          <SearchBox className="flex-auto"
            searchByValue={masterSearchByValue}
            searchByOnChange={masterSearchByOnChange}
            searchByOptions={masterSearchByOptions}
            searchTextOnChange={mastersearchTextOnChange}
            searchTextValue={masterSearchText}
          />
        </div>
        <Table 
          tableTitle="Token List"
          headerData={masterHeader}
          bodyData={masterBody}
          resultsAmount={masterResultsAmount}
          resultsTotal={masterResultsTotal}
          resultsAmountOnChange={masterResultsAmountOnChange}
          pageNumber={masterPageNumber}
          pageNumberTotal={masterPageNumberTotal}
          changePage={masterChangePage}
          bodyFormatting={masterBodyFormatting}
          sortKey={masterSortKey}
          setSortKey={setMasterSortKey}
          sortIsAsc={masterSortIsAsc}
          setSortIsAsc={setMasterSortIsAsc}
        />
      </> 
    },
    { tabTitle: "Approval", tabContent: 
      <>
        <div className='flex flex-col md:flex-row gap-2 mb-5 px-4 md:px-0'>
          <SearchBox className="flex-auto"
            searchByValue={approvalSearchByValue}
            searchByOnChange={approvalSearchByOnChange}
            searchByOptions={approvalSearchByOptions}
            searchTextOnChange={approvalsearchTextOnChange}
            searchTextValue={approvalSearchText}
          />
          <label className="flex-auto input-group text-base w-full min-h-[3.375rem] md:max-w-[22.7rem]">
              <span className="bg-white border-l border-y flex-initial pr-1 text-black">{"Show Status "}</span>
              <select className="bg-white border-y flex-1 text-black"  onChange={approvalShowStatusOnChange} value={approvalShowStatusValue} >
                  {approvalShowStatusOptions.map((item: any, index: number) => { return(<option key={index} value={item?.value}>{item?.name}</option>) })}
              </select>
          </label>
        </div>
        <Table 
          tableTitle="Approval List"
          headerData={approvalHeader}
          bodyData={approvalBody}
          resultsAmount={approvalResultsAmount}
          resultsTotal={approvalResultsTotal}
          resultsAmountOnChange={approvalResultsAmountOnChange}
          pageNumber={approvalPageNumber}
          pageNumberTotal={approvalPageNumberTotal}
          changePage={approvalChangePage}
          bodyFormatting={approvalBodyFormatting}
          sortKey={approvalSortKey}
          setSortKey={setApprovalSortKey}
          sortIsAsc={approvalSortIsAsc}
          setSortIsAsc={setApprovalSortIsAsc}
        />
      </> 
    },
  ];

  return (
    <>
      <Main
        toggleSidebar={props.toggleSidebar}
        sidebarIsOpen={props.sidebarIsOpen}
        title="Token"
        subtitle={`Token > ${crumbs}`}
        icon={<FaListUl size={25} />}
        user={props.user}
      >
        <div className="w-full bg-white pt-9 md:pt-0">
          <TabsPanel panelsData={panelsData}
            selectedIndex={selectedIndex} 
            setSelectedIndex={setSelectedIndex}/>
          <ModalAlert isVisible={modalAlertVisible} 
            alertText={modalAlertText}
            isConfirmationAlert={modalAlertIsConfirmation}
            isFailedAlert={modalAlertIsFailed}
            handleModalVisibility={() => {
              setSelectedApprovalId(null);
              setModalAlertVisible(false);
              setModalAlertIsConfirmation(false);
              setModalAlertIsDelete(false);
              setModalAlertIsFailed(false);
            }}
            handleConfirm={() =>
              modalAlertIsDelete ? handleDelete(selectedData?.tokenId || "") : (
                modalAlertIsApprove ? handleApprove(selectedApprovalId) : handleReject(selectedApprovalId)
              )
            }
          />
          <ModalForm isVisible={modalFormVisible} 
            handleModalVisibility={() => setModalFormVisible(!modalFormVisible)} 
            modalTitle={modalFormTitle}
            buttonText={modalFormButtonText}
            formContent={<FormContent 
              formik={formik}
              companyList={companyList}
              handleCreateCompany={() => console.log("lul")}
              modalFormIsUpdate={modalFormIsUpdate}
            />}
            handleConfirm={formik.handleSubmit}
          />
        </div>
      </Main>
    </>
  );
}
import { useFormik } from "formik";
import { useState, HTMLInputTypeAttribute } from "react";
import { AuthService } from "../../services/AuthService";

import LeftDecoration from "../../images/left-decoration.png";
import RightDecoration from "../../images/right-decoration.png";
import DexwellnessLogo from "../../images/dexwellness.png";
import { loginUser } from "./Auth.Slice";
import { useAppDispatch } from "../../app/hooks";
import { handleResponse } from "../../services/HandleResponse";
import { Link } from "react-router-dom";

export function Login() {
  const dispatch = useAppDispatch();
  const [formErrorMessage, setFormErrorMessage] = useState("");
  document.title = "Dexwellness - Login";

  const leftDecoration = (
    <img
      src={LeftDecoration}
      alt="login background"
      className="h-2/4 absolute bottom-0 left-0 right-0 ml-[10rem] mr-auto my-0 hidden lg:block"
    />
  );

  const rightDecoration = (
    <img
      src={RightDecoration}
      alt="login background"
      className="h-2/4 absolute top-0 left-0 right-0 ml-auto mr-[10rem] my-0 hidden lg:block"
    />
  );

  const handleLogin = async (email: string, password: string) => {
    const result = await AuthService.LoginUser(email, password);
    handleResponse({
      result: result,
      handleSuccess: (result: any) => dispatch(loginUser(result?.data?.data?.accessToken)),
      handleFailed: (result: any) => setFormErrorMessage(result?.response?.data?.errors?.message),
    })
  };

  const loginFormik = useFormik({
    enableReinitialize: true,
    initialValues: { email: "", password: "" },
    onSubmit: async (values) => {
      await handleLogin(values.email, values.password);
      loginFormik.resetForm();
    },
  });

  const logoDexWellness = (
    <label className="label flex justify-center">
      <img src={DexwellnessLogo} alt="dexwellness logo" className="h-[12rem]" />
    </label>
  );

  const formField = (
    type: HTMLInputTypeAttribute,
    label: string,
    name: string,
    placeholder: string,
    value: string | number,
    onChange: (e: React.ChangeEvent<any>) => void
  ) => (
    <>
      <label className="label">
        <span className="label-text text-lg text-black font-bold">{label}</span>
      </label>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(e) => { onChange(e); setFormErrorMessage(""); }}
        className={`input border-black !input-bordered text-lg text-black font-normal bg-white p-4
          w-full h-[3.25rem] ${formErrorMessage ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""}`}
      />
    </>
  );

  const emailField = formField(
    "text", "Email", "email", "Email", loginFormik.values.email, loginFormik.handleChange
  );

  const passwordField = formField(
    "password", "Password", "password", "Password", loginFormik.values.password, loginFormik.handleChange
  );

  const errorMessage = (
    <label
      className={`label text-lg text-[#DC3545] font-normal p-0 m-0 mt-3
        ${formErrorMessage ? "" : "hidden"}`}
    >
      {formErrorMessage}
    </label>
  );

  const forgotPasswordButton = (
    <label className="label p-0 m-0 mt-3">
      <Link to={"/forgot-password"} className="underline hover:!text-black text-black font-normal">
        Lupa Password ?
      </Link>
    </label>
  );

  const loginButton = (
    <button
      type="submit"
      className="btn bg-[#289B95] border-none text-[#F3EADA] w-full h-[3.25rem] mt-3"
    >
      Login
    </button>
  );

  const loginForm = (
    <form
      className="form-control w-[18.5rem] text-justify py-8"
      onSubmit={(e) => {
        e.preventDefault();
        loginFormik.handleSubmit(e);
      }}
    >
      {logoDexWellness}
      {emailField}
      {passwordField}
      {errorMessage}
      {forgotPasswordButton}
      {loginButton}
    </form>
  );

  return (
    <div className="flex justify-center items-center min-h-screen md:h-screen bg-[#F3EADA]">
      {leftDecoration}
      {rightDecoration}
      {loginForm}
    </div>
  );
}

import { useState, useEffect } from "react";

import { FaCheck, FaTimes } from "react-icons/fa";
import { ImSpinner2 } from "react-icons/im";

import SearchBox from "../../components/SearchBox";
import Table from "../../components/Table";
import ModalAlert from "../../components/ModalAlert";
import { SelectionBox, SelectionBoxAsync } from "../../components/SelectionBox";
import { DatePickerRange } from "../../components/DatePicker";

import { Roles } from "../auth/Auth.Slice";
import { RootState } from "../../app/store";
import { useAppSelector } from "../../app/hooks";

import { SessionTypeService } from "../../services/SessionTypeService";
import { ExpertiseService } from "../../services/ExpertiseService";
import { CounselorService } from "../../services/CounselorService";
import { ApprovalService } from "../../services/ApprovalService";
import { changeCase } from "../../util/formatter/formatter";

export function ApprovalCounselor(props: any) {
  const userRole = useAppSelector((state: RootState) => state.auth.role);
  const [refresh, setRefresh] = useState(false);

  const [selectedApprovalId, setSelectedApprovalId] = useState<string>("");
  const [modalAlertIsConfirmation, setModalAlertIsConfirmation] =
    useState<boolean>(false);
  const [modalAlertIsFailed, setModalAlertIsFailed] = useState<boolean>(false);
  const [modalAlertIsApprove, setModalAlertIsApprove] =
    useState<boolean>(false);
  const [modalAlertVisible, setModalAlertVisible] = useState<boolean>(false);
  const [modalAlertText, setModalAlertText] = useState<string>("");

  const masterSearchByOptions = [
    { value: "all", name: "All" },
    { value: "requestDate", name: "Request Date" },
    { value: "counselorName", name: "Counselor Name" },
    { value: "gender", name: "Gender" },
    { value: "tokenPerHour", name: "Token per Hour" },
    { value: "sessionTypeName", name: "Session Type" },
    { value: "expertiseName", name: "Expertise" },
    { value: "requestBy", name: "Request By" },
    { value: "requestFor", name: "Request For" },
    { value: "status", name: "Status" },
  ];

  let masterHeader: Array<any> = [
    {
      key: "requestDate",
      name: "Request Date",
    },
    {
      key: "counselor",
      name: "Counselor Name",
    },
    {
      key: "gender",
      name: "Gender",
    },
    {
      key: "tokenPerHour",
      name: "Token per Hour",
    },
    {
      key: "sessionType",
      name: "Session Type",
    },
    {
      key: "expertise",
      name: "Expertise",
    },
    {
      key: "requestBy",
      name: "Request By",
    },
    {
      key: "requestFor",
      name: "Request For",
    },
    {
      key: "status",
      name: "Status",
    },
  ];

  if (userRole === Roles.MASTER_ADMIN) {
    masterHeader.push({
      key: "action",
      name: "Action",
      noSort: true,
    });
  }

  const [masterBody, setMasterBody] = useState([]);
  const masterBodyFormatting = (key: string, data: any): JSX.Element => {
    let classNameAction = `btn rounded-lg border-none flex w-12 items-center justify-center rounded-lg text-white`;
    switch (key) {
      case "action":
        return (
          <div className="flex gap-2">
            <button
              className={`${classNameAction} bg-[#28A745] hover:bg-[#28A745]`}
              onClick={() => {
                setModalAlertText(
                  `Are you sure you want to approve this approval?`
                );
                setModalAlertIsConfirmation(true);
                setModalAlertVisible(true);
                setSelectedApprovalId(data?.id);
                setModalAlertIsApprove(true);
              }}
              disabled={data?.status !== "WAITING"}
            >
              <FaCheck className="h-4 w-4" fill="#FFFFFF" />
            </button>
            <button
              className={`${classNameAction} bg-[#DC3545] hover:bg-[#DC3545]`}
              onClick={() => {
                setModalAlertText(
                  `Are you sure you want to reject this approval?`
                );
                setModalAlertIsConfirmation(true);
                setModalAlertVisible(true);
                setSelectedApprovalId(data?.id);
                setModalAlertIsApprove(false);
              }}
              disabled={data?.status !== "WAITING"}
            >
              <FaTimes className="h-4 w-4" color="#FFFFFF" />
            </button>
          </div>
        );
      case "expertise":
        return (
          <div>
            {data[key].map((expertise: any, idx: number) => (
              <>
                <span key={idx}>{expertise?.name}</span>
                {idx !== data[key]?.length - 1 ? (
                  <>
                    ,<br />
                  </>
                ) : (
                  <></>
                )}
              </>
            ))}
          </div>
        );
      case "counselor":
        return <>{data[key]?.name}</>;
      case "sessionType":
        return <>{data[key]?.name}</>;
      case "status":
        return (
          <div
            className={`${
              data?.status === "APPROVED"
                ? "bg-[#28A745]"
                : data?.status === "WAITING"
                ? "bg-[#FFC107]"
                : "bg-[#DC3545]"
            } rounded-lg py-2 px-3 text-center font-bold text-white`}
          >
            {changeCase(data[key])}
          </div>
        );
      case "gender":
        return <>{changeCase(data[key])}</>;
      default:
        return <>{data[key]}</>;
    }
  };

  const statusOptions = [
    { label: "Approved", value: "APPROVED" },
    { label: "Rejected", value: "REJECTED" },
    { label: "Waiting", value: "WAITING" },
  ];
  const genderOptions = [
    { label: "Male", value: "MALE" },
    { label: "Female", value: "FEMALE" },
  ];
  const [filterStatus, setFilterStatus] = useState(null);
  const [filterGender, setFilterGender] = useState(null);
  const [filterSessionType, setFilterSessionType] = useState(null);
  const [filterExpertise, setFilterExpertise] = useState(null);
  const [filterDateStart, setFilterDateStart] = useState<Date | null>(null);
  const [filterDateEnd, setFilterDateEnd] = useState<Date | null>(null);

  const [masterSearchByValue, setMasterSearchByValue] = useState("all");
  const [masterSearchText, setMasterSearchText] = useState("");
  const [masterResultsAmount, setMasterResultsAmount] = useState(10);
  const [masterResultsTotal, setMasterResultsTotal] = useState(10);
  const [masterPageNumber, setMasterPageNumber] = useState(1);
  const [masterPageNumberTotal, setMasterPageNumberTotal] = useState(2);
  const [masterSortKey, setMasterSortKey] = useState("");
  const [masterSortIsAsc, setMasterSortIsAsc] = useState(true);

  const masterSearchByOnChange = (e: any) => {
    setMasterSearchByValue(e.target.value);
  };
  const mastersearchTextOnChange = (e: any) => {
    setMasterSearchText(e.target.value);
  };
  const masterResultsAmountOnChange = (e: any) => {
    setMasterResultsAmount(e.target.value);
  };
  const masterChangePage = (e: any) => {
    setMasterPageNumber(e.selected + 1);
  };

  const getCounselorApprovals = async (page: number) => {
    const response = await CounselorService.getCounselorApprovals(
      page,
      masterResultsAmount,
      masterSearchByValue,
      masterSearchText,
      masterSortKey,
      masterSortIsAsc,
      filterStatus,
      filterGender,
      filterSessionType,
      filterExpertise,
      filterDateStart,
      filterDateEnd
    );
    setMasterPageNumberTotal(response?.data?.page?.totalPage);
    setMasterResultsTotal(response?.data?.page?.totalCount);
    setMasterBody(response?.data?.data);
  };

  const handleApprove = async (id: string) => {
    const result = await ApprovalService.approve(id);
    handleResponse(result);
  };

  const handleReject = async (id: string) => {
    const result = await ApprovalService.reject(id);
    handleResponse(result);
  };

  const handleResponse = async (result: any) => {
    setModalAlertIsConfirmation(false);
    if (result?.data?.code === "200") {
      await getCounselorApprovals(masterPageNumber);
      setModalAlertText(result?.data?.data?.message);
      setModalAlertVisible(true);
      setModalAlertIsFailed(false);
    } else {
      setModalAlertText(result?.response?.data?.data?.message);
      setModalAlertVisible(true);
      setModalAlertIsFailed(true);
    }
  };

  useEffect(() => {
    getCounselorApprovals(masterPageNumber);
  }, [masterPageNumber]);

  useEffect(() => {
    if (masterPageNumber === 1) {
      getCounselorApprovals(1);
    }
    setMasterPageNumber(1);
    if (refresh) {
      setRefresh(false);
    }
  }, [
    masterResultsAmount,
    masterSearchByValue,
    masterSearchText,
    masterSortKey,
    masterSortIsAsc,
    filterStatus,
    filterGender,
    filterSessionType,
    filterExpertise,
    refresh,
  ]);

  const filterComponent = (
    <div className="flex w-full flex-col gap-2 md:flex-row md:items-end">
      <div className="flex w-full flex-col md:w-[18rem]">
        <DatePickerRange
          label="Request Date Filter"
          required={false}
          from={filterDateStart}
          to={filterDateEnd}
          onChangeFrom={(date: Date) => {
            setFilterDateStart(date);
            if (filterDateEnd && date > filterDateEnd) {
              setFilterDateEnd(date);
            }
          }}
          onChangeTo={(date: Date) => setFilterDateEnd(date)}
          error={null}
        />
      </div>
      <div
        className="btn border-none bg-accent normal-case text-white hover:bg-accent"
        onClick={() => {
          if (filterDateStart && filterDateEnd) {
            setRefresh(true);
          }
        }}
      >
        Apply Filter
      </div>
      <div
        className="btn border-accent bg-white normal-case text-accent hover:border-accent hover:bg-white"
        onClick={() => {
          if (filterDateStart || filterDateEnd) {
            setFilterDateStart(null);
            setFilterDateEnd(null);
            if (filterDateStart && filterDateEnd) {
              setRefresh(true);
            }
          }
        }}
      >
        Clear Filter
      </div>
    </div>
  );

  const mainContent = (
    <div className="flex w-full flex-col gap-7 px-4 md:px-0">
      <div className="flex flex-col items-center gap-2 md:flex-row">
        <SearchBox
          className="w-full flex-auto md:w-[32rem]"
          searchByValue={masterSearchByValue}
          searchByOnChange={masterSearchByOnChange}
          searchByOptions={masterSearchByOptions}
          searchTextOnChange={mastersearchTextOnChange}
          searchTextValue={masterSearchText}
        />
        <SelectionBox
          className="w-full md:w-[13.5rem]"
          placeholder="Show Status All"
          value={filterStatus}
          options={statusOptions}
          handleChange={(obj: any) => setFilterStatus(obj)}
          styleTemplate="page"
        />
        <SelectionBox
          className="w-full md:w-[13.5rem]"
          placeholder="Show Gender All"
          value={filterGender}
          options={genderOptions}
          handleChange={(obj: any) => setFilterGender(obj)}
          styleTemplate="page"
        />
        <SelectionBoxAsync
          className="w-full md:w-[13.5rem]"
          placeholder="Show Type All"
          value={filterSessionType}
          getOptionLabel={(e: any) => e?.name}
          getOptionValue={(e: any) => e?.id}
          handleChange={(value: any) => {
            setFilterSessionType(value);
          }}
          loadOptions={SessionTypeService.getSessionTypesOptions}
          styleTemplate="page"
        />
        <SelectionBoxAsync
          className="w-full md:w-[15rem]"
          placeholder="Show Expertise All"
          value={filterExpertise}
          getOptionLabel={(e: any) => e?.name}
          getOptionValue={(e: any) => e?.id}
          handleChange={(value: any) => {
            setFilterExpertise(value);
          }}
          loadOptions={ExpertiseService.getExpertiseOptions}
          styleTemplate="page"
        />
      </div>
      {filterComponent}
      <Table
        tableTitle="Approval List"
        headerData={masterHeader}
        bodyData={masterBody}
        resultsAmount={masterResultsAmount}
        resultsTotal={masterResultsTotal}
        resultsAmountOnChange={masterResultsAmountOnChange}
        pageNumber={masterPageNumber}
        pageNumberTotal={masterPageNumberTotal}
        changePage={masterChangePage}
        bodyFormatting={masterBodyFormatting}
        sortKey={masterSortKey}
        setSortKey={setMasterSortKey}
        sortIsAsc={masterSortIsAsc}
        setSortIsAsc={setMasterSortIsAsc}
      />
      <ModalAlert
        isVisible={modalAlertVisible}
        alertText={modalAlertText}
        isConfirmationAlert={modalAlertIsConfirmation}
        isFailedAlert={modalAlertIsFailed}
        handleModalVisibility={() => setModalAlertVisible(!modalAlertVisible)}
        handleConfirm={() =>
          modalAlertIsApprove
            ? handleApprove(selectedApprovalId)
            : handleReject(selectedApprovalId)
        }
      />
    </div>
  );

  return mainContent;
}

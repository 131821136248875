import { createAxiosInstance } from "./AxiosInstance";

const ChangePasswordRoute = "/change-password";

type ChangePassword = {
    currentPassword: string;
    newPassword: string;
    confirmationNewPassword: string;
};

export const ChangePasswordService = {
    changePassword
};

async function changePassword(changePassword: ChangePassword) {
    const result = await createAxiosInstance()
      .put(
        ChangePasswordRoute,
        changePassword
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  
    return result;
}
import { createAxiosInstance } from "./AxiosInstance";

const NotificationRoute = "/notification";

export const NotificationService = {
  getNotifications,
};

async function getNotifications() {
  const result = createAxiosInstance()
  .get(NotificationRoute)
  .then((res) => {
    return res;
  })
  .catch((err) => {
    console.log(err);
    return err;
  });

  return result;
}
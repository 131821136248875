import { createAxiosInstance } from "./AxiosInstance";
import { SessionTypeFormatter } from "../util/formatter/SessionTypeFormatter";
import { SessionTypeInterface } from "../util/interfaces";

const SessionTypeRoute = "/session-type";

export const SessionTypeService = {
  getSessionTypes,
  getSessionTypesOptions,
  createSessionType,
  updateSessionType,
  deleteSessionType,
};

async function getSessionTypes(
  page: number = 1,
  pageSize: number = 5,
  searchText: string = "",
  sortKey: string = "",
  isAsc: boolean = false,
) {
  const key = sortKey === "name" ? "sessionTypeName" : "";
  const result = await createAxiosInstance()
    .get(SessionTypeRoute, {
      params: {
        page: page,
        size: pageSize,
        name: searchText,
        sort: sortKey !== "" ? isAsc ? `asc(${key})` : `desc(${key})` : undefined,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function getSessionTypesOptions(searchText: string) {
  const result = await createAxiosInstance()
    .get(SessionTypeRoute, {
      params: {
        page: 1,
        size: 20,
        name: searchText,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result?.data?.data;
}

async function createSessionType(sessionType: SessionTypeInterface) {
  const result = await createAxiosInstance()
    .post(
      SessionTypeRoute,
      SessionTypeFormatter.formatCreateSessionType(sessionType)
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function updateSessionType(sessionType: SessionTypeInterface) {
  const result = await createAxiosInstance()
    .put(
      SessionTypeRoute,
      SessionTypeFormatter.formatUpdateSessionType(sessionType)
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function deleteSessionType(id: string) {
  const result = await createAxiosInstance()
    .delete(SessionTypeRoute, {
      data: {
        id: id,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

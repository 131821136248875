import { useFormik } from "formik";
import { useState, HTMLInputTypeAttribute, useEffect, useCallback } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Link, useSearchParams } from "react-router-dom";

import { AuthService } from "../../services/AuthService";
import LeftDecoration from "../../images/left-decoration.png";
import RightDecoration from "../../images/right-decoration.png";
import DexwellnessLogo from "../../images/dexwellness.png";
import { handleResponse } from "../../services/HandleResponse";
import { useAppDispatch } from "../../app/hooks";
import { startTransparentLoading, stopTransparentLoading } from "../../App.Slice";

export function ResetPassword() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const resetToken = searchParams.get("token") || "";

  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [formSuccessMessage, setFormSuccessMessage] = useState("");
  document.title = "Dexwellness - Reset Password";

  const tokenValidation = useCallback(
    async (token: string) => {
      dispatch(startTransparentLoading());
      const result = await AuthService.CheckResetPassword(token);
      handleResponse({
        result: result,
        // handleSuccess: (result: any) => setFormSuccessMessage(result?.data?.data?.message),
        handleFailed: (result: any) => setFormErrorMessage(result?.response?.data?.errors?.message),
      })
      dispatch(stopTransparentLoading());
    }, [dispatch],
  );

  useEffect(() => {
    tokenValidation(resetToken);
  }, [tokenValidation, resetToken]);

  const leftDecoration = (
    <img
      src={LeftDecoration}
      alt="login background"
      className="h-2/4 absolute bottom-0 left-0 right-0 ml-[10rem] mr-auto my-0 hidden lg:block"
    />
  );

  const rightDecoration = (
    <img
      src={RightDecoration}
      alt="login background"
      className="h-2/4 absolute top-0 left-0 right-0 ml-auto mr-[10rem] my-0 hidden lg:block"
    />
  );

  const handleResetPassword = async (token: string, newPassword: string, confirmationPassword: string) => {
    dispatch(startTransparentLoading());
    const result = await AuthService.ChangeResetPassword(token, newPassword, confirmationPassword);
    handleResponse({
      result: result,
      handleSuccess: (result: any) => setFormSuccessMessage(result?.data?.data?.message),
      handleFailed: (result: any) => setFormErrorMessage(result?.response?.data?.errors?.message),
    })
    dispatch(stopTransparentLoading());
  };

  const resetResetPassword = useFormik({
    enableReinitialize: true,
    initialValues: { newPassword: "", confirmationPassword: "" },
    onSubmit: async (values) => {
      await handleResetPassword(resetToken, values.newPassword, values.confirmationPassword);
      resetResetPassword.resetForm();
    },
  });

  const logoDexWellness = (
    <label className="label flex justify-center">
      <img src={DexwellnessLogo} alt="dexwellness logo" className="h-[12rem]" />
    </label>
  );

  const formField = (
    type: HTMLInputTypeAttribute,
    label: string,
    name: string,
    placeholder: string,
    value: string | number,
    onChange: (e: React.ChangeEvent<any>) => void
  ) => (
    <>
      <label className="label">
        <span className="label-text text-lg text-black font-bold">{label}</span>
      </label>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(e) => { onChange(e); setFormErrorMessage(""); setFormSuccessMessage(""); }}
        className={`input border-black !input-bordered text-lg text-black font-normal bg-white p-4
          w-full h-[3.25rem] ${formErrorMessage ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""}`}
      />
    </>
  );

  const formTitle = (
    <label className="label flex flex-col">
      <span className="label-text text-xl text-black font-normal text-justify w-full">
        Reset Password
      </span>
    </label>
  );

  const newPasswordField = formField(
    "password", "New Password", "newPassword", "New Password",
    resetResetPassword.values.newPassword, resetResetPassword.handleChange
  );

  const confirmationPasswordField = formField(
    "password", "Confirmation Password", "confirmationPassword", "Confirmation Password",
    resetResetPassword.values.confirmationPassword, resetResetPassword.handleChange
  );

  const errorMessage = (
    <label
      className={`label text-lg text-[#DC3545] font-normal p-0 m-0 mt-3
        ${formErrorMessage ? "" : "hidden"}`}
    >
      {formErrorMessage}
    </label>
  );

  const successMessage = (
    <label
      className={`label text-lg text-black font-normal p-0 m-0 mt-3
        ${formSuccessMessage ? "flex" : "hidden"} gap-2 items-start`}
    >
      <div className=""><FaCheckCircle size={44} color="#28A745" /></div>
      <div className="flex-1">{formSuccessMessage}</div>
    </label>
  );

  const loginButton = (
    <label className="label p-0 m-0 mt-3">
      <Link to={"/login"} className="underline hover:!text-black text-black font-normal">
        Ke Halaman Login
      </Link>
    </label>
  );

  const forgotPasswordButton = (
    <button
      type="submit"
      className="btn bg-[#289B95] border-none text-[#F3EADA] w-full h-[3.25rem] mt-3"
    >
      Reset Password
    </button>
  );

  const forgotPasswordForm = (
    <form
      className="form-control w-[18.5rem] md:w-[21.8125rem] text-justify py-8"
      onSubmit={(e) => {
        e.preventDefault();
        resetResetPassword.handleSubmit(e);
      }}
    >
      {logoDexWellness}
      {formTitle}
      {newPasswordField}
      {confirmationPasswordField}
      {errorMessage}
      {successMessage}
      {forgotPasswordButton}
      {loginButton}
    </form>
  );

  return (
    <div className="flex justify-center items-center min-h-screen md:h-screen bg-[#F3EADA]">
      {leftDecoration}
      {rightDecoration}
      {forgotPasswordForm}
    </div>
  );
}

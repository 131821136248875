import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

import React from 'react';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import ReactPaginate from 'react-paginate'
import { JsxElement } from 'typescript';
import { HiMagnifyingGlassCircle } from "react-icons/hi2";

const Table = ({
    tableTitle,
    headerData,
    bodyData,
    resultsAmount,
    resultsTotal,
    resultsAmountOptions,
    resultsAmountOnChange,
    pageNumber,
    pageNumberTotal,
    changePage,
    initialPage,
    bodyFormatting,
    tableClassName,
    sortKey,
    setSortKey,
    sortIsAsc,
    setSortIsAsc,
    showFooter,
}: {
    tableTitle: string,
    headerData: any[],
    bodyData: any[],
    resultsAmount: number,
    resultsTotal: number,
    resultsAmountOptions: number[],
    resultsAmountOnChange: any,
    pageNumber: number,
    pageNumberTotal: number,
    changePage: any,
    initialPage: number,
    bodyFormatting: (key: string, row: any) => JSX.Element,
    tableClassName: string,
    sortKey: string,
    setSortKey: any,
    sortIsAsc: boolean,
    setSortIsAsc: any,
    showFooter: boolean,
}) => {
    const sortIcon = (key: string) => {
        const isKey = key === sortKey;
        return (isKey && sortIsAsc ?
            <FaSortUp />
            : isKey ?
                <FaSortDown />
                : <FaSort />
        )
    };

    const handleSort = (key: string, noSort: boolean = false) => {
        if (noSort) return;
        if (key === sortKey) {
            if (sortIsAsc === false) setSortKey("");
            else setSortIsAsc(false);
        } else {
            setSortIsAsc(true);
            setSortKey(key);
        }
    };

    const emptyView = (
        <>
            <div className='text-[#289B95] flex justify-center'>
                <HiMagnifyingGlassCircle size={60} />
            </div>
            <div className='text-black text-xl'>
                No Data Available
            </div>
        </>
    );

    return (
        <>
            <div className={`${bodyData.length > 0 ? 'hidden' : ''}`}>
                <div className="text-left font-bold text-black text-xl mb-4">{tableTitle}</div>
                <div className={`flex flex-col border-[1px] h-[25rem] place-content-center gap-4 border-black border-opacity-20 rounded-lg`}>
                    {emptyView}
                </div>
            </div>
            <div className={`w-full ${bodyData.length === 0 ? 'hidden' : ''}`}>
                <div className="text-left font-bold text-black text-xl mb-4">{tableTitle}</div>
                <SimpleBar className="overflow-x-auto w-full">
                    <table className={`${tableClassName} w-full text-[#25213B] text-base text-left`}>
                        <colgroup>
                            {headerData.map((item, index) => {
                                return <col key={index} width={item.width || "auto"} />
                            })}
                        </colgroup>
                        <thead>
                            <tr>
                                {headerData.map((item: any, index: number) => (
                                    <th key={index} className={`${item?.className} static bg-[#F2F2F2] normal-case text-base p-4 align-middle whitespace-nowrap`}
                                        onClick={item?.onClick}>
                                        <div className="flex cursor-pointer items-center" onClick={() => handleSort(item?.key, item?.noSort)}>
                                            {item?.name}{!(item?.noSort) ? <div className='mt-1'>{sortIcon(item?.key)}</div> : ""}
                                        </div>
                                    </th>))}
                            </tr>
                        </thead>
                        <tbody className='overflow-x-auto'>
                            {bodyData?.map((item: any, index: number) => (
                                <tr key={index}>
                                    {headerData.map((headerItem: any, indexHead: number) => (
                                        <td key={indexHead} className='border-b bg-[#FFFFFF] border-[#D9D5EC] rounded-none p-4 align-middle whitespace-nowrap'>
                                            {bodyFormatting(headerItem?.key, item)}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </SimpleBar>
                {showFooter && <div className='bg-[#F2F2F2] flex flex-row rounded-b-xl text-[#10397C] text-base py-3.5'>
                    <div className='flex-1 text-left pl-4 hidden md:inline'>
                        <span>{"Showing "}</span>
                        <span className='font-bold'>{`${resultsAmount * (pageNumber - 1) + 1}-${resultsAmount * pageNumber > resultsTotal ? resultsTotal : resultsAmount * pageNumber} `}</span>
                        <span>{" from "}</span>
                        <span className='font-bold'>{`${resultsTotal} data`}</span>
                    </div>
                    <div className='flex-1 pl-4 md:pl-0 text-left md:text-center'>
                        <span>{"Show "}</span>
                        <select className="bg-white px-2 py-1 h-fit rounded-lg" onChange={resultsAmountOnChange} value={resultsAmount} >
                            {resultsAmountOptions.map((item: any, index: number) => (<option key={index} value={item}>{item}</option>))}
                        </select>
                        <span>{" data"}</span>
                    </div>
                    <div className='flex-1 text-right pr-4'>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            forcePage={pageNumber - 1}
                            onPageChange={changePage}
                            pageRangeDisplayed={1}
                            marginPagesDisplayed={1}
                            pageCount={pageNumberTotal}
                            previousLabel="<"
                            containerClassName="inline-flex"
                            activeLinkClassName='font-bold text-[#10397C]'
                            pageClassName="px-1 text-[#4A86E8]"
                            breakClassName='px-1 text-[#4A86E8]'
                            previousClassName='px-1 text-[#4A86E8]'
                            nextClassName='px-1 text-[#4A86E8]'
                        />
                    </div>
                </div>}
            </div>
        </>
    );
};

Table.defaultProps = {
    tableTitle: "",
    headerData: [],
    bodyData: [],
    resultsTotal: 0,
    resultsAmountOnChange: () => null,
    changePage: () => null,
    resultsAmount: 10,
    resultsAmountOptions: [10, 20, 30, 40, 50],
    pageNumber: 1,
    pageNumberTotal: 1,
    initialPage: 0,
    tableClassName: "",
    sortKey: "",
    setSortKey: () => null,
    sortIsAsc: true,
    setSortIsAsc: () => null,
    showFooter: true,
}

export default Table;
import { FormikProps, useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { FaListUl, FaPlusSquare } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { startTransparentLoading, stopTransparentLoading } from "../../App.Slice";
import { useAppDispatch } from "../../app/hooks";
import { Main } from "../../components/Main";
import ModalAlert from "../../components/ModalAlert";
import ModalForm from "../../components/ModalForm";
import SearchBox from "../../components/SearchBox";
import Table from "../../components/Table";
import { handleResponse } from "../../services/HandleResponse";
import { ReminderService } from "../../services/ReminderService";
import { ReminderInterface } from "../../util/interfaces";
import FormContent from "./FormContent";
import { ReminderOptions } from "./ReminderOptions";

export function ViewReminderList() {
  const dispatch = useAppDispatch();
  const [modalAlertVisible, setModalAlertVisible] = useState(false);
  const [modalAlertText, setModalAlertText] = useState("Email Reminder Succesfully Created");
  const [modalAlertIsConfirmation, setModalAlertIsConfirmation] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modalFormVisible, setModalFormVisible] = useState(false);
  const [modalFormTitle, setModalFormTitle] = useState("New Email Reminder");
  const [modalFormButtonText, setModalFormButtonText] = useState("Create New Email Reminder");
  const [masterResultsAmount, setMasterResultsAmount] = useState(10);
  const [masterResultsTotal, setMasterResultsTotal] = useState(10);
  const [masterPageNumber, setMasterPageNumber] = useState(1);
  const [masterPageNumberTotal, setMasterPageNumberTotal] = useState(1);
  const [masterSearchByValue, setMasterSearchByValue] = useState("all");
  const [masterSearchText, setMasterSearchText] = useState("");
  const [masterBody, setMasterBody] = useState([]);
  const [masterSortKey, setMasterSortKey] = useState("");
  const [masterSortAsc, setMasterSortAsc] = useState(true);
  const [selectedId, setSelectedId] = useState('');
  const [modalAlertIsDelete, setModalAlertIsDelete] = useState(false);
  const [modalAlertIsFailed, setModalAlertIsFailed] = useState(false);

  const masterSearchByOnChange = (e: any) => { setMasterSearchByValue(e.target.value); };
  const mastersearchTextOnChange = (e: any) => { setMasterSearchText(e.target.value); };
  const masterResultsAmountOnChange = (e: any) => { setMasterResultsAmount(e.target.value); };
  const masterChangePage = (e: any) => { setMasterPageNumber(e.selected + 1); };
  const { masterHeader, masterSearchByOptions } = ReminderOptions;

  const masterBodyFormatting = (key: string, data: any): JSX.Element => {
    switch (key) {
      case "action":
        return (
          <div className="flex justify-between gap-2">
            <NavLink
              to={`/reminder/${data.id}`}
              className="btn btn-accent text-white text-base h-14 flex-1"
            >
              Detail
            </NavLink>
            <button
              className="btn btn-error text-white text-base h-14 bg-[#DC3545] flex-1"
              onClick={() => {
                setModalAlertText(`Delete data for ${data.name}`);
                setSelectedId(data.id);
                setModalAlertIsConfirmation(true);
                setModalAlertVisible(true);
                setModalAlertIsDelete(true);
              }}
            >
              Delete
            </button>
          </div>
        );
      default:
        return (<>{data[key]}</>);
    }
  };

  const createButton = (
    <button
        className="btn flex w-full gap-[0.625rem] border-none bg-accent normal-case text-white
        hover:bg-accent hover:bg-opacity-90 md:min-h-[3.375rem] md:w-auto md:py-4 md:px-8"        
      onClick={() => {
        setModalFormVisible(true);
      }}
    >
      <FaPlusSquare className="h-4 w-4" />
      <span>New Email Reminder</span>
    </button>
  );

  const searchBox = (
    <SearchBox
      className="flex-auto text-black"
      searchByValue={masterSearchByValue}
      searchByOnChange={masterSearchByOnChange}
      searchByOptions={masterSearchByOptions}
      searchTextOnChange={mastersearchTextOnChange}
      searchTextValue={masterSearchText}
    />
  );

  const topMenu = (
    <div className="flex flex-col items-center gap-2 md:flex-row">
      {createButton}
      {searchBox}
    </div>
  );

  const table = (
    <Table
      tableTitle="Email Reminder List"
      headerData={masterHeader}
      bodyData={masterBody}
      resultsAmount={masterResultsAmount}
      resultsTotal={masterResultsTotal}
      resultsAmountOnChange={masterResultsAmountOnChange}
      resultsAmountOptions={[10, 20, 30, 40, 50]}
      pageNumber={masterPageNumber}
      pageNumberTotal={masterPageNumberTotal}
      changePage={masterChangePage}
      bodyFormatting={masterBodyFormatting}
      sortKey={masterSortKey}
      setSortKey={setMasterSortKey}
      sortIsAsc={masterSortAsc}
      setSortIsAsc={setMasterSortAsc}
    />
  );

  const content = (
    <div className="flex w-full flex-col gap-7 bg-white px-4 py-12 md:min-h-screen md:px-0 md:py-0">
      {topMenu}
      {table}
    </div>
  );

  const handleCreateReminder = async (
    name: string,
    tokenLeft: string,
    durationLeft: string,
    emailSubject: string,
    emailContent: string
  ) => {
    dispatch(startTransparentLoading());
    const result = await ReminderService.CreateReminder(
      name, tokenLeft, durationLeft, emailSubject, emailContent
    );
    handleResponse({
      result: result,
      handleSuccess: (result: any) => {
        setModalAlertText(result?.data?.data?.message);
        setModalAlertVisible(true);
        setModalFormVisible(false);
        setRefresh(true);
        setModalAlertIsConfirmation(false);
        setModalAlertIsFailed(false);
      },
      handleFailed: (result: any) => {
        formik.setErrors(result?.response?.data?.errors)
      },
    })
    dispatch(stopTransparentLoading());
  }

  const handleDeleteReminder = async (id: string) => {
    dispatch(startTransparentLoading());
    const result = await ReminderService.DeleteReminder(id);
    handleResponse({
      result: result,
      handleSuccess: (result: any) => {
        setModalAlertText(result?.data?.data?.message);
        setModalAlertVisible(true);
        setModalFormVisible(false);
        setRefresh(true);
        setModalAlertIsConfirmation(false);
        setModalAlertIsFailed(false);
      },
      handleFailed: (result: any) => {
        setModalAlertText(result?.response?.data?.errors?.id);
        setModalAlertVisible(true);
        setModalAlertIsConfirmation(false);
        setModalAlertIsFailed(true);
      },
    })
    dispatch(stopTransparentLoading());
  }

  const formik: FormikProps<ReminderInterface> = useFormik({
    enableReinitialize: true,
    initialValues: { id: "", name: "", tokenLeft: "", durationLeft: "", emailSubject: "", emailContent: "" },
    onSubmit: async (values) => {
      await handleCreateReminder(
        values.name, values.tokenLeft, values.durationLeft, values.emailSubject, values.emailContent
      );
    },
  });

  const getReminder = useCallback(
    async (
      page: number = 1,
      size: number = 5,
      searchBy: string = "",
      searchQuery: string = "",
      sortKey: string = "",
      isAsc: boolean = false
    ) => {
      dispatch(startTransparentLoading());
      const result = await ReminderService.GetReminder(page, size, searchBy, searchQuery, sortKey, isAsc);
      handleResponse({
        result: result,
        handleSuccess: (result: any) => {
          setMasterPageNumberTotal(result?.data?.page?.totalPage);
          setMasterResultsTotal(result?.data?.page?.totalCount);
          setMasterBody(result?.data?.data);
        },
      })
      dispatch(stopTransparentLoading());
    }, [dispatch],
  );

  useEffect(() => {
    if (!modalFormVisible) formik.resetForm();
  }, [formik, modalFormVisible]);

  useEffect(() => {
    setMasterPageNumber(1);
  }, [
    masterResultsAmount,
    masterSearchText,
  ]);

  useEffect(() => {
    getReminder(
      masterPageNumber,
      masterResultsAmount,
      masterSearchByValue,
      masterSearchText,
      masterSortKey,
      masterSortAsc
    );
    setRefresh(false);
  }, [
    getReminder,
    masterPageNumber,
    masterResultsAmount,
    masterSearchByValue,
    masterSearchText,
    masterSortKey,
    masterSortAsc,
    refresh,
  ]);

  return (
    <>
      <Main
        title={"Email Reminder"}
        subtitle={`Email Reminder`}
        icon={<FaListUl className="w-4 h-4" />}
        user={"user"}
      >
        {content}
      </Main>
      <ModalAlert
        isVisible={modalAlertVisible}
        alertText={modalAlertText}
        isConfirmationAlert={modalAlertIsConfirmation}
        isFailedAlert={modalAlertIsFailed}
        handleModalVisibility={() => {
          setModalAlertVisible(!modalAlertVisible);
          setModalAlertIsConfirmation(false);
          setModalAlertIsDelete(false);
        }}
        handleConfirm={() => {
          if (modalAlertIsDelete) handleDeleteReminder(selectedId);
        }}
      />
      <ModalForm isVisible={modalFormVisible}
        handleModalVisibility={() => setModalFormVisible(!modalFormVisible)}
        modalTitle={modalFormTitle}
        buttonText={modalFormButtonText}
        formContent={<FormContent formik={formik} modalFormVisible={modalFormVisible} />}
        handleConfirm={formik.handleSubmit}
        formClassname="md:!max-w-full md:w-[70vw]"
        titleClassname="w-[50rem] md:w-full"
        submitButtonClassname="w-[50rem] md:w-full"
      />
    </>
  );
}

import { FormikProps } from "formik";
import { HTMLInputTypeAttribute, useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

import { ReminderInterface } from "../../util/interfaces";
import RichTextEditor from "./RichTextEditor";

const FormContent = (
  { formik, modalFormVisible }:
    { formik: FormikProps<ReminderInterface>, modalFormVisible: boolean }
) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (!modalFormVisible) setEditorState(EditorState.createEmpty());
  }, [modalFormVisible]);

  const formField = (
    type: HTMLInputTypeAttribute,
    label: string,
    name: string,
    placeholder: string,
    value: string | number,
    error: string | undefined,
    onChange: (e: React.ChangeEvent<any>) => void,
    onlyInteger: boolean,
  ) => (
    <div className="flex flex-col w-full">
      <label className="label">
        <span className="label-text text-lg text-black font-bold">
          {label}
          <span className="text-[#FFA17A]">*</span>
        </span>
      </label>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          const re = /^\d+$/;
          if (onlyInteger && e.target.value && !re.test(e.target.value.toString())) return;
          onChange(e);
        }}
        className={`input border-black !input-bordered text-lg text-black font-normal bg-white p-4
          w-full h-[3.375rem] ${error ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""}`}
      />
      <label className={`label ${error ? "" : "hidden"}`}>
        <span className="label-text text-lg text-[#FF0000] font-bold">{error}</span>
      </label>
    </div>
  );

  const nameField = formField(
    "text", "Reminder Name", "name", "Reminder Name",
    formik.values.name, formik.errors.name, formik.handleChange, false,
  );

  const tokenLeftField = formField(
    "text", "Token Left", "tokenLeft", "Token Left",
    formik.values.tokenLeft, formik.errors.tokenLeft, formik.handleChange, true,
  );

  const periodLeftField = formField(
    "text", "Validity Period Name (Days)", "durationLeft", "Validity Period Name (Days)",
    formik.values.durationLeft, formik.errors.durationLeft, formik.handleChange, true,
  );

  const emailSubjectField = formField(
    "text", "Email Subject", "emailSubject", "Email Subject",
    formik.values.emailSubject, formik.errors.emailSubject, formik.handleChange, false,
  );

  const richTextEditorOnchange = (state: EditorState) => {
    if(!state.getCurrentContent().getPlainText())
      return formik.setFieldValue("emailContent", "");
    const emailContent = draftToHtml(convertToRaw(state.getCurrentContent()));
    formik.setFieldValue("emailContent", emailContent);
  }

  const emailContentField = (
    <div className="">
      <label className="label">
        <span className="label-text text-lg text-black font-bold">
          Email Content
          <span className="text-[#FFA17A]">*</span>
        </span>
      </label>
      <div className={`${formik.errors.emailContent ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""}`}>
        <RichTextEditor
          editorState={editorState}
          setEditorState={setEditorState}
          onChange={richTextEditorOnchange}
        />
      </div>
      <label className={`label ${formik.errors.emailContent ? "" : "hidden"}`}>
        <span className="label-text text-lg text-[#FF0000] font-bold">{formik.errors.emailContent}</span>
      </label>
    </div>
  );

  return (
    <SimpleBar
      className="-mr-4 flex h-[80vh] max-h-[80vh] flex-col gap-0 overflow-y-auto pr-4 md:max-h-max 
      text-left text-black py-2 w-[51rem] md:w-auto"
    >
      <div className="flex w-full flex-col md:flex-row gap-2">
        {nameField}
        {tokenLeftField}
        {periodLeftField}
      </div>
      <div>
        {emailSubjectField}
      </div>
      {emailContentField}
    </SimpleBar>
  );
};

export default FormContent;

import { createAxiosInstance } from "./AxiosInstance";
import moment from 'moment';
import { TokenInterface } from "../util/interfaces";

const TokenRoute = "/token";

export const TokenService = {
  getToken,
  getTokenById,
  getTokenApproval,
  createToken,
  updateToken,
  deleteToken,
};

const formatCreateToken = (token: TokenInterface) => {
  return {
    startDate: token.startDate ? moment(new Date(token.startDate)).format("YYYY-MM-DD") : "",
    expiredDate: token.expiredDate ? moment(new Date(token.expiredDate)).format("YYYY-MM-DD") : "",
    totalToken: token.totalToken,
    companyId: token.companyId,
  }
}

const formatUpdateToken = (token: TokenInterface) => {
  return {
    tokenId: token.tokenId,
    startDate: token.startDate ? moment(new Date(token.startDate)).format("YYYY-MM-DD") : "",
    expiredDate: token.expiredDate ? moment(new Date(token.expiredDate)).format("YYYY-MM-DD") : "",
    totalToken: token.totalToken,
    companyId: token.companyId,
  }
}

async function getToken(
  page: number,
  pageSize: number,
  searchBy: string,
  searchText: string,
  sortKey: string,
  isAsc: boolean,
) {
  const result = createAxiosInstance()
  .get(TokenRoute, {
    params: {
      page: page,
      size: pageSize,
      searchBy: searchBy,
      searchQuery: searchText,
      sort: isAsc ? `asc(${sortKey})` : `desc(${sortKey})`,
    },
  })
  .then((res) => {
    return res;
  })
  .catch((err) => {
    console.log(err);
    return err;
  });

  return result;
}

async function getTokenById(id: string) {
  const result = createAxiosInstance()
  .get(TokenRoute + `/Id/${id}`)
  .then((res) => {
    return res;
  })
  .catch((err) => {
    console.log(err);
    return err;
  });

  return result;
}

async function getTokenApproval(
  page: number,
  pageSize: number,
  searchBy: string,
  searchText: string,
  sortKey: string,
  isAsc: boolean,
  status: string,
) {
  const result = createAxiosInstance()
  .get(TokenRoute + "/approval", {
    params: {
      page: page,
      size: pageSize,
      searchBy: searchBy,
      searchQuery: searchText,
      sort: isAsc ? `asc(${sortKey})` : `desc(${sortKey})`,
      status: status,
    },
  })
  .then((res) => {
    return res;
  })
  .catch((err) => {
    console.log(err);
    return err;
  });

  return result;
}

async function createToken(token: TokenInterface) {
  const result = await createAxiosInstance()
    .post(
      TokenRoute,
      formatCreateToken(token)
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function updateToken(token: TokenInterface) {
  const result = await createAxiosInstance()
    .put(
      TokenRoute,
      formatUpdateToken(token)
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function deleteToken(id: string) {
  const result = await createAxiosInstance()
    .delete(TokenRoute, {
      data: {
        id: id,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}
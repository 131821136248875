import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { NavLink } from "react-router-dom";

import SearchBox from "../../components/SearchBox";
import Table from "../../components/Table";
import { DashboardAdminService } from "../../services/DashboardAdminService";
import { handleResponse } from "../../services/HandleResponse";
import { ClientOptions } from "./ClientOptions";
import { CompanyOptions } from "./CompanyOptions";
import { CounselorOptions } from "./CounselorOptions";
import Filter from "./Filter";
import { SessionOptions } from "./SessionOptionsAdmin";

export function DashboardAdmin() {
  // session table
  const [masterSortKey, setMasterSortKey] = useState("");
  const [masterSortAsc, setMasterSortAsc] = useState(true);
  const [masterResultsAmount, setMasterResultsAmount] = useState(10);
  const [masterResultsTotal, setMasterResultsTotal] = useState(10);
  const [masterPageNumber, setMasterPageNumber] = useState(1);
  const [masterPageNumberTotal, setMasterPageNumberTotal] = useState(2);
  const [summary, setSummary] = useState({
    totalCompany: "N/A",
    activeCompany: "N/A",
    expiringCompany: "N/A",
    tokenUsage: "N/A",
    dailyTokenUsage: "N/A",
  });
  const masterResultsAmountOnChange = (e: any) => { setMasterResultsAmount(e.target.value); };
  const masterChangePage = (e: any) => { setMasterPageNumber(e.selected + 1); };

  const masterBodyFormatting = (key: string, data: any) => {
    return <div className="">{data[key]}</div>
  };

  // client table
  const [masterClientSortKey, setMasterClientSortKey] = useState("");
  const [masterClientSortAsc, setMasterClientSortAsc] = useState(true);
  const [masterClientResultsAmount, setMasterClientResultsAmount] = useState(10);
  const [masterClientResultsTotal, setMasterClientResultsTotal] = useState(10);
  const [masterClientPageNumber, setMasterClientPageNumber] = useState(1);
  const [masterClientPageNumberTotal, setMasterClientPageNumberTotal] = useState(2);
  const masterClientResultsAmountOnChange = (e: any) => { setMasterClientResultsAmount(e.target.value); };
  const masterClientChangePage = (e: any) => { setMasterClientPageNumber(e.selected + 1); };

  const masterClientBodyFormatting = (key: string, data: any) => {
    if (key === "companyName") return (
      <div className="">
        <a
          target="_blank" rel="noreferrer"
          href={`https://www.google.com/search?q=${data[key]}`}
          className="underline text-[#289B95]"
        >
          {data[key]}
        </a>
      </div>
    );
    else if (key === "status") return (
      <div
        className={`${data[key] === "Active" ? "bg-[#28A745]" : data[key] === "Expiring" ? "bg-[#FF7A00]" : "bg-[#DC3545]"} 
        text-white text-center p-2 rounded-lg capitalize`}
      >
        {data[key]}
      </div>
    );
    else return <div className="">{data[key]}</div>;
  };

  // company table
  const masterCompanyBodyFormatting = (key: string, data: any) => {
    return <div className="">{data[key]}</div>
  };

  // counselor table
  const masterCounselorBodyFormatting = (key: string, data: any) => {
    return <div className="">{data[key]}</div>
  };

  // session chart
  const [chartId, setChartId] = useState("session-chart-weekly");
  const [chartCategories, setChartCategories] = useState(["N/A"]);
  const [chartSeriesName, setChartSeriesName] = useState("total session");
  const [chartSeriesData, setChartSeriesData] = useState([0]);
  const masterFilterChartBy = { value: "chart", name: "This" };
  const [masterFilterChartValue, setMasterFilterChartValue] = useState("weekly");
  const masterFilterChartOnChange = (e: any) => { setMasterFilterChartValue(e.target.value); };
  const [masterFilterChartOptions, setMasterFilterChartOptions] = useState([
    { value: "weekly", name: "Week's Chart" },
    { value: "monthly", name: "Month's Chart" },
    { value: "quarterly", name: "Quarter's Chart" },
  ]);

  const [masterBody, setMasterBody] = useState<any[]>([]);
  const [masterClientBody, setMasterClientBody] = useState<any[]>([]);
  const [masterCompanyBody, setMasterCompanyBody] = useState<any[]>([]);
  const [masterCounselorBody, setMasterCounselorBody] = useState<any[]>([]);

  const { masterHeader } = SessionOptions;
  const { masterHeader: masterClientHeader } = ClientOptions;
  const { masterHeader: masterCompanyHeader } = CompanyOptions;
  const { masterHeader: masterCounselorHeader } = CounselorOptions;

  // get summary
  useEffect(() => {
    async function getSummary() {
      const result = await DashboardAdminService.GetSummary();
      handleResponse({
        result: result,
        handleSuccess: (result: any) => {
          setSummary(result?.data?.data);
        }
      });
    }
    getSummary();
  }, []);

  // get counselor rank
  useEffect(() => {
    async function getCompanyRank() {
      const result = await DashboardAdminService.GetCompanyRank();
      handleResponse({
        result: result,
        handleSuccess: (result: any) => {
          const companyRank = result?.data?.data;
          while (companyRank.length < 5) {
            companyRank.push({ name: "N/A", tokenUsed: "N/A" });
          }
          setMasterCompanyBody(companyRank);
        }
      });
    }
    getCompanyRank();
  }, []);

  // get counselor
  useEffect(() => {
    async function getCounselorRank() {
      const result = await DashboardAdminService.GetCounselorRank();
      handleResponse({
        result: result,
        handleSuccess: (result: any) => {
          const counselorRank = result?.data?.data;
          while (counselorRank.length < 5) {
            counselorRank.push({ name: "N/A", totalCounseling: "N/A" });
          }
          setMasterCounselorBody(counselorRank);
        }
      });
    }
    getCounselorRank();
  }, []);

  // get session chart
  useEffect(() => {
    async function getSessionChart() {
      const result = await DashboardAdminService.GetSessionChart(masterFilterChartValue);
      handleResponse({
        result: result,
        handleSuccess: (result: any) => {
          const categories = result?.data?.data.reduce((prev: any, curr: any) => ([...prev, curr.time]), []);
          const series = result?.data?.data.reduce((prev: any, curr: any) => ([...prev, curr.count]), []);
          setChartCategories(categories);
          setChartSeriesData(series);
        }
      });
    }
    getSessionChart();
  }, [masterFilterChartValue]);

  // get client
  useEffect(() => {
    async function getClient(page: number, size: number, sortKey: string, asc: boolean) {
      const result = await DashboardAdminService.GetClient(page, size, sortKey, asc);
      handleResponse({
        result: result,
        handleSuccess: (result: any) => {
          setMasterClientPageNumberTotal(result?.data?.page?.totalPage);
          setMasterClientResultsTotal(result?.data?.page?.totalCount);
          setMasterClientBody(result?.data?.data);
        }
      });
    }
    getClient(masterClientPageNumber, masterClientResultsAmount, masterClientSortKey, masterClientSortAsc);
  }, [masterClientPageNumber, masterClientResultsAmount, masterClientSortKey, masterClientSortAsc]);

  // get session
  useEffect(() => {
    async function getSession(page: number, size: number, sortKey: string, asc: boolean) {
      const result = await DashboardAdminService.GetSession(page, size, sortKey, asc);
      handleResponse({
        result: result,
        handleSuccess: (result: any) => {
          setMasterPageNumberTotal(result?.data?.page?.totalPage);
          setMasterResultsTotal(result?.data?.page?.totalCount);
          setMasterBody(result?.data?.data);
        }
      });
    }
    getSession(masterPageNumber, masterResultsAmount, masterSortKey, masterSortAsc);
  }, [masterPageNumber, masterResultsAmount, masterSortKey, masterSortAsc]);

  const summaryLabel = (label: any, value: any) => (
    <div
      className="flex flex-col text-black text-left gap-2
        rounded-lg p-4 bg-white flex-[0_0_50%] md:flex-1"
    >
      <div className="text-base font-normal">
        {label}
      </div>
      <div className="text-xl font-bold">
        {value}
      </div>
    </div>
  );

  const totalCompanyComponent = summaryLabel("Total Companies #", summary.totalCompany);
  const activeTokenComponent = summaryLabel("Active Companies #", summary.activeCompany);
  const expiringCompanyComponent = summaryLabel("Expiring Companies #", summary.expiringCompany);
  const tokenUsageComponent = summaryLabel("Token Usage %", summary.tokenUsage);
  const dailyTokenUsageComponent = summaryLabel("Daily Token Usage # (Avg)", summary.dailyTokenUsage);

  const contractSummary = (
    <div className="flex gap-4 w-full overflow-x-auto mb-2 md:mb-0">
      {totalCompanyComponent}
      {activeTokenComponent}
      {expiringCompanyComponent}
      {tokenUsageComponent}
      {dailyTokenUsageComponent}
    </div>
  );

  const sessionTable = (
    <Table
      tableTitle="Latest Sessions"
      headerData={masterHeader}
      bodyData={masterBody}
      resultsAmount={masterResultsAmount}
      resultsTotal={masterResultsTotal}
      resultsAmountOnChange={masterResultsAmountOnChange}
      pageNumber={masterPageNumber}
      pageNumberTotal={masterPageNumberTotal}
      changePage={masterChangePage}
      bodyFormatting={masterBodyFormatting}
      sortKey={masterSortKey}
      setSortKey={setMasterSortKey}
      sortIsAsc={masterSortAsc}
      setSortIsAsc={setMasterSortAsc}
      tableClassName=""
    />
  );

  const sessionSummary = (
    <div className="w-full">
      {sessionTable}
      <NavLink to={"/session"} className="w-full my-4 inline-block">
        <button className="btn btn-accent text-white p-4 w-full">
          See More...
        </button>
      </NavLink>
    </div>
  );

  const clientTable = (
    <Table
      tableTitle="List of Clients"
      headerData={masterClientHeader}
      bodyData={masterClientBody}
      resultsAmount={masterClientResultsAmount}
      resultsTotal={masterClientBody.length}
      resultsAmountOnChange={masterClientResultsAmountOnChange}
      pageNumber={masterClientPageNumber}
      pageNumberTotal={masterClientPageNumberTotal}
      changePage={masterClientChangePage}
      bodyFormatting={masterClientBodyFormatting}
      sortKey={masterClientSortKey}
      setSortKey={setMasterClientSortKey}
      sortIsAsc={masterClientSortAsc}
      setSortIsAsc={setMasterClientSortAsc}
      tableClassName=""
    />
  );

  const clientSummary = (
    <div className="w-full">
      {clientTable}
      <NavLink to={"/company"} className="w-full my-4 inline-block">
        <button className="btn btn-accent text-white p-4 w-full">
          See More...
        </button>
      </NavLink>
    </div>
  );

  const chartOptions: ApexOptions = {
    chart: {
      id: chartId
    },
    xaxis: {
      categories: chartCategories
    },
    colors: ["#FFA17A"]
  };

  const chartSeries: ApexOptions['series'] = [{
    name: chartSeriesName,
    data: chartSeriesData
  }];

  const chartFilter = (
    <Filter
      className="w-full"
      filterBy={masterFilterChartBy}
      filterValue={masterFilterChartValue}
      filterOnChange={masterFilterChartOnChange}
      filterOptions={masterFilterChartOptions}
    />
  );

  const sessionChart = (
    <div className="w-full">
      <div className="text-black text-xl font-bold my-4 text-left">
        Session Chart
      </div>
      <div className="my-4">
        {chartFilter}
      </div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        width="100%"
      />
    </div>
  );

  const companyTable = (
    <Table
      tableTitle="Top 5 Company This Month (Rank By Used Token)"
      headerData={masterCompanyHeader}
      bodyData={masterCompanyBody}
      bodyFormatting={masterCompanyBodyFormatting}
      tableClassName=""
      showFooter={false}
    />
  );

  const counselorTable = (
    <Table
      tableTitle="Top 5 Counselor This Month (Rank By Total Counseling)"
      headerData={masterCounselorHeader}
      bodyData={masterCounselorBody}
      bodyFormatting={masterCounselorBodyFormatting}
      tableClassName=""
      showFooter={false}
    />
  );

  return (
    <div className="w-full px-8 py-12 md:p-0">
      {contractSummary}
      <div className="w-full flex-col md:flex-row flex gap-6">
        <div className="md:w-2/3 flex flex-col gap-4">
          {clientSummary}
          {sessionSummary}
        </div>
        <div className="md:w-1/3 flex flex-col gap-8 md:gap-4">
          {sessionChart}
          {companyTable}
          {counselorTable}
        </div>
      </div>
    </div>
  );
}

import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import LoadingAnimation from "../images/loading.gif";

export function Loading() {
  const loading = useAppSelector((state: RootState) => state.app.loading);
  const loadingTransparent = useAppSelector((state: RootState) => state.app.loadingTransparent);

  return (
    <div className={`fixed h-screen w-screen ${loading ? "flex" : "hidden"} bg-white
      ${loadingTransparent ? "bg-opacity-50" : "bg-opacity-100"} z-[1000] justify-center items-center`}
    >
      <img src={LoadingAnimation} alt="loading animation" />
    </div>
  );
};

import React from 'react';
import { AiFillWarning } from 'react-icons/ai';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { FaTimesCircle } from "react-icons/fa";

const ModalAlert = ({
    isVisible,
    alertText,
    isConfirmationAlert,
    isFailedAlert,
    handleModalVisibility,
    handleConfirm,
}: {
    isVisible: boolean,
    alertText: string,
    isConfirmationAlert: boolean,
    isFailedAlert: boolean,
    handleModalVisibility: any,
    handleConfirm: any,
}) => {

    return (
        <div className={`${isVisible ? "" : "hidden"} flex items-center justify-center z-[1000] fixed inset-0`} onClick={handleModalVisibility}>
            <div
                className="h-screen w-screen bg-black bg-opacity-25 fixed"
                onClick={handleModalVisibility}
            />
            <div className="bg-white modal-box relative min-w-[404px] p-8" onClick={(e) => e.stopPropagation()}>
                <div className='flex justify-center'>
                    {!isConfirmationAlert ?
                        !isFailedAlert ?
                            <BsFillCheckCircleFill className="text-[#28A745] h-32 w-32" />
                            :
                            <FaTimesCircle className="text-[#DC3545] h-32 w-32" />
                        :
                        <AiFillWarning className="text-[#FF7A00] h-32 w-32" />
                    }
                </div>
                <p className="text-black py-4 text-xl whitespace-pre-wrap">{alertText.charAt(0).toUpperCase() + alertText.slice(1)}</p>
                {!isConfirmationAlert ?
                    <button className="w-full py-4 text-white rounded-lg bg-[#193341]" onClick={handleModalVisibility}>Close</button>
                    :
                    <div className="flex gap-2 w-full">
                        <button className="flex-1 py-4 text-white rounded-lg bg-[#DC3545]" onClick={handleModalVisibility}>Cancel</button>
                        <button className="flex-1 py-4 text-white rounded-lg bg-[#193341]" onClick={(e) => { handleConfirm(e); e.stopPropagation() }}>Confirm</button>
                    </div>
                }
            </div>
        </div>
    );
};

ModalAlert.defaultProps = {
    isVisible: false,
    alertText: "Alert!!!",
    isConfirmationAlert: false,
    isFailedAlert: false,
    handleConfirm: (e: any) => { },
}

export default ModalAlert;
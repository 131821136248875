import { useState } from "react";
import { FormikProps } from "formik";

import { SessionTypeInterface } from "../../util/interfaces";

const FormContent = ({
  formik,
}: {
  formik: FormikProps<SessionTypeInterface>;
}) => {
  return (
    <div className="text-left text-black py-2">
      <label className="text-base text-black font-bold">
        Session Type<span className="text-[#FFA17A]">*</span>
      </label>
      <div className="flex flex-col gap-1">
        <div className="flex justify-between w-full my-2">
          <input
            className={`bg-white border rounded p-4 w-full ${
              formik.errors.name ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
            }`}
            name="name"
            type="text"
            placeholder="Session Type"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </div>
        <span className="text-[#FF0000]">{formik.errors.name}</span>
      </div>
    </div>
  );
};

FormContent.defaultProps = {
  modalFormIsUpdate: false,
};

export default FormContent;

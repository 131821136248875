import { SessionInterface } from "../interfaces";
import { fixTimezoneOffset } from "./formatter";

const formatCreateSession = (session: SessionInterface) => {
  return {
    sessionDate: fixTimezoneOffset(session.sessionDate),
    timeStart: session.counselingTime.start,
    timeEnd: session.counselingTime.end,
    idCounselor: session.counselor?.id,
    idCompany: session.company?.companyId,
    rating: session.rating === "" ? null : session.rating,
    notes: session.notes,
    clientName: session.clientName,
    clientEmail: session.clientEmail,
    clientPhoneNumber: session.clientPhoneNumber,
    sessionStatus: session.sessionStatus?.value,
  };
};

const formatUpdateSession = (session: SessionInterface) => {
  return {
    id: session.id,
    sessionDate: fixTimezoneOffset(session.sessionDate),
    timeStart: session.counselingTime.start,
    timeEnd: session.counselingTime.end,
    idCounselor: session.counselor?.id,
    idCompany: session.company?.companyId,
    rating: session.rating === "" ? null : session.rating,
    notes: session.notes,
    clientName: session.clientName,
    clientEmail: session.clientEmail,
    clientPhoneNumber: session.clientPhoneNumber,
    sessionStatus: session.sessionStatus?.value,
  };
};

export const SessionFormatter = {
  formatCreateSession,
  formatUpdateSession,
};

import { createAxiosInstance } from "./AxiosInstance";

const DashboardAdminRoute = "/dashboard-admin";
const SummaryRoute = `${DashboardAdminRoute}/summary`;
const SessionChartRoute = `${DashboardAdminRoute}/session-chart`;
const CompanyRankRoute = `${DashboardAdminRoute}/company-rank`;
const CounselorRankRoute = `${DashboardAdminRoute}/counselor-rank`;
const ClientRoute = `${DashboardAdminRoute}/client`;
const SessionRoute = `${DashboardAdminRoute}/session`;

export const DashboardAdminService = {
  GetSummary,
  GetSessionChart,
  GetCompanyRank,
  GetCounselorRank,
  GetClient,
  GetSession,
};

async function GetSummary() {
  const result = createAxiosInstance()
    .get(SummaryRoute)
    .then((res) => { return res; })
    .catch((err) => { console.log(err); return err; });

  return result;
}

async function GetSessionChart(filter: string) {
  const result = createAxiosInstance()
    .get(SessionChartRoute, { params: { filter } })
    .then((res) => { return res; })
    .catch((err) => { console.log(err); return err; });

  return result;
}

async function GetCompanyRank() {
  const result = createAxiosInstance()
    .get(CompanyRankRoute)
    .then((res) => { return res; })
    .catch((err) => { console.log(err); return err; });

  return result;
}

async function GetCounselorRank() {
  const result = createAxiosInstance()
    .get(CounselorRankRoute)
    .then((res) => { return res; })
    .catch((err) => { console.log(err); return err; });

  return result;
}

async function GetClient(
  page: number = 1,
  size: number = 5,
  sortKey: string = "",
  isAsc: boolean = false
) {
  const sort = sortKey ? isAsc ? `asc(${sortKey})` : `desc(${sortKey})` : "";
  const result = createAxiosInstance()
    .get(ClientRoute, { params: { page, size, sort } })
    .then((res) => { return res; })
    .catch((err) => { console.log(err); return err; });

  return result;
}

async function GetSession(
  page: number = 1,
  size: number = 5,
  sortKey: string = "",
  isAsc: boolean = false
) {
  const sort = sortKey ? isAsc ? `asc(${sortKey})` : `desc(${sortKey})` : "";
  const result = createAxiosInstance()
    .get(SessionRoute, { params: { page, size, sort } })
    .then((res) => { return res; })
    .catch((err) => { console.log(err); return err; });

  return result;
}
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";

import SearchBox from "../../components/SearchBox";
import Table from "../../components/Table";
import { DashboardHRService } from "../../services/DashboardHRService";
import { changeDateFormat } from "../../util/formatter/formatter";
import Filter from "./Filter";
import { SessionOptions } from "./SessionOptions";

import { SelectionBoxAsync } from "../../components/SelectionBox";
import { SessionTypeService } from "../../services/SessionTypeService";

export function DashboardHR() {
  // token summary
  const [startDate, setStartDate] = useState('');
  const [expiredDate, setExpiredDate] = useState('');
  const [totalToken, setTotalToken] = useState(0);
  const [usedToken, setUsedToken] = useState(0);
  const [availToken, setAvailToken] = useState(0);

  // session table
  const [masterSortKey, setMasterSortKey] = useState("");
  const [masterSortAsc, setMasterSortAsc] = useState(true);
  const [masterResultsAmount, setMasterResultsAmount] = useState(5);
  const [masterResultsTotal, setMasterResultsTotal] = useState(5);
  const [masterPageNumber, setMasterPageNumber] = useState(1);
  const [masterPageNumberTotal, setMasterPageNumberTotal] = useState(2);
  const [masterBody, setMasterBody] = useState([]);
  const masterResultsAmountOnChange = (e: any) => { setMasterResultsAmount(e.target.value); };
  const masterChangePage = (e: any) => { setMasterPageNumber(e.selected + 1); };
  const masterBodyFormatting = (key: string, data: any) => {
    switch (key) {
      case "time":
        return <div>{`${data.timeStart.slice(0, 5)} - ${data.timeEnd.slice(0, 5)}`}</div>
      default:
        return <div className="">{data[key]}</div>
    }
  };

  // session search
  const [masterSearchByValue, setMasterSearchByValue] = useState("all");
  const [masterSearchText, setMasterSearchText] = useState("");
  const masterSearchByOnChange = (e: any) => { setMasterSearchByValue(e.target.value); };
  const mastersearchTextOnChange = (e: any) => { setMasterSearchText(e.target.value); };

  // session filter
  const [masterFilter, setMasterFilter] = useState(null);
  const [masterFilterValue, setMasterFilterValue] = useState('');

  // session chart
  const [chartId, setChartId] = useState("session-chart-weekly");
  const [chartCategories, setChartCategories] = useState([
    "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
  ]);
  const [chartSeriesName, setChartSeriesName] = useState("total session");
  const [chartSeriesData, setChartSeriesData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const masterFilterChartBy = { value: "chart", name: "This" };
  const [masterFilterChartValue, setMasterFilterChartValue] = useState("weekly");
  const masterFilterChartOnChange = (e: any) => { setMasterFilterChartValue(e.target.value); };
  const [masterFilterChartOptions, setMasterFilterChartOptions] = useState([
    { value: "weekly", name: "Week's Chart" },
    { value: "monthly", name: "Month's Chart" },
    { value: "quarterly", name: "Quarter's Chart" },
  ]);

  const {
    masterHeader, masterSearchByOptions,
  } = SessionOptions;

  useEffect(() => {
    const getSessionChart = async () => {
      const response = await DashboardHRService.getSessionChart(
        masterFilterChartValue
      );

      setChartSeriesData(response?.data?.data?.yAxis);
      setChartCategories(response?.data?.data?.xAxis);
    }

    getSessionChart();
  }, [masterFilterChartValue])

  useEffect(() => {
    const getTokenSummary = async () => {
      const response = await DashboardHRService.getTokenSummary();

      setStartDate(changeDateFormat(response?.data?.data?.startDate));
      setExpiredDate(changeDateFormat(response?.data?.data?.expiredDate));
      setTotalToken(response?.data?.data?.totalToken);
      setAvailToken(response?.data?.data?.availToken);
      setUsedToken(response?.data?.data?.usedToken);
    }

    getTokenSummary();
  }, [startDate, expiredDate, totalToken, usedToken, availToken])

  useEffect(() => {
    const sort = (!masterSortKey) ? 'desc(createdAt)' : (masterSortAsc ? `asc(${masterSortKey})` : `desc(${masterSortKey})`);
    const getSession = async () => {
      const response = await DashboardHRService.getSession(
        masterPageNumber,
        masterResultsAmount,
        masterSearchByValue,
        masterSearchText,
        sort,
        masterFilterValue
      )

      setMasterPageNumberTotal(response?.data?.page?.totalPage);
      setMasterResultsTotal(response?.data?.page?.totalCount);
      setMasterBody(response?.data?.data);
    }

    getSession();

  }, [masterPageNumber, masterResultsAmount, masterSearchByValue, masterSearchText, masterSortAsc, masterSortKey, masterFilterValue]);

  const summaryLabel = (label: any, value: any, bgColor: string, textColor: string) => (
    <div
      className={`flex flex-col ${textColor} text-left gap-2 flex-[0_0_50%] md:flex-1
        border border-opacity-20 border-black rounded-lg p-4 ${bgColor} text-ellipsis overflow-hidden`}
    >
      <div className="text-base font-normal">
        {label}
      </div>
      <div className="text-xl font-bold"> 
        {typeof value === "number" ? Math.round(Number(value) * 100) / 100 : value}
      </div>
    </div>
  );

  const startDateComponent = summaryLabel("Start Date", startDate, "bg-[#FFFFFF]", "text-black");
  const endDateComponent = summaryLabel("End Date", expiredDate, "bg-[#FFC107]", "text-black");
  const totalTokenComponent = summaryLabel("Token", totalToken, "bg-[#FFFFFF]", "text-black");
  const usedTokenComponent = summaryLabel("Used Token", usedToken, "bg-[#FFC107]", "text-black");
  const tokenLeftComponent = summaryLabel("Token Left", availToken, "bg-[#DC1F31]", "text-white");

  const contractSummary = (
    <div className="flex gap-4 w-full overflow-x-auto mb-2 md:mb-0">
      {startDateComponent}
      {endDateComponent}
      {totalTokenComponent}
      {usedTokenComponent}
      {tokenLeftComponent}
    </div>
  );

  const sessionTable = (
    <Table
      tableTitle=""
      headerData={masterHeader}
      bodyData={masterBody}
      resultsAmount={masterResultsAmount}
      resultsTotal={masterResultsTotal}
      resultsAmountOnChange={masterResultsAmountOnChange}
      pageNumber={masterPageNumber}
      pageNumberTotal={masterPageNumberTotal}
      changePage={masterChangePage}
      bodyFormatting={masterBodyFormatting}
      sortKey={masterSortKey}
      setSortKey={setMasterSortKey}
      sortIsAsc={masterSortAsc}
      setSortIsAsc={setMasterSortAsc}
      tableClassName=""
    />
  );

  const sessionSearch = (
    <SearchBox
      className="w-3/4"
      searchByValue={masterSearchByValue}
      searchByOnChange={masterSearchByOnChange}
      searchByOptions={masterSearchByOptions}
      searchTextOnChange={mastersearchTextOnChange}
      searchTextValue={masterSearchText}
    />
  );

  const sessionFilter = (
    <SelectionBoxAsync
      className="w-[15rem]"
      placeholder="Show Session Type All"
      value={masterFilter}
      getOptionLabel={(e: any) => e?.name}
      getOptionValue={(e: any) => e?.id}
      handleChange={(value: any) => {
        setMasterFilter(value);
        (value) ? setMasterFilterValue(value.id) : setMasterFilterValue('')
      }}
      loadOptions={SessionTypeService.getSessionTypesOptions}
      styleTemplate="page"
    />
  );

  const sessionSummary = (
    <div className="w-full md:w-2/3">
      <div className="text-black text-xl font-bold my-4 text-left">
        List Session
      </div>
      <div className="hidden md:flex gap-4">
        {sessionSearch}
        {sessionFilter}
      </div>
      {sessionTable}
    </div>
  );

  const chartOptions: ApexOptions = {
    chart: {
      id: chartId
    },
    xaxis: {
      categories: chartCategories
    },
    colors: ["#289B95"]
  };

  const chartSeries: ApexOptions['series'] = [{
    name: chartSeriesName,
    data: chartSeriesData
  }];

  const chartFilter = (
    <Filter
      className="w-full"
      filterBy={masterFilterChartBy}
      filterValue={masterFilterChartValue}
      filterOnChange={masterFilterChartOnChange}
      filterOptions={masterFilterChartOptions}
    />
  );

  const sessionChart = (
    <div className="w-full md:w-1/3">
      <div className="text-black text-xl font-bold my-4 text-left">
        Session Chart
      </div>
      <div className="my-4">
        {chartFilter}
      </div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        width="100%"
      />
    </div>
  );

  return (
    <div className="w-full px-8 py-12 md:p-0">
      {contractSummary}
      <div className="w-full flex flex-col md:flex-row gap-6">
        {sessionSummary}
        {sessionChart}
      </div>
    </div>
  );
}

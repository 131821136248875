import { useState, useRef } from "react";
import { FaChevronDown } from "react-icons/fa";
import { components } from "react-select";
import Select, { StylesConfig } from "react-select";
import AsyncSelect from "react-select/async";

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <FaChevronDown className="w-2 h-2 lg:w-4 lg:h-4" fill="#000000" />
  </components.DropdownIndicator>
);

const noOptionsMessage = ({ inputValue }: { inputValue: any }) =>
  inputValue.length === 0 ? "No options" : "No results found";

type OptionType = {
  label: string;
  value: string;
};

const customStylesSingle: StylesConfig<OptionType, false> = {
  option: (base, props) => ({
    ...base,
    color: props.isSelected ? "white" : "black",
    backgroundColor: props.isSelected ? "#289B95" : "white",
    "&:hover": {
      backgroundColor: "#F3EADA",
      color: "black",
    },
  }),
  control: (base, props) => ({
    ...base,
    height: "3.375rem",
    minHeight: "3.375rem",
    background: "white",
    borderRadius: props.isFocused ? "8px 8px 0 0" : 8,
    borderColor: props.isFocused ? "#B6BBC4" : "#CCCCCC",
    boxShadow: undefined,
    "&:hover": {
      borderColor: props.isFocused ? "#B6BBC4" : "#CCCCCC",
    },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    hyphens: "auto",
    marginTop: 0,
    textAlign: "left",
    wordWrap: "break-word",
    zIndex: 9999,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    maxHeight: "8rem",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "black",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const customStylesSingleForModal: StylesConfig<OptionType, false> = {
  placeholder: (base, props) => ({
    ...base,
    color: "#CCCCCC",
  }),
};

const customStylesSingleForPage: StylesConfig<OptionType, false> = {
  placeholder: (base, props) => ({
    ...base,
    color: props.isFocused ? "#CCCCCC" : "black",
    fontSize: "1rem",
  }),
};

const customStylesMulti: StylesConfig<OptionType, true> = {
  option: (base, props) => ({
    ...base,
    color: props.isDisabled ? "#CCCCCC" : props.isSelected ? "white" : "black",
    backgroundColor: props.isDisabled
      ? "#E5E7EB"
      : props.isSelected
      ? "#289B95"
      : "white",
    "&:hover": {
      color: props.isDisabled ? "#CCCCCC" : "black",
      backgroundColor: props.isDisabled ? "#E5E7EB" : "#F3EADA",
    },
  }),
  control: (base, props) => ({
    ...base,
    minHeight: "3.375rem",
    background: "white",
    borderRadius: props.isFocused ? "8px 8px 0 0" : 8,
    borderColor: props.isFocused ? "#B6BBC4" : "#E5E7EB",
    boxShadow: undefined,
    "&:hover": {
      borderColor: props.isFocused ? "#B6BBC4" : "#E5E7EB",
    },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    hyphens: "auto",
    marginTop: 0,
    textAlign: "left",
    wordWrap: "break-word",
    zIndex: 9999,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    maxHeight: "8rem",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
  }),
  multiValueLabel: (base) => ({
    ...base,
    backgroundColor: "#F3EADA",
    color: "#289B95",
    borderRadius: "6px 0px 0px 6px",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#289B95",
    backgroundColor: "#F3EADA",
    borderRadius: "0px 6px 6px 0px",
    ":hover": {
      backgroundColor: "#289B95",
      color: "white",
    },
  }),
};

const customStyleMultiForModal: StylesConfig<OptionType, true> = {
  placeholder: (base, props) => ({
    ...base,
    color: "#CCCCCC",
  }),
};

const customStyleMultiForPage: StylesConfig<OptionType, true> = {
  placeholder: (base, props) => ({
    ...base,
    color: props.isFocused ? "#CCCCCC" : "black",
    fontSize: "1rem",
  }),
};

const getStyleTemplateSingle = (template: string) => {
  switch (template) {
    case "page":
      return { ...customStylesSingle, ...customStylesSingleForPage };
    case "modal":
      return { ...customStylesSingle, ...customStylesSingleForModal };
    default:
      return { ...customStylesSingle, ...customStylesSingleForModal };
  }
};

const getStyleTemplateMulti = (template: string) => {
  switch (template) {
    case "page":
      return { ...customStylesMulti, ...customStyleMultiForPage };
    case "modal":
      return { ...customStylesMulti, ...customStyleMultiForModal };
    default:
      return { ...customStylesMulti, ...customStyleMultiForModal };
  }
};

export const SelectionBox = ({
  className,
  placeholder,
  value,
  options,
  handleChange,
  isClearable,
  styleTemplate,
}: {
  className: string;
  placeholder: string;
  value: any;
  options: any;
  handleChange: any;
  isClearable: boolean;
  styleTemplate: string;
}) => {
  const [placeholderState, setPlaceholderState] = useState<string>(placeholder);
  const clearPlaceholder = () => setPlaceholderState("Type to search");
  const resetPlaceholder = () => setPlaceholderState(placeholder);
  const selectRef = useRef<any>(null);
  return (
    <Select
      className={className}
      ref={selectRef}
      onFocus={clearPlaceholder}
      onBlur={resetPlaceholder}
      placeholder={placeholderState}
      value={value}
      options={[...options]}
      onChange={(option, { action }) => {
        if (action === "clear" || action === "remove-value") {
          setTimeout(() => selectRef.current.blur(), 1);
        }
        handleChange(option, { action });
      }}
      styles={getStyleTemplateSingle(styleTemplate)}
      components={{ DropdownIndicator }}
      isClearable={isClearable}
    />
  );
};

SelectionBox.defaultProps = {
  className: "",
  isClearable: true,
  styleTemplate: "",
};

export const SelectionBoxAsync = ({
  className,
  placeholder,
  value,
  getOptionLabel,
  getOptionValue,
  handleChange,
  loadOptions,
  menuShouldScrollIntoView,
  defaultOptions,
  isClearable,
  styleTemplate,
}: {
  className: string;
  placeholder: string;
  value: any;
  getOptionLabel: any;
  getOptionValue: any;
  handleChange: any;
  loadOptions: any;
  menuShouldScrollIntoView: boolean;
  defaultOptions: boolean;
  isClearable: boolean;
  styleTemplate: string;
}) => {
  const [placeholderState, setPlaceholderState] = useState<string>(placeholder);
  const clearPlaceholder = () => setPlaceholderState("Type to search");
  const resetPlaceholder = () => setPlaceholderState(placeholder);
  const selectRef = useRef<any>(null);
  return (
    <AsyncSelect
      className={className}
      cacheOptions
      ref={selectRef}
      onFocus={clearPlaceholder}
      onBlur={resetPlaceholder}
      placeholder={placeholderState}
      value={value}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      onChange={(option, { action }) => {
        if (action === "clear" || action === "remove-value") {
          setTimeout(() => selectRef.current.blur(), 1);
        }
        handleChange(option, { action });
      }}
      styles={getStyleTemplateSingle(styleTemplate)}
      components={{ DropdownIndicator }}
      loadOptions={loadOptions}
      noOptionsMessage={noOptionsMessage}
      menuShouldScrollIntoView={menuShouldScrollIntoView}
      defaultOptions={defaultOptions}
      isClearable={isClearable}
    />
  );
};

SelectionBoxAsync.defaultProps = {
  className: "",
  menuShouldScrollIntoView: true,
  defaultOptions: true,
  isClearable: true,
  styleTemplate: "",
};

export const MultiSelectionBoxAsync = ({
  className,
  placeholder,
  value,
  getOptionLabel,
  getOptionValue,
  handleChange,
  loadOptions,
  maxOptions,
  styleTemplate,
}: {
  className: string;
  placeholder: any;
  value: any;
  getOptionLabel: any;
  getOptionValue: any;
  handleChange: any;
  loadOptions: any;
  maxOptions: number;
  styleTemplate: string;
}) => (
  <AsyncSelect
    className={className}
    cacheOptions
    defaultOptions
    placeholder={<div className="placeholder-black">{placeholder}</div>}
    value={value}
    getOptionLabel={getOptionLabel}
    getOptionValue={getOptionValue}
    onChange={handleChange}
    styles={getStyleTemplateMulti(styleTemplate)}
    components={{ DropdownIndicator }}
    isMulti={true}
    isClearable={false}
    isOptionDisabled={() => value.length >= maxOptions}
    loadOptions={loadOptions}
    noOptionsMessage={noOptionsMessage}
  />
);

MultiSelectionBoxAsync.defaultProps = {
  className: "",
  maxOptions: 999,
  styleTemplate: "",
};

import { useState } from 'react';
import { FormikProps } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { CompanyInterface } from "../../util/interfaces";

const FormContent = ({
  formik,
  modalFormIsUpdate,
  hrAdminList,
}: {
  formik: FormikProps<CompanyInterface>,
  modalFormIsUpdate: boolean,
  hrAdminList: any[],
}) => {
  const [startDateType, setStartDateType] = useState("text");
  const [endDateType, setEndDateType] = useState("text");

  return (
    <div className='text-left text-black overflow-auto max-h-[43rem]'>
      <label className='text-base text-black font-bold'>Company Name<span className='text-[#FFA17A]'>*</span></label>
      <div className='flex flex-col justify-between w-full my-2'>
        <input className={`bg-white border rounded p-2 w-full ${formik.errors.companyName ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
          }`} type="text" placeholder="Company Name"
          name="companyName" value={formik.values.companyName}
          onChange={formik.handleChange} />
        {formik.errors.companyName ? <span className="text-[#FF0000]">{formik.errors.companyName}</span> : ""}
      </div>
      <div className='flex flex-col md:flex-row gap-2 justify-between w-full my-2'>
        <div className="bg-white rounded w-full md:w-[49%]">
          <label className='text-base text-black font-bold'>CP Name<span className='text-[#FFA17A]'>*</span></label>
          <div className='flex flex-col justify-between w-full my-2'>
            <input className={`bg-white border rounded p-2 w-full ${formik.errors.cpName ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
              }`} type="text" placeholder="CP Name"
              name="cpName" value={formik.values.cpName}
              onChange={formik.handleChange} />
            {formik.errors.cpName ? <span className="text-[#FF0000]">{formik.errors.cpName}</span> : ""}
          </div>
        </div>
        <div className="bg-white rounded w-full md:w-[49%]">
          <label className='text-base text-black font-bold'>CP Email<span className='text-[#FFA17A]'>*</span></label>
          <div className='flex flex-col justify-between w-full my-2'>
            <input className={`bg-white border rounded p-2 w-full ${formik.errors.cpEmail ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
              }`} type="text" placeholder="CP Email"
              name="cpEmail" value={formik.values.cpEmail}
              onChange={formik.handleChange} />
            {formik.errors.cpEmail ? <span className="text-[#FF0000]">{formik.errors.cpEmail}</span> : ""}
          </div>
        </div>
      </div>
      {modalFormIsUpdate ?
        <>
          <div className='text-xl text-black border-b font-bold mb-1 pb-1'><label>Company HR Admin Data</label></div>
          <label className='text-base text-black font-bold'>HR Admin<span className='text-[#FFA17A]'>*</span></label>
          <select className={`${formik.values.hrAdminId === "" ? "text-[#9ca3af]" : "text-black"} bg-white w-full border rounded text-black p-2 mt-1 ${formik.errors.hrAdminId ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
            }`} onChange={formik.handleChange}
            name="hrAdminId" value={formik.values.hrAdminId}>
            <option key={0} value="" disabled>Choose HR Admin</option>
            {hrAdminList.map((item: any, index: number) => { return (<option key={index + 1} value={item?.value}>{item?.name}</option>) })}
          </select>
          {formik.errors.hrAdminId ? <span className="text-[#FF0000]">{formik.errors.hrAdminId}</span> : ""}
        </>
        :
        <div className='flex flex-col md:flex-row gap-2 justify-between w-full my-2'>
          <div className="bg-white rounded w-full md:w-[49%]">
            <div className='text-xl text-black border-b font-bold mb-1 pb-1'><label>Company HR Admin Data</label></div>
            <label className='text-base text-black font-bold'>HR Admin Name<span className='text-[#FFA17A]'>*</span></label>
            <div className='flex flex-col justify-between w-full my-2'>
              <input className={`bg-white border rounded p-2 w-full ${formik.errors.hrAdminName ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
                }`} type="text" placeholder="HR Admin Name"
                onChange={formik.handleChange}
                name="hrAdminName" value={formik.values.hrAdminName} />
              {formik.errors.hrAdminName ? <span className="text-[#FF0000]">{formik.errors.hrAdminName}</span> : ""}
            </div>
            <label className='text-base text-black font-bold'>HR Admin Email<span className='text-[#FFA17A]'>*</span></label>
            <div className='flex flex-col md:flex-row gap-2 justify-between w-full my-2'>
              <div className="flex flex-col bg-white rounded justify-between w-full md:w-[49%]">
                <input className={`bg-white border rounded p-2 w-full ${formik.errors.hrAdminEmail1 ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
                  }`} type="text" placeholder="Email"
                  onChange={formik.handleChange}
                  name="hrAdminEmail1" value={formik.values.hrAdminEmail1} />
                {formik.errors.hrAdminEmail1 ? <span className="text-[#FF0000]">{formik.errors.hrAdminEmail1}</span> : ""}
              </div>
              <div className="flex flex-col bg-white rounded justify-between w-full md:w-[49%]">
                <input className={`bg-white border rounded p-2 w-full ${formik.errors.hrAdminEmail2 ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
                  }`} type="text" placeholder="Second Email"
                  onChange={formik.handleChange}
                  name="hrAdminEmail2" value={formik.values.hrAdminEmail2} />
                {formik.errors.hrAdminEmail2 ? <span className="text-[#FF0000]">{formik.errors.hrAdminEmail2}</span> : ""}
              </div>
            </div>
          </div>
          <div className="bg-white rounded w-full md:w-[49%]">
            <div className='text-xl text-black border-b font-bold mb-1 pb-1'><label>Company Token Data</label></div>
            <label className='text-base text-black font-bold mt-2'>Contract Date</label>
            <div className='flex flex-col md:flex-row gap-2 justify-between w-full my-2'>
              <div className="w-full md:w-[49%]">
                <DatePicker
                  selected={formik.values.tokenStartDate}
                  onChange={(date: Date) => formik.setFieldValue("tokenStartDate", date)}
                  className="bg-white border rounded p-2 flex flex-col gap-2 focus:outline-none cursor-pointer w-full"
                  placeholderText="Start Date"
                  dateFormat="dd/MM/yyyy"
                />
                {formik.errors.tokenStartDate ? <span className="text-[#FF0000]">{`${formik.errors.tokenStartDate}`}</span> : ""}
              </div>
              <div className="w-full md:w-[49%]">
                <DatePicker
                  selected={formik.values.tokenEndDate}
                  onChange={(date: Date) => formik.setFieldValue("tokenEndDate", date)}
                  className="bg-white border rounded p-2 flex flex-col gap-2 focus:outline-none cursor-pointer w-full"
                  placeholderText="Expired Date"
                  dateFormat="dd/MM/yyyy"
                />
                {formik.errors.tokenEndDate ? <span className="text-[#FF0000]">{`${formik.errors.tokenEndDate}`}</span> : ""}
              </div>
            </div>
            <label className='text-base text-black font-bold'>Total Token</label>
            <div className='flex flex-col gap-2 justify-between w-full my-2'>
              <input className={`bg-white border rounded p-2 w-full ${formik.errors.tokenAmount ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""
                }`} type="number" placeholder="Total Token"
                name="tokenAmount" value={formik.values.tokenAmount}
                onChange={formik.handleChange}
              />
              {formik.errors.tokenAmount ? <span className="text-[#FF0000]">{formik.errors.tokenAmount}</span> : ""}
            </div>
          </div>
        </div>
      }
    </div>
  )
};

FormContent.defaultProps = {
  modalFormIsUpdate: false,
  hrAdminList: [],
}

export default FormContent;
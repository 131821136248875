export function changeCase(text: string) {
  if (!text || text === "") return "";
  return text[0].toUpperCase() + text.slice(1).toLowerCase();
}

export const fixTimezoneOffset = (date: Date | null) : string => {
  if(!date) return "";
  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
}

export const changeDateFormat = (rawDate: string) => {
  const date = new Date(rawDate);
  const formatedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`
  return formatedDate
}

import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import {
  FaListUl,
  FaPlusSquare,
  FaPencilAlt,
  FaTrashAlt,
} from "react-icons/fa";

import { Main } from "../../components/Main";
import TabsPanel from "../../components/TabsPanel";
import ModalForm from "../../components/ModalForm";
import ModalAlert from "../../components/ModalAlert";
import SearchBox from "../../components/SearchBox";
import Table from "../../components/Table";
import { SelectionBox, SelectionBoxAsync } from "../../components/SelectionBox";
import { ApprovalCounselor } from "./ApprovalCounselor";

import FormContent from "./FormContent";

import { SessionTypeService } from "../../services/SessionTypeService";
import { ExpertiseService } from "../../services/ExpertiseService";
import { CounselorService } from "../../services/CounselorService";
import { CounselorInterface } from "../../util/interfaces";
import { changeCase } from "../../util/formatter/formatter";

export function Counselor(props: any) {
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(
    location?.state?.initialTabIndex ? location?.state?.initialTabIndex : 0
  );
  const [tabIndex, setTabIndex] = useState(
    location?.state?.initialTabIndex ? location?.state?.initialTabIndex : 0
  );
  const [refresh, setRefresh] = useState(false);

  const masterSearchByOptions = [
    { value: "all", name: "All" },
    { value: "counselorName", name: "Counselor Name" },
    { value: "gender", name: "Gender" },
    { value: "tokenPerHour", name: "Token per Hour" },
    { value: "sessionTypeName", name: "Session Type" },
    { value: "expertiseName", name: "Expertise" },
  ];

  const [masterBody, setMasterBody] = useState([]);
  const [selectedData, setSelectedData] = useState<CounselorInterface | null>(
    null
  );
  const [modalFormIsUpdate, setModalFormIsUpdate] = useState(false);
  const [modalAlertIsConfirmation, setModalAlertIsConfirmation] =
    useState(false);
  const [modalAlertIsFailed, setModalAlertIsFailed] = useState(false);
  const [modalAlertIsDelete, setModalAlertIsDelete] = useState(false);

  const formik = useFormik<CounselorInterface>({
    enableReinitialize: true,
    initialValues: {
      id: selectedData?.id || "",
      name: selectedData?.name || "",
      gender: selectedData?.gender || "",
      tokenPerHour: selectedData?.tokenPerHour || 0,
      expertise: selectedData?.expertise || [],
      sessionType: selectedData?.sessionType || null,
      email: selectedData?.email || "",
    },
    onSubmit: async (values) => {
      if (modalFormIsUpdate) {
        const result = await CounselorService.updateCounselor(values);
        handleResponse(result, true, false);
      } else {
        const result = await CounselorService.createCounselor(values);
        handleResponse(result, false, false);
      }
    },
  });

  const handleResponse = (result: any, isUpdate = false, isDelete = false) => {
    if (result?.data?.code === "200") {
      setModalAlertText(result?.data?.data?.message);
      setModalAlertVisible(true);
      if (isUpdate) {
        setModalFormIsUpdate(false);
      }
      setModalFormVisible(false);
      setRefresh(true);
      formik.resetForm();
    } else {
      if (result?.code === "ERR_NETWORK") {
        setModalAlertText("Can't connect to the server");
        setModalAlertVisible(true);
        setModalAlertIsFailed(true);
      } else if (!isDelete && result?.response?.data?.errors) {
        const errors = result?.response?.data?.errors;
        for (let key of Object.keys(errors)) {
          if (key === "expertiseIds") {
            formik.setFieldError("expertise", errors[key]);
          } else if (key === "sessionTypeId") {
            formik.setFieldError("sessionType", errors[key]);
          } else {
            formik.setFieldError(key, errors[key]);
          }
        }
      } else {
        setModalAlertText(result?.response?.data?.data?.message);
        setModalAlertVisible(true);
        setModalAlertIsFailed(true);
      }
    }
    if (isDelete) {
      setModalAlertIsConfirmation(false);
    }
  };

  const handleDelete = async (id: string) => {
    const result = await CounselorService.deleteCounselor(id);
    handleResponse(result, false, true);
  };

  const genderOptions = [
    { label: "Male", value: "MALE" },
    { label: "Female", value: "FEMALE" },
  ];
  const [filterGender, setFilterGender] = useState(null);
  const [filterSessionType, setFilterSessionType] = useState(null);
  const [filterExpertise, setFilterExpertise] = useState(null);

  const TOOLTIP_ID_UPDATE = "counselor-update-";
  const TOOLTIP_ID_DELETE = "counselor-delete-";

  const masterBodyFormatting = (key: string, data: any): JSX.Element => {
    let classNameAction =
      "btn rounded-lg border-none flex w-12 items-center justify-center rounded-lg text-white";
    switch (key) {
      case "action":
        return (
          <div className="flex gap-2">
            <span
              data-tooltip-html="Counselor is on approval<br/>cant be edited"
              data-tip-disable={false}
              id={TOOLTIP_ID_UPDATE + data?.id}
            >
              <button
                className={`${classNameAction} bg-orange-500 hover:bg-orange-500`}
                onClick={() => {
                  setModalFormTitle("Update Counselor");
                  setModalFormButtonText("Update Counselor");
                  setModalFormIsUpdate(true);
                  setModalFormVisible(true);
                  setSelectedData(data);
                }}
                disabled={data?.isWaitingApproval}
              >
                <FaPencilAlt className="h-4 w-4" fill="#FFFFFF" />
              </button>
              {data?.isWaitingApproval ? (
                <ReactTooltip anchorId={TOOLTIP_ID_UPDATE + data?.id} />
              ) : (
                <></>
              )}
            </span>
            <span
              data-tooltip-html="Counselor is on approval<br/>cant be deleted"
              data-tip-disable={false}
              id={TOOLTIP_ID_DELETE + data?.id}
            >
              <button
                className={`${classNameAction} bg-red-700 hover:bg-red-700`}
                onClick={() => {
                  setModalAlertText(
                    `Are you sure you want to delete this counselor ?`
                  );
                  setModalAlertIsConfirmation(true);
                  setModalAlertVisible(true);
                  setSelectedData(data);
                  setModalAlertIsDelete(true);
                }}
                disabled={data?.isWaitingApproval}
              >
                <FaTrashAlt className="h-4 w-4" fill="#FFFFFF" />
              </button>
              {data?.isWaitingApproval ? (
                <ReactTooltip anchorId={TOOLTIP_ID_DELETE + data?.id} />
              ) : (
                <></>
              )}
            </span>
          </div>
        );
      case "expertise":
        return (
          <div>
            {data[key].map((expertise: any, idx: number) => (
              <>
                <span key={idx}>{expertise?.name}</span>
                {idx !== data[key]?.length - 1 ? (
                  <>
                    ,<br />
                  </>
                ) : (
                  <></>
                )}
              </>
            ))}
          </div>
        );
      case "sessionType":
        return <>{data[key]?.name}</>;
      case "gender":
        return <>{changeCase(data[key])}</>;
      default:
        return <>{data[key]}</>;
    }
  };

  const [modalAlertVisible, setModalAlertVisible] = useState(false);
  const [modalAlertText, setModalAlertText] = useState(
    "Counselor Succesfully Created"
  );
  const [modalFormVisible, setModalFormVisible] = useState(false);
  const [modalFormTitle, setModalFormTitle] = useState("New Counselor");
  const [modalFormButtonText, setModalFormButtonText] =
    useState("Add New Counselor");

  const [masterSearchByValue, setMasterSearchByValue] = useState("all");
  const [masterSearchText, setMasterSearchText] = useState("");
  const [masterResultsAmount, setMasterResultsAmount] = useState(10);
  const [masterResultsTotal, setMasterResultsTotal] = useState(10);
  const [masterPageNumber, setMasterPageNumber] = useState(1);
  const [masterPageNumberTotal, setMasterPageNumberTotal] = useState(2);
  const [masterSortKey, setMasterSortKey] = useState("");
  const [masterSortIsAsc, setMasterSortIsAsc] = useState(true);

  const masterSearchByOnChange = (e: any) => {
    setMasterSearchByValue(e.target.value);
  };
  const mastersearchTextOnChange = (e: any) => {
    setMasterSearchText(e.target.value);
  };
  const masterResultsAmountOnChange = (e: any) => {
    setMasterResultsAmount(e.target.value);
  };
  const masterChangePage = (e: any) => {
    setMasterPageNumber(e.selected + 1);
  };

  const getCounselors = async (page: number) => {
    const response = await CounselorService.getCounselors(
      page,
      masterResultsAmount,
      masterSearchByValue,
      masterSearchText,
      masterSortKey,
      masterSortIsAsc,
      filterGender,
      filterSessionType,
      filterExpertise
    );
    setMasterPageNumberTotal(response?.data?.page?.totalPage);
    setMasterResultsTotal(response?.data?.page?.totalCount);
    setMasterBody(response?.data?.data);
  };

  useEffect(() => {
    getCounselors(masterPageNumber);
  }, [masterPageNumber]);

  useEffect(() => {
    if (masterPageNumber === 1) {
      getCounselors(1);
    }
    setMasterPageNumber(1);
    if (refresh) {
      setRefresh(false);
    }
  }, [
    masterResultsAmount,
    masterSearchByValue,
    masterSearchText,
    masterSortKey,
    masterSortIsAsc,
    filterGender,
    filterSessionType,
    filterExpertise,
    refresh,
  ]);

  useEffect(() => {
    if (!modalFormVisible) {
      setSelectedData(null);
    }
  }, [modalFormVisible]);

  useEffect(() => {
    if (!modalAlertVisible) {
      if (!modalFormVisible) {
        setSelectedData(null);
      }
      setModalAlertIsConfirmation(false);
      setModalAlertIsFailed(false);
    }
  }, [modalAlertVisible]);

  useEffect(() => {
    tabsChangeHandler(selectedIndex);
  }, [selectedIndex]);

  useEffect(() => {
    if (location?.state?.initialTabIndex) {
      setSelectedIndex(1);
    }
  }, [location?.state?.initialTabIndex]);

  const masterHeader = [
    {
      key: "name",
      name: "Counselor Name",
    },
    {
      key: "gender",
      name: "Gender",
    },
    {
      key: "tokenPerHour",
      name: "Token per Hour",
    },
    {
      key: "sessionType",
      name: "Session Type",
    },
    {
      key: "expertise",
      name: "Expertise",
    },
    {
      key: "action",
      name: "Action",
      noSort: true,
    },
  ];

  const mainContent = (
    <div className="flex w-full flex-col gap-7 px-4 md:px-0">
      <div className="flex flex-col items-center gap-2 md:flex-row">
        <div
          className="btn flex w-full gap-[0.625rem] border-none bg-accent normal-case text-white
            hover:bg-accent hover:bg-opacity-90 md:min-h-[3.375rem] md:w-auto md:py-4 md:px-8"
          onClick={() => {
            setSelectedData(null);
            formik.resetForm();
            setModalFormTitle("New Counselor");
            setModalFormButtonText("Create New Counselor");
            setModalFormIsUpdate(false);
            setModalFormVisible(true);
          }}
        >
          <FaPlusSquare size={16} />
          <span>New Counselor</span>
        </div>
        <SearchBox
          className="w-full md:w-[32rem] flex-auto"
          searchByValue={masterSearchByValue}
          searchByOnChange={masterSearchByOnChange}
          searchByOptions={masterSearchByOptions}
          searchTextOnChange={mastersearchTextOnChange}
          searchTextValue={masterSearchText}
        />
        <SelectionBox
          className="w-full md:w-[13.5rem]"
          placeholder="Show Gender All"
          value={filterGender}
          options={genderOptions}
          handleChange={(obj: any) => setFilterGender(obj)}
          styleTemplate="page"
        />
        <SelectionBoxAsync
          className="w-full md:w-[13.5rem]"
          placeholder="Show Type All"
          value={filterSessionType}
          getOptionLabel={(e: any) => e?.name}
          getOptionValue={(e: any) => e?.id}
          handleChange={(value: any) => {
            setFilterSessionType(value);
          }}
          loadOptions={SessionTypeService.getSessionTypesOptions}
          styleTemplate="page"
        />
        <SelectionBoxAsync
          className="w-full md:w-[15rem]"
          placeholder="Show Expertise All"
          value={filterExpertise}
          getOptionLabel={(e: any) => e?.name}
          getOptionValue={(e: any) => e?.id}
          handleChange={(value: any) => {
            setFilterExpertise(value);
          }}
          loadOptions={ExpertiseService.getExpertiseOptions}
          styleTemplate="page"
        />
      </div>
      <Table
        tableTitle="Counselor List"
        headerData={masterHeader}
        bodyData={masterBody}
        resultsAmount={masterResultsAmount}
        resultsTotal={masterResultsTotal}
        resultsAmountOnChange={masterResultsAmountOnChange}
        pageNumber={masterPageNumber}
        pageNumberTotal={masterPageNumberTotal}
        changePage={masterChangePage}
        bodyFormatting={masterBodyFormatting}
        sortKey={masterSortKey}
        setSortKey={setMasterSortKey}
        sortIsAsc={masterSortIsAsc}
        setSortIsAsc={setMasterSortIsAsc}
      />
    </div>
  );

  const panelsData = [
    { tabTitle: "Master Data", tabContent: mainContent },
    {
      tabTitle: "Approval",
      tabContent: <ApprovalCounselor />,
    },
  ];
  const [crumbs, setCrumbs] = useState("Master Data");
  const tabsChangeHandler = (index: number) => {
    setCrumbs(panelsData[index].tabTitle);
    setTabIndex(index);
    if (index === 0) setRefresh(true);
  };

  return (
    <Main
      toggleSidebar={props.toggleSidebar}
      sidebarIsOpen={props.sidebarIsOpen}
      title="Counselor"
      subtitle={`Counselor > ${crumbs}`}
      icon={<FaListUl size={25} />}
      user={props.user}
    >
      <div className="w-full bg-white py-8 md:py-0">
        <TabsPanel
          panelsData={panelsData}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
        <ModalAlert
          isVisible={modalAlertVisible}
          alertText={modalAlertText}
          isConfirmationAlert={modalAlertIsConfirmation}
          isFailedAlert={modalAlertIsFailed}
          handleModalVisibility={() => setModalAlertVisible(!modalAlertVisible)}
          handleConfirm={() =>
            modalAlertIsDelete ? handleDelete(selectedData?.id || "") : ""
          }
        />
        <ModalForm
          isVisible={modalFormVisible}
          handleModalVisibility={() => setModalFormVisible(!modalFormVisible)}
          modalTitle={modalFormTitle}
          buttonText={modalFormButtonText}
          formContent={
            <FormContent formik={formik} isUpdate={modalFormIsUpdate} />
          }
          handleConfirm={formik.handleSubmit}
        />
      </div>
    </Main>
  );
}


import { SessionTypeInterface } from "../interfaces";

const formatCreateSessionType = (sessionType: SessionTypeInterface) => {
  return {
    name: sessionType.name,
  }
}

const formatUpdateSessionType = (sessionType: SessionTypeInterface) => {
  return {
    id: sessionType.id,
    name: sessionType.name,
  }
}

export const SessionTypeFormatter = {
  formatCreateSessionType,
  formatUpdateSessionType,
};

import React from 'react';
import { AiFillWarning } from 'react-icons/ai';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { FaWindowClose } from 'react-icons/fa';

const ModalForm = ({
  isVisible,
  handleModalVisibility,
  modalTitle,
  buttonText,
  formContent,
  handleConfirm,
  formClassname,
  titleClassname,
  submitButtonClassname,
  wideModal,
}: {
  isVisible: boolean,
  handleModalVisibility: any,
  modalTitle: string,
  buttonText: string,
  formContent: JSX.Element,
  handleConfirm: any,
  formClassname: string,
  titleClassname: string,
  submitButtonClassname: string,
  wideModal: boolean,
}) => {

  return (
    <div
      className={`${isVisible ? "" : "hidden"} flex items-center justify-center z-[999] fixed inset-0`}
      onClick={handleModalVisibility}
    >
      <div
        className="h-screen w-screen bg-black bg-opacity-25 fixed"
        onClick={handleModalVisibility}
      />
      <form
        className={`bg-white modal-box relative max-h-max ${wideModal ? "md:min-w-[900px]" : "md:min-w-[676px]"} p-8 ${formClassname}`}
        onClick={(e) => e.stopPropagation()}
        onSubmit={(e) => { e.preventDefault(); handleConfirm(e); }}
      >
        <div className={`flex justify-between w-full border-b ${titleClassname}`}>
          <div className="font-bold text-black text-left text-lg pb-2 w-full">{modalTitle}</div>
          <FaWindowClose onClick={handleModalVisibility} fill="#000000" size={20} className="cursor-pointer"/>
        </div>
        <div className="py-2">
          {formContent}
        </div>
        <button className={`btn normal-case w-full py-4 text-white rounded-lg bg-[#193341] hover:bg-[#193341] hover:bg-opacity-90
           ${submitButtonClassname}`} type="submit">{buttonText}</button>
      </form>
    </div>
  );
};

ModalForm.defaultProps = {
  isVisible: false,
  modalTitle: "Modal Form",
  buttonText: "Confirm",
  formContent: <></>,
  formClassname: "",
  titleClassname: "",
  submitButtonClassname: "",
  wideModal: false,
}

export default ModalForm;
const masterHeader = [
  { key: "name", name: "Name", width: "35%"  },
  { key: "tokenLeft", name: "Token Left", width: "20%"  },
  { key: "durationLeft", name: "Validity Period Left", width: "20%"  },
  { key: "action", name: "Action", noSort: true, width: "25%" }
];

const masterSearchByOptions = [
  { value: "all", name: "All" },
  { value: "name", name: "Name" },
  { value: "tokenLeft", name: "Token Left" },
  { value: "durationLeft", name: "Validity Period Left" },
];

export const ReminderOptions = {
  masterHeader, masterSearchByOptions,
};

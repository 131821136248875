const masterHeader = [
  { key: "name", name: "Company Name" },
  { key: "status", name: "Status" },
  { key: "cpEmail", name: "Contact Person" },
  { key: "expiredDate", name: "End Period" },
  { key: "tokenLeft", name: "Token Left" },
];

export const ClientOptions = {
  masterHeader,
};

import { useFormik } from "formik";
import { useState, HTMLInputTypeAttribute } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

import { AuthService } from "../../services/AuthService";
import LeftDecoration from "../../images/left-decoration.png";
import RightDecoration from "../../images/right-decoration.png";
import DexwellnessLogo from "../../images/dexwellness.png";
import { handleResponse } from "../../services/HandleResponse";
import { useAppDispatch } from "../../app/hooks";
import { startTransparentLoading, stopTransparentLoading } from "../../App.Slice";

export function ForgotPassword() {
  const dispatch = useAppDispatch();
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [formSuccessMessage, setFormSuccessMessage] = useState("");
  document.title = "Dexwellness - Forgot Password";

  const leftDecoration = (
    <img
      src={LeftDecoration}
      alt="login background"
      className="h-2/4 absolute bottom-0 left-0 right-0 ml-[10rem] mr-auto my-0 hidden lg:block"
    />
  );

  const rightDecoration = (
    <img
      src={RightDecoration}
      alt="login background"
      className="h-2/4 absolute top-0 left-0 right-0 ml-auto mr-[10rem] my-0 hidden lg:block"
    />
  );

  const handleForgotPassword = async (email: string) => {
    dispatch(startTransparentLoading());
    const result = await AuthService.RequestResetPassword(email);
    handleResponse({
      result: result,
      handleSuccess: (result: any) => setFormSuccessMessage(result?.data?.data?.message),
      handleFailed: (result: any) => setFormErrorMessage(result?.response?.data?.errors?.message),
    })
    dispatch(stopTransparentLoading());
  };

  const forgotPasswordFormik = useFormik({
    enableReinitialize: true,
    initialValues: { email: "" },
    onSubmit: async (values) => {
      await handleForgotPassword(values.email);
      forgotPasswordFormik.resetForm();
    },
  });

  const logoDexWellness = (
    <label className="label flex justify-center">
      <img src={DexwellnessLogo} alt="dexwellness logo" className="h-[12rem]" />
    </label>
  );

  const formField = (
    type: HTMLInputTypeAttribute,
    label: string,
    name: string,
    placeholder: string,
    value: string | number,
    onChange: (e: React.ChangeEvent<any>) => void
  ) => (
    <>
      <label className="label">
        <span className="label-text text-lg text-black font-bold">{label}</span>
      </label>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(e) => { onChange(e); setFormErrorMessage(""); setFormSuccessMessage(""); }}
        className={`input border-black !input-bordered text-lg text-black font-normal bg-white p-4
          w-full h-[3.25rem] ${formErrorMessage ? "shadow-[0_0_4px_rgb(255,0,0)]" : ""}`}
      />
    </>
  );

  const formTitle = (
    <label className="label flex flex-col">
      <span className="label-text text-xl text-black font-bold text-justify w-full">
        Request Reset Password
      </span>
      <span className="label-text text-lg text-black font-normal text-justify w-full">
        We'll send you an email to reset your password, please check your inbox.
      </span>
    </label>
  );

  const emailField = formField(
    "text", "Email", "email", "Email", forgotPasswordFormik.values.email, forgotPasswordFormik.handleChange
  );

  const errorMessage = (
    <label
      className={`label text-lg text-[#DC3545] font-normal p-0 m-0 mt-3
        ${formErrorMessage ? "" : "hidden"}`}
    >
      {formErrorMessage}
    </label>
  );

  const successMessage = (
    <label
      className={`label text-lg text-black font-normal p-0 m-0 mt-3
        ${formSuccessMessage ? "flex" : "hidden"} gap-2 items-start`}
    >
      <div className=""><FaCheckCircle size={44} color="#28A745" /></div>
      <div className="flex-1">{formSuccessMessage}</div>
    </label>
  );

  const loginButton = (
    <label className="label p-0 m-0 mt-3">
      <Link to={"/login"} className="underline hover:!text-black text-black font-normal">
        Kembali ke Login
      </Link>
    </label>
  );

  const forgotPasswordButton = (
    <button
      type="submit"
      className="btn bg-[#289B95] border-none text-[#F3EADA] w-full h-[3.25rem] mt-3"
    >
      Request Reset Password
    </button>
  );

  const forgotPasswordForm = (
    <form
      className="form-control w-[18.5rem] md:w-[21.8125rem] text-justify py-8"
      onSubmit={(e) => {
        e.preventDefault();
        forgotPasswordFormik.handleSubmit(e);
      }}
    >
      {logoDexWellness}
      {formTitle}
      {emailField}
      {errorMessage}
      {successMessage}
      {forgotPasswordButton}
      {loginButton}
    </form>
  );

  return (
    <div className="flex justify-center items-center min-h-screen md:h-screen bg-[#F3EADA]">
      {leftDecoration}
      {rightDecoration}
      {forgotPasswordForm}
    </div>
  );
}

type Props = {
  result: any,
  handleSuccess?: (result: any) => any,
  handleFailed?: (result: any) => any,
  handleError?: (result: any) => any
};

export function handleResponse({
  result,
  handleSuccess = () => null,
  handleFailed = () => null,
  handleError = () => null
}: Props) {
  if (result?.data) handleSuccess(result);
  else if (result?.response) handleFailed(result);
  else handleError(result);
};

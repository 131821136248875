import { createAxiosInstance } from "./AxiosInstance";

const ApprovalRoute = "/approval";
const Approve = "/approve";
const Reject = "/reject";

export const ApprovalService = {
  approve,
  reject,
};

async function approve(id: string) {
  const result = await createAxiosInstance()
    .post(ApprovalRoute + Approve, { id: id })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function reject(id: string) {
  const result = await createAxiosInstance()
    .post(ApprovalRoute + Reject, { id: id })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

import { createAxiosInstance } from "./AxiosInstance";
import { fixTimezoneOffset } from "../util/formatter/formatter";
import { SessionFormatter } from "../util/formatter/SessionFormatter";
import { SessionInterface } from "../util/interfaces";
import { sessionList, successMessage } from "../mock/MockSession";

const SessionRoute = "/session";
const SessionRouteApproval = SessionRoute + "/approval";

export const SessionService = {
  getSessions,
  getSessionApprovals,
  createSession,
  updateSession,
  deleteSession,
  getSessionChart,
};

async function getSessions(
  page: number,
  pageSize: number,
  searchBy: string = "",
  searchText: string = "",
  sortKey: string = "",
  isAsc: boolean = false,
  filterSessionType: any,
  filterRating: any,
  filterSessionStatus: any,
  filterSessionDateStart: Date | null,
  filterSessionDateEnd: Date | null,
  filterUsedTokenStart: string,
  filterUsedTokenEnd: string,
  filterIdCompany: string = "",
) {
  const result = await createAxiosInstance()
    .get(SessionRoute, {
      params: {
        page: page,
        size: pageSize,
        searchBy: searchBy,
        searchQuery: searchText,
        sort:
          sortKey !== ""
            ? isAsc
              ? `asc(${sortKey})`
              : `desc(${sortKey})`
            : undefined,
        idSessionType: filterSessionType?.id || "",
        rating: filterRating?.value || "",
        sessionStatus: filterSessionStatus?.value || "",
        dateStart: fixTimezoneOffset(filterSessionDateStart),
        dateEnd: fixTimezoneOffset(filterSessionDateEnd),
        tokenStart: filterUsedTokenStart,
        tokenEnd: filterUsedTokenEnd,
        idCompany: filterIdCompany,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
  return result;
}

async function getSessionApprovals(
  page: number,
  pageSize: number,
  searchBy: string = "",
  searchText: string = "",
  sortKey: string = "",
  isAsc: boolean = false,
  filterStatus: any,
  filterSessionType: any,
  filterDateStart: Date | null,
  filterDateEnd: Date | null,
  filterSessionDateStart: Date | null,
  filterSessionDateEnd: Date | null,
  filterUsedTokenStart: string,
  filterUsedTokenEnd: string,
) {
  const result = await createAxiosInstance()
    .get(SessionRouteApproval, {
      params: {
        page: page,
        size: pageSize,
        searchBy: searchBy,
        searchQuery: searchText,
        sort:
          sortKey !== ""
            ? isAsc
              ? `asc(${sortKey})`
              : `desc(${sortKey})`
            : undefined,
        status: filterStatus?.value || "",
        idSessionType: filterSessionType?.id || "",
        dateStart: fixTimezoneOffset(filterDateStart),
        dateEnd: fixTimezoneOffset(filterDateEnd),
        sessionDateStart: fixTimezoneOffset(filterSessionDateStart),
        sessionDateEnd: fixTimezoneOffset(filterSessionDateEnd),
        tokenStart: filterUsedTokenStart,
        tokenEnd: filterUsedTokenEnd,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

async function createSession(session: SessionInterface) {
  const result = await createAxiosInstance()
    .post(
      SessionRoute,
      SessionFormatter.formatCreateSession(session)
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
  return result;
}

async function updateSession(session: SessionInterface) {
  const result = await createAxiosInstance()
    .put(
      SessionRoute,
      SessionFormatter.formatUpdateSession(session)
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
  return result;
}

async function deleteSession(id: string) {
  const result = await createAxiosInstance()
    .delete(SessionRoute, {
      data: {
        id: id,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
  return result;
}

async function getSessionChart(filter: string, idCompany: string) {
  const result = await createAxiosInstance()
    .get(`${SessionRoute}/session-chart/${idCompany}`, {
      params: {
        filter
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
}

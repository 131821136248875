import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import moment from 'moment';

import { FaChevronLeft, FaPencilAlt, FaTrashAlt } from 'react-icons/fa';

import { SelectionBoxAsync } from '../../components/SelectionBox';
import SearchBox from '../../components/SearchBox';
import Table from '../../components/Table';
import Filter from "../dashboard-hr/Filter";

import { CompanyInterface, SessionInterface, TokenInterface } from "../../util/interfaces";
import { SessionTypeService } from '../../services/SessionTypeService';
import { DashboardHRService } from '../../services/DashboardHRService';
import { SessionService } from '../../services/SessionService';
import { CompanyService } from "../../services/CompanyService";
import { TokenService } from '../../services/TokenService';
import ModalAlert from '../../components/ModalAlert';

export function ViewCompanyDetail({
  setTitle,
  setSubtitle,
}:{
  setTitle: any,
  setSubtitle: any,
}) {
  const { companyId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [returnLink, setReturnLink] = useState("");

  const [modalAlertVisible, setModalAlertVisible] = useState(false);

  const [masterRefresh, setMasterRefresh] = useState(0);

  const [companyData, setCompanyData] = useState<CompanyInterface | null>(null);
  const [tokenData, setTokenData] = useState<TokenInterface | null>(null);
  const [masterBody, setMasterBody] = useState<SessionInterface[]>([]);

  const [masterSearchByValue, setMasterSearchByValue] = useState("all");
  const [masterSearchText, setMasterSearchText] = useState("");
  const [masterSortKey, setMasterSortKey] = useState("");
  const [masterSortIsAsc, setMasterSortIsAsc] = useState(true);

  const [masterResultsAmount, setMasterResultsAmount] = useState(10);
  const [masterResultsTotal, setMasterResultsTotal] = useState(10);
  const [masterPageNumber, setMasterPageNumber] = useState(1);
  const [masterPageNumberTotal, setMasterPageNumberTotal] = useState(1);

  const [filterSessionType, setFilterSessionType] = useState(null);

  // session chart
  const [chartId, setChartId] = useState("session-chart-weekly");
  const [chartCategories, setChartCategories] = useState([
    "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
  ]);
  const [chartSeriesName, setChartSeriesName] = useState("total session");
  const [chartSeriesData, setChartSeriesData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const masterFilterChartBy = { value: "chart", name: "This" };
  const [masterFilterChartValue, setMasterFilterChartValue] = useState("weekly");
  const masterFilterChartOnChange = (e: any) => { setMasterFilterChartValue(e.target.value); };
  const [masterFilterChartOptions, setMasterFilterChartOptions] = useState([
    { value: "weekly", name: "Week's Chart" },
    { value: "monthly", name: "Month's Chart" },
    { value: "quarterly", name: "Quarter's Chart" },
  ]);

  const masterSearchByOnChange = (e: any) => { setMasterSearchByValue(e.target.value); };
  const mastersearchTextOnChange = (e: any) => { setMasterSearchText(e.target.value); };
  const masterResultsAmountOnChange = (e: any) => { setMasterResultsAmount(e.target.value); };
  const masterChangePage = (e: any) => { setMasterPageNumber(e.selected+1); };

  useEffect(()=>{
    const getSessionChart = async()=>{
      if (companyId) {
        const response = await SessionService.getSessionChart(
          masterFilterChartValue, companyId
        );

        setChartSeriesData(response?.data?.data?.yAxis);
        setChartCategories(response?.data?.data?.xAxis);
      }
    }

    getSessionChart();
  },[masterFilterChartValue])

  useEffect(() => {
    setMasterRefresh(masterRefresh + 1);
  }, [masterSearchByValue, masterSearchText, masterResultsAmount, masterSortKey, masterSortIsAsc]);

  useEffect(() => {
    const getSessions = async () => {
      const response: any = await SessionService.getSessions(
        masterPageNumber,
        masterResultsAmount,
        masterSearchByValue,
        masterSearchText,
        masterSortKey,
        masterSortIsAsc,
        filterSessionType,
        "",
        null,
        null,
        null,
        "",
        "",
        companyId,
      );
      setMasterPageNumberTotal(response?.data?.page?.totalPage);
      setMasterResultsTotal(response?.data?.page?.totalCount);
      setMasterBody(response?.data?.data);
    };
    if (!masterRefresh) {
      getSessions();
    }
    if (masterRefresh) {
      setMasterRefresh(0);
      setMasterPageNumber(1);
    }
  }, [masterPageNumber, masterResultsAmount, masterRefresh]);

  useEffect(() => {
    setTitle(location.state?.title);
    setSubtitle(location.state?.subtitle);
    setReturnLink(location.state?.returnLink);
  },[location]);

  useEffect(() => {
    const getCompanyById = async (id: any) => {
      const response = await CompanyService.getCompanyById(id);
      if (response?.data?.code?.substr(0,1) === "2" || response?.data?.code?.substr(0,1) === "3") {
        setCompanyData(response?.data?.data);
      } else {
        setModalAlertVisible(true);
      }
    };
    const getTokenById = async (id: any) => {
      const response = await TokenService.getTokenById(id);
      setTokenData(response?.data?.data);
    };

    getCompanyById(companyId);
    getTokenById(companyId);
  }, [companyId]);

  const masterSearchByOptions = [
    { value: "all", name: "All" },
    { value: "sessionDate", name: "Session Date" },
    { value: "counselorName", name: "Counselor" },
    { value: "sessionTypeName", name: "Session Type" },
    { value: "counselingTime", name: "Counseling Time" },
    { value: "usedToken", name: "Used Token" }];

  const masterHeader = [
    { key:"sessionDate", name:"Session Date" },
    { key:"counselor", name:"Counselor" },
    { key:"sessionType", name:"Session Type" },
    { key:"counselingTime", name:"Counseling Time" },
    { key:"usedToken", name:"Used Token" }];

  const masterBodyFormatting = (key: string, data: any): JSX.Element => {
    switch (key) {
      case"sessionDate":
        return <>{moment(data[key]).format("DD MMMM YYYY")}</>
      case "counselingTime":
        return (
          <>
            {data[key]?.start}-{data[key]?.end}
          </>
        );
      case "sessionType":
        return <>{data[key]?.name}</>;
      case "counselor":
        return <>{data[key]?.name}</>;
      default:
        return <>{data[key]}</>;
    }
  };
  
  const chartOptions: ApexOptions = {
    chart: {
      id: chartId
    },
    xaxis: {
      categories: chartCategories
    },
    colors: ["#289B95"]
  };

  const chartSeries: ApexOptions['series'] = [{
    name: chartSeriesName,
    data: chartSeriesData
  }];

  return (
    <div className="w-full bg-white px-2 pt-9 md:pt-0">
      <div className="cursor-pointer flex items-center mb-4 text-black" onClick={() => { navigate(returnLink); }}>
        <FaChevronLeft size={20}/>
        <div className="font-bold ml-4 text-[#289B95] text-xl">{`Company Detail (${companyData?.companyName})`}</div>
      </div>
      <div className="font-bold text-black text-left text-xl">Token Data</div>
      <div className="border-t gap-4 flex md:grid md:grid-cols-5 overflow-auto py-4 text-black text-left w-full">
        <div className="border bg-[#FFFFFF] p-4 rounded-lg w-max md:w-auto">
          <div className="font-normal mb-1 text-base w-max">Start Date</div>
          <div className="font-bold text-xl w-max">{tokenData?.startDate ? moment(tokenData?.startDate).format("DD MMMM YYYY") : ""}</div>
        </div>
        <div className="border bg-[#FFC107] p-4 rounded-lg w-max md:w-auto">
          <div className="font-normal mb-1 text-base w-max">End Date</div>
          <div className="font-bold text-xl w-max">{tokenData?.expiredDate ? moment(tokenData?.expiredDate).format("DD MMMM YYYY") : ""}</div>
        </div>
        <div className="border bg-[#FFFFFF] p-4 rounded-lg w-max md:w-auto">
          <div className="font-normal mb-1 text-base w-max">Total Token</div>
          <div className="font-bold text-xl w-max">{tokenData?.totalToken}</div>
        </div>
        <div className="border bg-[#FFC107] p-4 rounded-lg w-max md:w-auto">
          <div className="font-normal mb-1 text-base w-max">Used Token</div>
          <div className="font-bold text-xl w-max">{tokenData?.usedToken}</div>
        </div>
        <div className="border bg-[#DC1F31] p-4 rounded-lg text-white w-max md:w-auto">
          <div className="font-normal mb-1 text-base w-max">Token Left</div>
          <div className="font-bold text-xl w-max">{tokenData?.availToken}</div>
        </div>
      </div>
      <div className="font-bold text-black text-left text-xl">Company Data</div>
      <div className="border-t gap-4 flex md:grid md:grid-cols-5 overflow-auto items-center py-3 text-black text-left w-full">
        <div className='w-max md:w-auto'>
          <div className="font-medium mb-1 text-base w-max">Company Name</div>
          <div className="font-normal text-base w-max">{companyData?.companyName}</div>
        </div>
        <div className='w-max md:w-auto'>
          <div className="font-medium mb-1 text-base w-max">CP Name</div>
          <div className="font-normal text-base w-max">{companyData?.cpName}</div>
        </div>
        <div className='w-max md:w-auto'>
          <div className="font-medium mb-1 text-base w-max">CP Email</div>
          <div className="font-normal text-base w-max">{companyData?.cpEmail}</div>
        </div>
        <div className='w-max md:w-auto'>
          <div className="font-medium mb-1 text-base w-max">Admin HR Name</div>
          <div className="font-normal text-base w-max">{companyData?.hrAdminName}</div>
        </div>
        <div className='w-max md:w-auto'>
          <div className="font-medium mb-1 text-base w-max">Admin HR Email</div>
          <div className="font-normal text-base w-max">
            <ul className='list-disc pl-4'>
              { companyData?.hrAdminEmail1 ? <li>{companyData?.hrAdminEmail1}</li> : ""}
              { companyData?.hrAdminEmail2 ? <li>{companyData?.hrAdminEmail2}</li> : ""}
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-8">
        <div className="w-full md:w-[63%]">
          <div className="font-bold mb-3 text-black text-left text-xl">Session List</div>
          <div className='flex flex-col md:flex-row gap-2 mb-5'>
            <SearchBox className="flex-auto"
              searchByValue={masterSearchByValue}
              searchByOnChange={masterSearchByOnChange}
              searchByOptions={masterSearchByOptions}
              searchTextOnChange={mastersearchTextOnChange}
              searchTextValue={masterSearchText}
            />
            <SelectionBoxAsync
              className="text-left w-full md:w-[28rem]"
              placeholder="Show Session Type All"
              value={filterSessionType}
              getOptionLabel={(e: any) => e?.name}
              getOptionValue={(e: any) => e?.id}
              handleChange={(value: any) => {
                setFilterSessionType(value);
              }}
              loadOptions={SessionTypeService.getSessionTypesOptions}
              styleTemplate="page"
            />
          </div>
          <Table 
            headerData={masterHeader}
            bodyData={masterBody}
            resultsAmount={masterResultsAmount}
            resultsTotal={masterResultsTotal}
            resultsAmountOnChange={masterResultsAmountOnChange}
            pageNumber={masterPageNumber}
            pageNumberTotal={masterPageNumberTotal}
            changePage={masterChangePage}
            bodyFormatting={masterBodyFormatting}
            sortKey={masterSortKey}
            setSortKey={setMasterSortKey}
            sortIsAsc={masterSortIsAsc}
            setSortIsAsc={setMasterSortIsAsc}
          />
        </div>
        <div className="w-full md:w-[35%]">
          <div className="font-bold mb-3 text-black text-left text-xl">Session Chart</div>
          <div className="my-4">
            <Filter
              className="w-full"
              filterBy={masterFilterChartBy}
              filterValue={masterFilterChartValue}
              filterOnChange={masterFilterChartOnChange}
              filterOptions={masterFilterChartOptions}
            />
          </div>
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            width="100%"
          />
        </div>
      </div>
      <ModalAlert isVisible={modalAlertVisible} 
        alertText={"Company Not Found"}
        isConfirmationAlert={false}
        isFailedAlert={true}
        handleModalVisibility={() => navigate("/")}
      />
    </div>
  );
}
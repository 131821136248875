import React from "react";
import { Topbar } from "./Topbar";
import { SideBar } from "./Sidebar";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { closeMenubar, closeSidebar } from "../App.Slice";

type Props = {
  children: React.ReactNode;
  toggleSidebar?: () => void;
  sidebarIsOpen?: any;
  title: string;
  subtitle: string;
  icon: React.ReactNode;
  user: any;
};

export const Main = ({
  children,
  title,
  subtitle,
  icon,
  user,
}: Props) => {
  const dispatch = useAppDispatch();
  const showSidebar = useAppSelector(state => state.app.sidebar);
  const showMenubar = useAppSelector(state => state.app.menubar);
  document.title = "Dexwellness - " + title;

  return (
    <div className="flex flex-col lg:flex-row gap-1 lg:gap-4 items-start w-full h-full md:p-12">
      <div
        onClick={() => { dispatch(closeSidebar()); dispatch(closeMenubar()); }}
        className={`${showSidebar || showMenubar ? "opacity-25" : "opacity-0 hidden"}
          transition-opacity duration-500 md:hidden w-screen h-screen bg-black fixed z-[998]`}
      />
      <div
        className={`${showSidebar ? "-translate-x-0" : "-translate-x-full"} ease-in-out duration-500
          md:translate-x-0 w-3/4 md:w-[15%] h-screen fixed z-[999]`}
      >
        <SideBar />
      </div>
      <div className="flex flex-col w-full md:ml-[calc(15%+2rem)] md:max-w-[85%] gap-4">
        <Topbar title={title} subtitle={subtitle} icon={icon} user={user} />
        {children}
      </div>
    </div>
  );
};
